// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Login from './pages/Login';
// import Dashboard from './pages/Dashboard';
// import ClientDebt from './pages/ClientDebt';
// import PaymentHistory from './pages/PaymentHistory';
// import './App.css';
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'

// library.add(fas)

// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <Routes>
//           <Route path="/login" element={<Login />} />
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/client-debt" element={<ClientDebt />} />
//           <Route path="/payment-history" element={<PaymentHistory />} />
//           <Route path="/" element={<Navigate replace to="/login" />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// } 

// export default App;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ClientDebt from './pages/ClientDebt';
import PaymentHistory from './pages/PaymentHistory';
import ManualUsuario from './pages/ManualUsuario';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('token') !== null;
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

const PublicRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('token') !== null;
  return isAuthenticated ? <Navigate to="/client-debt" replace /> : children;
};

const Modal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>¿Desea cerrar sesión?</h2>
        <p>Si retrocede, se cerrará su sesión actual.</p>
        <div className="modal-buttons">
          <button onClick={onConfirm}>Sí, cerrar sesión</button>
          <button onClick={onClose}>No, permanecer en la aplicación</button>
        </div>
      </div>
    </div>
  );
};

const AppContent = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handlePopstate = (event) => {
      event.preventDefault();
      if (location.pathname !== '/login' && localStorage.getItem('token')) {
        setShowModal(true);
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [location]);

  const handleConfirmLogout = () => {
    localStorage.removeItem('token');
    setShowModal(false);
    navigate('/login');
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate(location.pathname);
  };

  return (
    <>
      <Routes>
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/manual-usuario" element={<PublicRoute><ManualUsuario /></PublicRoute>} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/client-debt" element={<PrivateRoute><ClientDebt /></PrivateRoute>} />
        <Route path="/payment-history" element={<PrivateRoute><PaymentHistory /></PrivateRoute>} />
        <Route path="/" element={<Navigate replace to="/client-debt" />} />
        <Route path="*" element={<Navigate replace to="/client-debt" />} />
      </Routes>
      <Modal 
        isOpen={showModal} 
        onClose={handleCloseModal} 
        onConfirm={handleConfirmLogout}
      />
    </>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <AppContent />
      </div>
    </Router>
  );
}

export default App;