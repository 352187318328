
// // import React, { useState, useEffect } from 'react';
// // import { useNavigate } from 'react-router-dom';
// // import loginImage from '../assets/sammy-34.png';
// // import logo from '../assets/logo.png';
// // import Footer from '../components/footer';
// // import apiService from '../services/apiService';

// // const Login = () => {
// //   const [branch, setBranch] = useState('');
// //   const [usuario, setUsuario] = useState('');
// //   const [contrasenia, setContrasenia] = useState('');
// //   const [sucursales, setSucursales] = useState([]);
// //   const [error, setError] = useState('');
// //   const [welcomeMessage, setWelcomeMessage] = useState('');
// //   const [isLoading, setIsLoading] = useState(false);
// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     const fetchSucursales = async () => {
// //       try {
// //         const data = await apiService.getSucursales();
// //         setSucursales(data.sucursales);
// //       } catch (error) {
// //         console.error('Error fetching sucursales:', error);
// //         setError('Error al cargar las sucursales');
// //       }
// //     };  

// //     fetchSucursales();
// //   }, []);

// //   const handleBranchChange = (e) => setBranch(e.target.value);
// //   const handleUsuarioChange = (e) => setUsuario(e.target.value);
// //   const handleContraseniaChange = (e) => setContrasenia(e.target.value);

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     setError('');
// //     setWelcomeMessage('');
// //     if (!branch) {
// //       setError('Por favor, selecciona una sucursal');
// //       return;
// //     }
// //     setIsLoading(true);
// //     try {
// //       const response = await apiService.login(branch, usuario, contrasenia);

// //       if (response.status === 'success') {
// //         const userData = {
// //           ...response.data.usuario,
// //           sucursal: branch
// //         };
// //         localStorage.setItem('token', response.data.token);
// //         localStorage.setItem('user', JSON.stringify(userData));   
// //         localStorage.setItem('sucursal', branch);
        
        
// //         setWelcomeMessage(`Bienvenido ${userData.nombre_usuario} a la sucursal ${branch}`);
// //         setTimeout(() => {
// //           navigate('/client-debt');
// //         }, 2000);
// //       } else {
// //         setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
// //       }
// //     } catch (error) {
// //       console.error('Login failed:', error);
// //       setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
// //     } finally {
// //       setIsLoading(false);
// //     }
// //   };

// //   return (
// //     <div className="flex flex-col min-h-screen bg-purple-50 font-poppins">
// //       <div className="h-1 bg-orange-500"></div>
// //       <div className="flex-grow flex flex-col md:flex-row items-center justify-center p-4 md:p-8">
// //         <div className="w-full md:w-1/2 flex items-center justify-center md:pr-8">
// //           <img src={loginImage} alt="Login" className="w-full max-w-2xl h-auto object-contain" />
// //         </div>
// //         <div className="w-full md:w-1/2 flex flex-col items-center justify-center mt-8 md:mt-0">
// //           <img src={logo} alt="Logo" className="w-48 mb-8" />
// //           <div className="w-full max-w-md bg-white rounded-xl shadow-lg p-8">
// //             {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
// //             {welcomeMessage && <div className="text-green-500 mb-4 text-center">{welcomeMessage}</div>}
// //             <h2 className="text-2xl font-bold text-purple-800 mb-6 text-center">Iniciar sesión</h2>
// //             <form onSubmit={handleSubmit}>
// //               <div className="mb-6">
// //                 <label htmlFor="branch" className="block text-purple-800 font-medium mb-2">
// //                   Sucursal
// //                 </label>
// //                 <select
// //                   id="branch"
// //                   value={branch}
// //                   onChange={handleBranchChange}
// //                   className="w-full px-4 py-2 border border-purple-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
// //                 >
// //                   <option value="">Selecciona una sucursal</option>
// //                   {sucursales.map((sucursal) => (
// //                     <option key={sucursal.ip} value={sucursal.nombre}>
// //                       {sucursal.nombre}
// //                     </option>
// //                   ))}
// //                 </select>
// //               </div>
// //               <div className="mb-6">
// //                 <label htmlFor="usuario" className="block text-purple-800 font-medium mb-2">
// //                   Usuario
// //                 </label>
// //                 <input
// //                   type="text"
// //                   id="usuario"
// //                   value={usuario}
// //                   onChange={handleUsuarioChange}
// //                   placeholder="Introduce tu usuario"
// //                   required
// //                   disabled={!branch}
// //                   className={`w-full px-4 py-2 border border-purple-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 ${!branch ? 'bg-gray-100 cursor-not-allowed' : ''}`}
// //                 />
// //               </div>
// //               <div className="mb-6">
// //                 <label htmlFor="contrasenia" className="block text-purple-800 font-medium mb-2">
// //                   Contraseña
// //                 </label>
// //                 <input
// //                   type="password"
// //                   id="contrasenia"
// //                   value={contrasenia}
// //                   onChange={handleContraseniaChange}
// //                   placeholder="Introduce tu contraseña"
// //                   required
// //                   disabled={!branch}
// //                   className={`w-full px-4 py-2 border border-purple-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 ${!branch ? 'bg-gray-100 cursor-not-allowed' : ''}`}
// //                 />
// //               </div>
// //               <button
// //                 type="submit"
// //                 disabled={!branch || isLoading}
// //                 className={`w-full py-3 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${(!branch || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
// //               >
// //                 {isLoading ? 'Iniciando sesión...' : 'Iniciar sesión'}
// //               </button>
// //             </form>
// //           </div>
// //         </div>
// //       </div>
// //       <Footer />
// //     </div>
// //   );
// // };

// // export default Login;


// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import backImage from '../assets/BACKGROUND.png';
// import logoCBBlanco from '../assets/LOGOCB.png';
// import luisito from '../assets/LUISITO.png';
// import logoPlay from '../assets/LOGOCBPLAY.png';
// import logoInternet from '../assets/LOGOINTERNET.png';
// import logoSlogan from '../assets/LOGOSLOGAN.png';
// import logoTV from '../assets/LOGOTV.png';
// import logoWifi from '../assets/LOGOWIFI.png';
// import apiService from '../services/apiService';

// const Login = () => {
//   const [branch, setBranch] = useState('');
//   const [usuario, setUsuario] = useState('');
//   const [contrasenia, setContrasenia] = useState('');
//   const [sucursales, setSucursales] = useState([]);
//   const [error, setError] = useState('');
//   const [welcomeMessage, setWelcomeMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchSucursales = async () => {
//       try {
//         const data = await apiService.getSucursales();
//         setSucursales(data.sucursales);
//       } catch (error) {
//         console.error('Error fetching sucursales:', error);
//         setError('Error al cargar las sucursales');
//       }
//     };  

//     fetchSucursales();
//   }, []);

//   const handleBranchChange = (e) => setBranch(e.target.value);
//   const handleUsuarioChange = (e) => setUsuario(e.target.value);
//   const handleContraseniaChange = (e) => setContrasenia(e.target.value);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');
//     setWelcomeMessage('');
//     if (!branch) {
//       setError('Por favor, selecciona una sucursal');
//       return;
//     }
//     setIsLoading(true);
//     try {
//       const response = await apiService.login(branch, usuario, contrasenia);

//       if (response.status === 'success') {
//         const userData = {
//           ...response.data.usuario,
//           sucursal: branch
//         };
//         localStorage.setItem('token', response.data.token);
//         localStorage.setItem('user', JSON.stringify(userData));
//         localStorage.setItem('userId', response.data.usuario.id);
//         localStorage.setItem('inicial', response.data.usuario.inicial);
//         localStorage.setItem('userName', response.data.usuario.nombre_usuario);
//         localStorage.setItem('userRole', response.data.usuario.rol);
//         localStorage.setItem('userBranch', response.data.usuario.sucursal);
//         localStorage.setItem('username', response.data.usuario.usuario);       
//         localStorage.setItem('sucursal', branch);

//         setWelcomeMessage(`Bienvenido ${userData.nombre_usuario} a la sucursal ${branch}`);
//         setTimeout(() => {
//           navigate('/client-debt');
//         }, 2000);
//       } else {
//         setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
//       }
//     } catch (error) {
//       console.error('Login failed:', error);
//       setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div 
//    className="flex flex-col min-h-screen bg-cover bg-center bg-no-repeat overflow-hidden"
//       style={{ backgroundImage: `url(${backImage})` }}
//     >
//       <div className="flex-grow grid grid-cols-1 md:grid-cols-3 gap-2 items-center justify-items-center p-8 max-h-screen">
//         {/* Columna izquierda - Logo y Luisito (hidden on mobile) */} 
//         <div className="hidden md:flex w-full h-full items-center justify-center">
//           <img src={luisito} alt="Luisito" className="max-h-[80vh] w-auto object-contain" />
//         </div>

//         {/* Columna central - Logo and Form */}
//         <div className="w-full max-w-md flex flex-col items-center justify-center pb-8">
//           <img src={logoCBBlanco} alt="CB Logo" className="w-full max-w-[200px] mb-9 md:mb-8 p-1 py-2 ps-0" />
//           <div className="w-full bg-white/40 backdrop-blur-md rounded-xl shadow-lg p-2 md:p-6">
//             {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
//             {welcomeMessage && <div className="text-white mb-4 text-center">{welcomeMessage}</div>}
//             <h2 className="text-xl md:text-2xl font-bold text-white/80 mb-4 md:mb-6 text-center">Iniciar sesión</h2>
//             <form onSubmit={handleSubmit} className="space-y-4">
//               <div>
//                 <label htmlFor="branch" className="block text-orange-800 font-medium mb-1">
//                   Sucursal
//                 </label>
//                 <select
//                   id="branch"
//                   value={branch}
//                   onChange={handleBranchChange}
//                   className="w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300"
//                 >
//                   <option value="">Selecciona una sucursal</option>
//                   {sucursales.map((sucursal) => (
//                     <option key={sucursal.ip} value={sucursal.nombre}>  
//                       {sucursal.nombre}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div>
//                 <label htmlFor="usuario" className="block text-orange-800 font-medium mb-1">
//                   Usuario
//                 </label>
//                 <input
//                   type="text"
//                   id="usuario"
//                   value={usuario}
//                   onChange={handleUsuarioChange}
//                   placeholder="Introduce tu usuario"
//                   required
//                   disabled={!branch}
//                   className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${!branch ? 'bg-gray-100 cursor-not-allowed' : ''}`}
//                 />
//               </div>    
//               <div>
//                 <label htmlFor="contrasenia" className="block text-orange-800 font-medium mb-1">
//                   Contraseña
//                 </label>
//                 <input
//                   type="password"
//                   id="contrasenia"
//                   value={contrasenia}
//                   onChange={handleContraseniaChange}
//                   placeholder="Introduce tu contraseña"
//                   required
//                   disabled={!branch}
//                   className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${!branch ? 'bg-gray-100 cursor-not-allowed' : ''}`}
//                 />
//               </div>
//               <button
//                 type="submit"
//                 disabled={!branch || isLoading}
//                 className={`w-full  py-2 md:py-3 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${(!branch || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
//               >
//                 {isLoading ? 'Iniciando sesión...' : 'Iniciar sesión'}
//               </button>
//             </form>
//           </div>
//         </div>
       
//         <div className="hidden md:flex w-full h-full items-center flex-col justify-center space-y-5">
//           <img src={logoSlogan} alt="Slogan" className="w-full max-w-[200px]" />
//           <img src={logoPlay} alt="CB Play" className="w-full max-w-[200px]" />
//           <img src={logoInternet} alt="Internet" className="w-full max-w-[200px]" />
//           <img src={logoTV} alt="TV" className="w-full max-w-[200px]" />
//           <img src={logoWifi} alt="WiFi" className="w-full max-w-[200px]" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;


// login 3

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import backImage from '../assets/BACKGROUND.png';
// import logoCBBlanco from '../assets/LOGOCB.png';
// import luisito from '../assets/LUISITO.png';
// import logoPlay from '../assets/LOGOCBPLAY.png';
// import logoInternet from '../assets/LOGOINTERNET.png';
// import logoSlogan from '../assets/LOGOSLOGAN.png';
// import logoTV from '../assets/LOGOTV.png';
// import logoWifi from '../assets/LOGOWIFI.png';
// import apiService from '../services/apiService';

// const Login = () => {
//   const [branch, setBranch] = useState('');
//   const [usuario, setUsuario] = useState('');
//   const [contrasenia, setContrasenia] = useState('');
//   const [sucursales, setSucursales] = useState([]);
//   const [error, setError] = useState('');
//   const [welcomeMessage, setWelcomeMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchSucursales = async () => {
//       try {
//         const data = await apiService.getSucursales();
//         setSucursales(data.sucursales);
//       } catch (error) {
//         console.error('Error fetching sucursales:', error);
//         setError('Error al cargar las sucursales');
//       }
//     };  

//     fetchSucursales();
//   }, []);

//   const handleBranchChange = (e) => setBranch(e.target.value);
//   const handleUsuarioChange = (e) => setUsuario(e.target.value);
//   const handleContraseniaChange = (e) => setContrasenia(e.target.value);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');
//     setWelcomeMessage('');
//     if (!branch) {
//       setError('Por favor, selecciona una sucursal');
//       return;
//     }
//     setIsLoading(true);
//     try {
//       const response = await apiService.login(branch, usuario, contrasenia);

//       if (response.status === 'success') {
//         const userData = {
//           ...response.data.usuario,
//           sucursal: branch
//         };
//         localStorage.setItem('token', response.data.token);
//         localStorage.setItem('user', JSON.stringify(userData));
//         localStorage.setItem('userId', response.data.usuario.id);
//         localStorage.setItem('inicial', response.data.usuario.inicial);
//         localStorage.setItem('userName', response.data.usuario.nombre_usuario);
//         localStorage.setItem('userRole', response.data.usuario.rol);
//         localStorage.setItem('userBranch', response.data.usuario.sucursal);
//         localStorage.setItem('username', response.data.usuario.usuario);       
//         localStorage.setItem('sucursal', branch);

//         setWelcomeMessage(`Bienvenido ${userData.nombre_usuario} a la sucursal ${branch}`);
//         setTimeout(() => {
//           navigate('/client-debt');
//         }, 2000);
//       } else {
//         setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
//       }
//     } catch (error) {
//       console.error('Login failed:', error);
//       setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="min-h-screen bg-cover bg-center bg-no-repeat overflow-y-auto flex flex-col font-poppins"
//          style={{ backgroundImage: `url(${backImage})` }}>
//       <div className="flex-grow flex flex-col md:flex-row items-center justify-center p-4 md:p-8 w-full">
//         {/* Luisito - hidden on mobile */}
//         <div className="hidden md:flex md:w-1/4 lg:w-1/3 items-center justify-center">
//           <img src={luisito} alt="Luisito" className="max-h-[60vh] w-auto object-contain" />
//         </div>

//         <div className="w-full md:w-1/2 lg:w-1/3 max-w-md flex flex-col items-center justify-center mb-8 md:mb-0">
//           <img src={logoCBBlanco} alt="CB Logo" className="w-full max-w-[150px] md:max-w-[200px] mb-6 md:mb-8" />
//           <div className="w-full bg-white/40 backdrop-blur-md rounded-xl shadow-lg p-6 md:p-8">
//             {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
//             {welcomeMessage && <div className="text-white mb-4 text-center">{welcomeMessage}</div>}
//             <h2 className="text-xl md:text-2xl font-bold text-white/80 mb-6 text-center">Iniciar sesión LOCAL</h2>
//             <form onSubmit={handleSubmit} className="space-y-4">
//               <div>
//                 <label htmlFor="branch" className="block text-orange-800 font-medium mb-1">
//                   Sucursal
//                 </label>
//                 <select
//                   id="branch"
//                   value={branch}
//                   onChange={handleBranchChange}
//                   className="w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300"
//                 >
//                   <option value="">Selecciona una sucursal</option>
//                   {sucursales.map((sucursal) => (
//                     <option key={sucursal.ip} value={sucursal.nombre}>  
//                       {sucursal.nombre}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div>
//                 <label htmlFor="usuario" className="block text-orange-800 font-medium mb-1">
//                   Usuario
//                 </label>
//                 <input
//                   type="text"
//                   id="usuario"
//                   value={usuario}
//                   onChange={handleUsuarioChange}
//                   placeholder="Introduce tu usuario"
//                   required
//                   disabled={!branch}
//                   className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${!branch ? 'bg-gray-100 cursor-not-allowed' : ''}`}
//                 />
//               </div>    
//               <div>
//                 <label htmlFor="contrasenia" className="block text-orange-800 font-medium mb-1">
//                   Contraseña
//                 </label>
//                 <input
//                   type="password"
//                   id="contrasenia"
//                   value={contrasenia}
//                   onChange={handleContraseniaChange}
//                   placeholder="Introduce tu contraseña"
//                   required
//                   disabled={!branch}
//                   className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${!branch ? 'bg-gray-100 cursor-not-allowed' : ''}`}
//                 />
//               </div>
//               <button
//                 type="submit"
//                 disabled={!branch || isLoading}
//                 className={`w-full py-3 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${(!branch || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
//               >
//                 {isLoading ? 'Iniciando sesión...' : 'Iniciar sesión'}
//               </button>
//             </form>
//           </div>
//         </div>
       
//         {/* Right column - Logos */}
//         <div className="hidden md:flex md:w-1/4 lg:w-1/3 flex-col items-center justify-center space-y-4 mt-8 md:mt-0">
//           <img src={logoSlogan} alt="Slogan" className="w-full max-w-[150px] md:max-w-[200px]" />
//           <img src={logoPlay} alt="CB Play" className="w-full max-w-[150px] md:max-w-[200px]" />
//           <img src={logoInternet} alt="Internet" className="w-full max-w-[150px] md:max-w-[200px]" />
//           <img src={logoTV} alt="TV" className="w-full max-w-[150px] md:max-w-[200px]" />
//           <img src={logoWifi} alt="WiFi" className="w-full max-w-[150px] md:max-w-[200px]" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;


//login definitivo

// import React, { useState, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import backImage from '../assets/BACKGROUND.png';
// import logoCBBlanco from '../assets/LOGOCB.png';
// import luisito from '../assets/LUISITO.png';
// import logoPlay from '../assets/LOGOCBPLAY.png';
// import logoInternet from '../assets/LOGOINTERNET.png';
// import logoSlogan from '../assets/LOGOSLOGAN.png';
// import logoTV from '../assets/LOGOTV.png';
// import logoWifi from '../assets/LOGOWIFI.png';
// import apiService from '../services/apiService';

// const Login = () => {
//   const [branch, setBranch] = useState('');
//   const [usuario, setUsuario] = useState('');
//   const [contrasenia, setContrasenia] = useState('');
//   const [sucursales, setSucursales] = useState([]);
//   const [error, setError] = useState('');
//   const [welcomeMessage, setWelcomeMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [isButtonDisabled, setIsButtonDisabled] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchSucursales = async () => {
//       try {
//         const data = await apiService.getSucursales();
//         setSucursales(data.sucursales);
//       } catch (error) {
//         console.error('Error fetching sucursales:', error);
//         setError('Error al cargar las sucursales');
//       }
//     };  

//     fetchSucursales();
//   }, []);

//   const handleBranchChange = (e) => setBranch(e.target.value);
//   const handleUsuarioChange = (e) => setUsuario(e.target.value);
//   const handleContraseniaChange = (e) => setContrasenia(e.target.value);

//   const handleSubmit = useCallback(async (e) => {
//     e.preventDefault();
//     if (isButtonDisabled) return;
    
//     setError('');
//     setWelcomeMessage('');
//     if (!branch) {
//       setError('Por favor, selecciona una sucursal');
//       return;
//     }
    
//     setIsLoading(true);
//     setIsButtonDisabled(true);
    
//     try {
//       const response = await apiService.login(branch, usuario, contrasenia);

//       if (response.status === 'success') {
//         const userData = {
//           ...response.data.usuario,
//           sucursal: branch
//         };
//         localStorage.setItem('token', response.data.token);
//         localStorage.setItem('user', JSON.stringify(userData));
//         localStorage.setItem('userId', response.data.usuario.id);
//         localStorage.setItem('inicial', response.data.usuario.inicial);
//         localStorage.setItem('userName', response.data.usuario.nombre_usuario);
//         localStorage.setItem('userRole', response.data.usuario.rol);
//         localStorage.setItem('userBranch', response.data.usuario.sucursal);
//         localStorage.setItem('username', response.data.usuario.usuario);       
//         localStorage.setItem('sucursal', branch);

//         setWelcomeMessage(`Bienvenido ${userData.nombre_usuario} a la sucursal ${branch}`);
//         setTimeout(() => {
//           navigate('/client-debt');
//         }, 2000);
//       } else {
//         setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
//         // Re-enable the button after 3 seconds on error
//         setTimeout(() => {
//           setIsButtonDisabled(false);
//         }, 3000);
//       }
//     } catch (error) {
//       console.error('Login failed:', error);
//       setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
//       // Re-enable the button after 3 seconds on error
//       setTimeout(() => {
//         setIsButtonDisabled(false);
//       }, 3000);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [branch, usuario, contrasenia, isButtonDisabled, navigate]);


// import React, { useState, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import backImage from '../assets/BACKGROUND.png';
// import logoCBBlanco from '../assets/LOGOCB.png';
// import luisito from '../assets/LUISITO.png';
// import logoPlay from '../assets/LOGOCBPLAY.png';
// import logoInternet from '../assets/LOGOINTERNET.png';
// import logoSlogan from '../assets/LOGOSLOGAN.png';
// import logoTV from '../assets/LOGOTV.png';
// import logoWifi from '../assets/LOGOWIFI.png';
// import apiService from '../services/apiService';
// import { setEncryptedItem } from '../encryption';

// const Login = () => {
//   const [branch, setBranch] = useState('');
//   const [usuario, setUsuario] = useState('');
//   const [contrasenia, setContrasenia] = useState('');
//   const [sucursales, setSucursales] = useState([]);
//   const [error, setError] = useState('');
//   const [welcomeMessage, setWelcomeMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [isButtonDisabled, setIsButtonDisabled] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchSucursales = async () => {
//       try {
//         const data = await apiService.getSucursales();
//         setSucursales(data.sucursales);
//       } catch (error) {
//         console.error('Error fetching sucursales:', error);
//         setError('Error al cargar las sucursales');
//       }
//     };  

//     fetchSucursales();
//   }, []);

//   const handleBranchChange = (e) => setBranch(e.target.value);
//   const handleUsuarioChange = (e) => setUsuario(e.target.value);
//   const handleContraseniaChange = (e) => setContrasenia(e.target.value);

//   const handleSubmit = useCallback(async (e) => {
//     e.preventDefault();
//     if (isButtonDisabled) return;
    
//     setError('');
//     setWelcomeMessage('');
//     if (!branch) {
//       setError('Por favor, selecciona una sucursal');
//       return;
//     }
    
//     setIsLoading(true);
//     setIsButtonDisabled(true);
    
//     try {
//       const response = await apiService.login(branch, usuario, contrasenia);

//       if (response.status === 'success') {
//         const userData = {
//           ...response.data.usuario,
//           sucursal: branch
//         };
//         setEncryptedItem('token', response.data.token);
//         setEncryptedItem('user', JSON.stringify(userData));
//         setEncryptedItem('userId', response.data.usuario.id);
//         setEncryptedItem('inicial', response.data.usuario.inicial);
//         setEncryptedItem('userName', response.data.usuario.nombre_usuario);
//         setEncryptedItem('userRole', response.data.usuario.rol);
//         setEncryptedItem('userBranch', response.data.usuario.sucursal);
//         setEncryptedItem('username', response.data.usuario.usuario);       
//         setEncryptedItem('sucursal', branch);

//         setWelcomeMessage(`Bienvenido ${userData.nombre_usuario} a la sucursal ${branch}`);
//         setTimeout(() => {
//           navigate('/client-debt');
//         }, 2000);
//       } else {
//         setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
//         setTimeout(() => {
//           setIsButtonDisabled(false);
//         }, 3000);
//       }
//     } catch (error) {
//       console.error('Login failed:', error);
//       setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
//       setTimeout(() => {
//         setIsButtonDisabled(false);
//       }, 3000);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [branch, usuario, contrasenia, isButtonDisabled, navigate]);

//   return (
//     <div className="min-h-screen bg-cover bg-center bg-no-repeat overflow-y-auto flex flex-col font-poppins"
//          style={{ backgroundImage: `url(${backImage})` }}>
//       <div className="flex-grow flex flex-col md:flex-row items-center justify-center p-4 md:p-8 w-full">
//         {/* Luisito - hidden on mobile */}
//         <div className="hidden md:flex md:w-1/4 lg:w-1/3 items-center justify-center">
//           <img src={luisito} alt="Luisito" className="max-h-[60vh] w-auto object-contain" />
//         </div>

//         <div className="w-full md:w-1/2 lg:w-1/3 max-w-md flex flex-col items-center justify-center mb-8 md:mb-0">
//           <img src={logoCBBlanco} alt="CB Logo" className="w-full max-w-[150px] md:max-w-[200px] mb-6 md:mb-8" />
//           <div className="w-full bg-white/40 backdrop-blur-md rounded-xl shadow-lg p-6 md:p-8">
//             {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
//             {welcomeMessage && <div className="text-white mb-4 text-center">{welcomeMessage}</div>}
//             <h2 className="text-xl md:text-2xl font-bold text-white/80 mb-6 text-center">Iniciar sesión</h2>
//             <form onSubmit={handleSubmit} className="space-y-4">
//               <div>
//                 <label htmlFor="branch" className="block text-orange-800 font-medium mb-1">
//                   Sucursal
//                 </label>
//                 <select
//                   id="branch"
//                   value={branch}
//                   onChange={handleBranchChange}
//                   disabled={isLoading || isButtonDisabled}
//                   className="w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300"
//                 >
//                   <option value="">Selecciona una sucursal</option>
//                   {sucursales.map((sucursal) => (
//                     <option key={sucursal.ip} value={sucursal.nombre}>  
//                       {sucursal.nombre}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div>
//                 <label htmlFor="usuario" className="block text-orange-800 font-medium mb-1">
//                   Usuario
//                 </label>
//                 <input
//                   type="text"
//                   id="usuario"
//                   value={usuario}
//                   onChange={handleUsuarioChange}
//                   placeholder="Introduce tu usuario"
//                   required
//                   disabled={!branch || isLoading || isButtonDisabled}
//                   className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${(!branch || isLoading || isButtonDisabled) ? 'bg-gray-100 cursor-not-allowed' : ''}`}
//                 />
//               </div>    
//               <div>
//                 <label htmlFor="contrasenia" className="block text-orange-800 font-medium mb-1">
//                   Contraseña
//                 </label>
//                 <input
//                   type="password"
//                   id="contrasenia"
//                   value={contrasenia}
//                   onChange={handleContraseniaChange}
//                   placeholder="Introduce tu contraseña"
//                   required
//                   disabled={!branch || isLoading || isButtonDisabled}
//                   className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${(!branch || isLoading || isButtonDisabled) ? 'bg-gray-100 cursor-not-allowed' : ''}`}
//                 />
//               </div>
//               <button
//                 type="submit"
//                 disabled={!branch || isLoading || isButtonDisabled}
//                 className={`w-full py-3 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${(!branch || isLoading || isButtonDisabled) ? 'opacity-50 cursor-not-allowed' : ''}`}
//               >
//                 {isLoading ? 'Iniciando sesión...' : 'Iniciar sesión'}
//               </button>
//             </form>
//           </div>
//         </div>
       
//         {/* Right column - Logos */}
//         <div className="hidden md:flex md:w-1/4 lg:w-1/3 flex-col items-center justify-center space-y-4 mt-8 md:mt-0">
//           <img src={logoSlogan} alt="Slogan" className="w-full max-w-[150px] md:max-w-[200px]" />
//           <img src={logoPlay} alt="CB Play" className="w-full max-w-[150px] md:max-w-[200px]" />
//           <img src={logoInternet} alt="Internet" className="w-full max-w-[150px] md:max-w-[200px]" />
//           <img src={logoTV} alt="TV" className="w-full max-w-[150px] md:max-w-[200px]" />
//           <img src={logoWifi} alt="WiFi" className="w-full max-w-[150px] md:max-w-[200px]" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import backImage from '../assets/BACKGROUND.png';
import logoCBBlanco from '../assets/LOGOCB.png';
import luisito from '../assets/LUISITO.png';
import logoPlay from '../assets/LOGOCBPLAY.png';
import logoInternet from '../assets/LOGOINTERNET.png';
import logoSlogan from '../assets/LOGOSLOGAN.png';
import logoTV from '../assets/LOGOTV.png';
import logoWifi from '../assets/LOGOWIFI.png';
import apiService from '../services/apiService';
import { setEncryptedItem } from '../encryption';

const Login = () => {
  const [branch, setBranch] = useState('');
  const [usuario, setUsuario] = useState('');
  const [contrasenia, setContrasenia] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [error, setError] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSucursales = async () => {
      try {
        const data = await apiService.getSucursales();
        setSucursales(data.sucursales);
      } catch (error) {
        console.error('Error fetching sucursales:', error);
        setError('Error al cargar las sucursales');
      }
    };  

    fetchSucursales();
  }, []);

  const handleBranchChange = (e) => setBranch(e.target.value);
  const handleUsuarioChange = (e) => setUsuario(e.target.value);
  const handleContraseniaChange = (e) => setContrasenia(e.target.value);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (isButtonDisabled) return;
    
    setError('');
    setWelcomeMessage('');
    if (!branch) {
      setError('Por favor, selecciona una sucursal');
      return;
    }
    
    setIsLoading(true);
    setIsButtonDisabled(true);
    
    try {
      const response = await apiService.login(branch, usuario, contrasenia);

      if (response.status === 'success') {
        const userData = {
          ...response.data.usuario,
          sucursal: branch
        };
        setEncryptedItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('userId', response.data.usuario.id);
        localStorage.setItem('inicial', response.data.usuario.inicial);
        localStorage.setItem('userName', response.data.usuario.nombre_usuario);
        localStorage.setItem('userRole', response.data.usuario.rol);
        localStorage.setItem('userBranch', response.data.usuario.sucursal);
        localStorage.setItem('username', response.data.usuario.usuario);       
        localStorage.setItem('sucursal', branch);

        setWelcomeMessage(`Bienvenido ${userData.nombre_usuario} a la sucursal ${branch}`);
        setTimeout(() => {
          navigate('/client-debt');
        }, 2000);
      } else {
        setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  }, [branch, usuario, contrasenia, isButtonDisabled, navigate]);

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat overflow-y-auto flex flex-col font-poppins"
         style={{ backgroundImage: `url(${backImage})` }}>
      <div className="flex-grow flex flex-col md:flex-row items-center justify-center p-4 md:p-8 w-full">
        <div className="hidden md:flex md:w-1/4 lg:w-1/3 items-center justify-center">
          <img src={luisito} alt="Luisito" className="max-h-[60vh] w-auto object-contain" />
        </div>

        <div className="w-full md:w-1/2 lg:w-1/3 max-w-md flex flex-col items-center justify-center mb-8 md:mb-0">
          <img src={logoCBBlanco} alt="CB Logo" className="w-full max-w-[150px] md:max-w-[200px] mb-6 md:mb-8" />
          <div className="w-full bg-white/40 backdrop-blur-md rounded-xl shadow-lg p-6 md:p-8">
            {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
            {welcomeMessage && <div className="text-white mb-4 text-center">{welcomeMessage}</div>}
            <h2 className="text-xl md:text-2xl font-bold text-white/80 mb-6 text-center">Iniciar sesión</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="branch" className="block text-orange-800 font-medium mb-1">
                  Sucursal
                </label>
                <select
                  id="branch"
                  value={branch}
                  onChange={handleBranchChange}
                  disabled={isLoading || isButtonDisabled}
                  className="w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300"
                >
                  <option value="">Selecciona una sucursal</option>
                  {sucursales.map((sucursal) => (
                    <option key={sucursal.ip} value={sucursal.nombre}>  
                      {sucursal.nombre}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="usuario" className="block text-orange-800 font-medium mb-1">
                  Usuario
                </label>
                <input
                  type="text"
                  id="usuario"
                  value={usuario}
                  onChange={handleUsuarioChange}
                  placeholder="Introduce tu usuario"
                  required
                  disabled={!branch || isLoading || isButtonDisabled}
                  className={`w-full px-3 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${(!branch || isLoading || isButtonDisabled) ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                />
              </div>    
              <div className="relative">
                <label htmlFor="contrasenia" className="block text-orange-800 font-medium mb-1">
                  Contraseña
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="contrasenia"
                    value={contrasenia}
                    onChange={handleContraseniaChange}
                    placeholder="Introduce tu contraseña"
                    required
                    disabled={!branch || isLoading || isButtonDisabled}
                    className={`w-full px-3 py-2 pr-10 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${(!branch || isLoading || isButtonDisabled) ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-orange-500 focus:outline-none"
                    disabled={!branch || isLoading || isButtonDisabled}
                  >
                    {showPassword ? (
                      <EyeOffIcon className="h-5 w-5" />
                    ) : (
                      <EyeIcon className="h-5 w-5" />
                    )}
                  </button>
                </div>
              </div>
              <button
                type="submit"
                disabled={!branch || isLoading || isButtonDisabled}
                className={`w-full py-3 bg-orange-500 text-white font-bold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${(!branch || isLoading || isButtonDisabled) ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isLoading ? 'Iniciando sesión...' : 'Iniciar sesión'}
              </button>
            </form>
          </div>
        </div>
       
        <div className="hidden md:flex md:w-1/4 lg:w-1/3 flex-col items-center justify-center space-y-4 mt-8 md:mt-0">
          <img src={logoSlogan} alt="Slogan" className="w-full max-w-[150px] md:max-w-[200px]" />
          <img src={logoPlay} alt="CB Play" className="w-full max-w-[150px] md:max-w-[200px]" />
          <img src={logoInternet} alt="Internet" className="w-full max-w-[150px] md:max-w-[200px]" />
          <img src={logoTV} alt="TV" className="w-full max-w-[150px] md:max-w-[200px]" />
          <img src={logoWifi} alt="WiFi" className="w-full max-w-[150px] md:max-w-[200px]" />
        </div>
      </div>
    </div>
  );
};

export default Login;