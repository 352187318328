import CryptoJS from 'crypto-js';

const SECRET_KEY = 'quemasve';

export const encrypt = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

export const decrypt = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const setEncryptedItem = (key, value) => {
  const encryptedValue = encrypt(value);
  localStorage.setItem(key, encryptedValue);
};

export const getDecryptedItem = (key) => {
  const encryptedValue = localStorage.getItem(key);
  if (encryptedValue) {
    return decrypt(encryptedValue);
  }
  return null;
};