// // import React, { useState, useEffect, useCallback } from 'react';
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { faTimes, faSpinner, faMoneyBillWave, faPrint, faFileInvoice, faSearch, faExclamationCircle, faInfoCircle, faUser, faMapMarkerAlt, faPhone, faMobileAlt, faWifi, faTv } from '@fortawesome/free-solid-svg-icons';
// // import apiService from "../services/apiService";
// // import Header from "../components/header";
// // import Footer from "../components/footer";
// // import moment from 'moment';
// // import InvoiceGenerator from './InvoiceGenerator';

// // const ClientDebt = () => {
// //   const [cedula, setCedula] = useState("");
// //   const [clientDebts, setClientDebts] = useState(null);
// //   const [isProcessing, setIsProcessing] = useState(false);
// //   const [error, setError] = useState(null);
// //   const [showModal, setShowModal] = useState(false);
// //   const [selectedDebt, setSelectedDebt] = useState(null);
// //   const [selectedMonths, setSelectedMonths] = useState(1);
// //   const [contractPayment, setContractPayment] = useState(null);
// //   const [maxMonths, setMaxMonths] = useState(6);
// //   const [monthOptions, setMonthOptions] = useState([]);



// //   const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
// //   const [isAnimating, setIsAnimating] = useState(false);
// //   const [invoiceNumber, setInvoiceNumber] = useState(null);
// //   const [isSearching, setIsSearching] = useState(false);

// //   const invoiceGenerator = InvoiceGenerator();

// //   const validateInput = (value) => {
// //     const regex = /^[0-9]*$/;
// //     if (!regex.test(value)) {
// //       setError('Solo se permiten números');
// //       return false;
// //     }
// //     setError('');
// //     return true;
// //   };

// //   const handleInputChange = (e) => {
// //     const value = e.target.value;
// //     if (validateInput(value)) {
// //       setCedula(value);
// //       setClientDebts(null);
// //       setError(null);
// //     }
// //   };

// //   const handleSearch = async () => {
// //     if (cedula.trim() === '') {
// //       setError('Este campo es requerido');
// //       return;
// //     }
// //     setIsSearching(true);
// //     setError(null);
// //     try {
// //       const user = JSON.parse(localStorage.getItem("user"));
// //       const branch = user.sucursal;
// //       const userId = user.id;

// //       if (!branch || !userId) {
// //         throw new Error('Información de usuario no disponible');
// //       }

// //       const response = await apiService.searchClientDebts(branch, cedula, userId);
// //       setClientDebts(response.data.pagos);
// //     } catch (err) {
// //       setError(err.message || 'Error al buscar deudas del cliente');
// //       setClientDebts(null);
// //     } finally {
// //       setIsSearching(false);
// //     }
// //   };








// //   const resetPaymentStates = () => {
// //     setShowModal(false);
// //     setIsProcessing(false);
// //     setIsPaymentSuccess(false);
// //     setSelectedDebt(null);
// //     setContractPayment(null);
// //     setSelectedMonths(1);
// //     setMaxMonths(6);
// //   };

// //   // const handlePaymentClick = async (debt) => {
// //   //   resetPaymentStates();
// //   //   setSelectedDebt(debt);
    
// //   //   const initialMonths = Math.ceil(debt.num_meses);
// //   //   let limitMonths = 12; // Valor por defecto
  
// //   //   if (debt.aviso === "CONTRATO SUSPENDIDO") {
// //   //     limitMonths = Math.floor(debt.num_meses);
// //   //     setMaxMonths(limitMonths);
// //   //     setSelectedMonths(Math.min(initialMonths, limitMonths));
// //   //   } else {
// //   //     setMaxMonths(12);
// //   //     setSelectedMonths(Math.min(initialMonths, 12));
// //   //   }
    
// //   //   await fetchContractPayment(debt, Math.min(initialMonths, limitMonths));
// //   //   setShowModal(true);
// //   // };


// //   const handlePaymentClick = async (debt) => {
// //     resetPaymentStates();
// //     setSelectedDebt(debt);
    
// //     const initialMonths = debt.num_meses;
// //     let limitMonths = 12; // Default value
  
// //     if (debt.aviso === "CONTRATO SUSPENDIDO") {
// //       limitMonths = Math.ceil(debt.num_meses);
// //       setMaxMonths(limitMonths);
      
// //       // Generate month options for suspended contracts
// //       const options = [];
// //       for (let i = 1; i <= limitMonths; i++) {
// //         if (i < initialMonths) {
// //           options.push(i);
// //           options.push(Number(i.toFixed(1)));
// //         } else {
// //           options.push(Number(initialMonths.toFixed(1)));
// //           break;
// //         }
// //       }
// //       setMonthOptions(options);
// //       setSelectedMonths(initialMonths);
// //     } else {
// //       setMaxMonths(12);
// //       setMonthOptions([...Array(12)].map((_, i) => i + 1));
// //       setSelectedMonths(Math.min(Math.ceil(initialMonths), 12));
// //     }
    
// //     await fetchContractPayment(debt, initialMonths);
// //     setShowModal(true);
// //   };







// //   const fetchContractPayment = async (debt, months) => {
// //     try {
// //       const user = JSON.parse(localStorage.getItem("user"));
// //       const branch = user.sucursal;
// //       const userId = user.id;

// //       const response = await apiService.searchClientContract(
// //         branch,
// //         debt.servicio,
// //         debt.suscripcion,
// //         months,
// //         userId
// //       );

// //       if (
// //         (response.data.pago_int && Object.keys(response.data.pago_int).length > 0) ||
// //         (response.data.pago_tv && Object.keys(response.data.pago_tv).length > 0)
// //       ) {
// //         const paymentKey = Object.keys(response.data.pago_int || response.data.pago_tv)[0];
// //         const payment = response.data.pago_int?.[paymentKey] || response.data.pago_tv?.[paymentKey];
// //         setContractPayment(payment);
// //       } else {
// //         throw new Error("No se encontraron pagos para este contrato");
// //       }
// //     } catch (err) {
// //       setError(err.message);
// //       setContractPayment(null);
// //     }
// //   };

// //   // const handleMonthChange = async (event) => {
// //   //   const months = parseInt(event.target.value);
// //   //   setSelectedMonths(months);
// //   //   if (selectedDebt) {
// //   //     await fetchContractPayment(selectedDebt, months);
// //   //   }
// //   // };


// //   const handleMonthChange = async (event) => {
// //     const months = parseFloat(event.target.value);
// //     setSelectedMonths(months);
// //     if (selectedDebt) {
// //       await fetchContractPayment(selectedDebt, months);
// //     }
// //   };

// //   const handleConfirmPayment = async () => {
// //     try {
// //       setIsProcessing(true);
// //       const user = JSON.parse(localStorage.getItem("user"));
// //       const branch = user.sucursal;
// //       const userId = user.id;
// //       const paymentInfo = contractPayment || selectedDebt;
// //       if (!paymentInfo) {
// //         throw new Error("No se ha seleccionado ningún pago");
// //       }
// //       const fecha1 = moment().format('YYYY-MM-DD HH:mm:ss.S');

// //       const fact_meses = paymentInfo.pagos_individuales.map(pago => ({
// //             meses: pago.meses,
// //               }));

// //       const facturas_d = paymentInfo.pagos_individuales.map(pago => ({
// //         cod_producto: pago.cod_producto,
// //         descripcion: pago.descripcion,
// //         cantidad: pago.meses,
// //         valor_t: pago.valor_total
// //       }));
// //       let paymentData = {
// //         tipo_aviso: 1,
// //         tipo_fac: 1,
// //         cod_doc: "",
// //         estado: 0,
// //         anulado: 0,
// //         numerodoc: 0,
// //         cod_cliente: paymentInfo.cod_cliente,
// //         cod_usuario: userId,
// //         fecha: fecha1,
// //         total: paymentInfo.total,
// //         contrato: paymentInfo.suscripcion,
// //         sucursal: parseInt(localStorage.getItem('userBranch')),
// //         sucursal: 5,
// //         //aqui tengo mi problema
// //         factura_com: {
// //           meses: selectedMonths  // pinches meses
// //         },      
// //         facturas_d: facturas_d
// //       };
// //       const response = await apiService.realizarPago(branch, paymentInfo.servicio, paymentData);
// //       if (response.status === "success") {
// //         setIsProcessing(false);
// //         setIsPaymentSuccess(true);
// //         setIsAnimating(true);
// //         setInvoiceNumber(response.data.cod_fac);
// //       } else {
// //         throw new Error(response.message || "Error al procesar el pago");
// //       }
// //     } catch (err) {
// //       console.error('Error al procesar el pago:', err);
// //       setError(err.message || 'Error al procesar el pago');
// //       setIsProcessing(false);
// //     }
// //   };

// //   const handleGenerateInvoice = () => {
// //     if (contractPayment) {
// //       invoiceGenerator.generateInvoicePDF({
// //         ...contractPayment,
// //         nombre_cliente: clientDebts.cliente_int?.nombre || clientDebts.cliente_tv?.nombre,
// //         ruc: clientDebts.cliente_int?.ruc || clientDebts.cliente_tv?.ruc,
// //         direccion: selectedDebt.direccion,
// //         invoiceNumber: invoiceNumber, 
// //         telefono: clientDebts.cliente_int?.telefono || clientDebts.cliente_tv?.telefono,
// //       });
// //     }
// //   };

// //   const handleGenerateThermalInvoice = () => {
// //     if (contractPayment) {
// //       invoiceGenerator.generateThermalPrint({
// //         ...contractPayment,
// //         nombre_cliente: clientDebts.cliente_int?.nombre || clientDebts.cliente_tv?.nombre,
// //         ruc: clientDebts.cliente_int?.ruc || clientDebts.cliente_tv?.ruc,
// //         direccion: selectedDebt.direccion,
// //         telefono: clientDebts.cliente_int?.telefono || clientDebts.cliente_tv?.telefono,
// //       });
// //     }
// //   };

// //   const closeModal = () => {
// //     resetPaymentStates();
// //   };

// //   useEffect(() => {
// //     const script = document.createElement('script');
// //     script.src = "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
// //     script.type = "module";
// //     document.body.appendChild(script);

// //     return () => {
// //       document.body.removeChild(script);
// //     }
// //   }, []);

// //   return (
// //     <div className="flex flex-col min-h-screen bg-[#FBFEF7] font-poppins">
// //       <Header />
// //       <div className="h-1 bg-[#f5831f]"></div>
// //       <div className="flex-grow flex flex-col p-6 md:p-12">
// //         <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-6">
// //           Búsqueda de deudas del cliente
// //         </h1>

// //         <div className="p-4 md:p-6 rounded-lg mb-8 max-w-3xl mx-auto">
// //           <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
// //             <div className="flex-grow w-full md:w-auto">
// //               <label htmlFor="cedula" className="text-gray-700 font-bold mb-2 block md:inline-block md:mr-2">
// //                 Cédula o RUC:
// //               </label>
// //               <input
// //                 type="text"
// //                 id="cedula"
// //                 value={cedula}
// //                 onChange={handleInputChange}
// //                 className="w-full md:w-64 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#803f98]"
// //               />
// //             </div>
// //             <button
// //               onClick={handleSearch}
// //               disabled={isSearching}
// //               className={`bg-[#803f98] text-white font-bold py-2 px-4 rounded-md hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-[#803f98] w-full md:w-auto ${isSearching ? 'opacity-50 cursor-not-allowed' : ''}`}
// //             >
// //               {isSearching ? (
// //                 <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
// //               ) : (
// //                 <FontAwesomeIcon icon={faSearch} className="mr-2" />
// //               )}
// //               {isSearching ? 'Buscando...' : 'Buscar'}
// //             </button>
// //           </div>
// //         </div>

// //         {error && (
// //           <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md mb-8">
// //             <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
// //             {error}
// //           </div>
// //         )}

// //         {clientDebts && (
// //           <div className="space-y-8">
// //             {clientDebts.cliente_int && (
// //               <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
// //                 <h2 className="text-2xl font-bold text-[#803f98] mb-6">
// //                   <FontAwesomeIcon icon={faWifi} className="mr-2" />
// //                   Servicios de Internet
// //                 </h2>
// //                 {clientDebts.cliente_int.nombre ? (
// //                   <div className="mb-6">
// //                     <p className="text-gray-700 font-bold">
// //                       <FontAwesomeIcon icon={faUser} className="mr-2" />
// //                       Nombre: {clientDebts.cliente_int.nombre}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
// //                       Dirección: {clientDebts.cliente_int.direccion}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faPhone} className="mr-2" />
// //                       Teléfono: {clientDebts.cliente_int.telefono}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
// //                       Celular: {clientDebts.cliente_int.celular}
// //                     </p>
// //                   </div>
// //                 ) : (
// //                   <div
// //                     className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
// //                     role="alert"
// //                   >
// //                     <p className="font-bold">
// //                       No se encontraron datos del cliente de Internet.
// //                     </p>
// //                   </div>
// //                 )}
// //                 <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 sm:gap-6">
// //                   {Object.values(clientDebts.cliente_int.pagos_internet).map(
// //                     (debt, index) => (
// //                       <div
// //                         key={`internet-${index}`}
// //                         className={`bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border-t-4 ${
// //                           debt.aviso === "CONTRATO SUSPENDIDO"
// //                             ? "border-[#ff4d4f]"
// //                             : "border-[#803f98]"
// //                         }`}
// //                       >
// //                         <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 border-b pb-2">
// //                           <h3 className="text-lg sm:text-xl font-bold text-[#803f98] mb-2 sm:mb-0">
// //                             <FontAwesomeIcon icon={faWifi} className="mr-2" />
// //                             Servicio de Internet
// //                           </h3>
// //                           <span className="text-xs text-gray-500">
// //                             Contrato #{debt.suscripcion}
// //                           </span>
// //                         </div>
// //                         <div className="mb-4 grid grid-cols-1 gap-2 bg-purple-100 p-2 rounded-md">
               








// //                           <p className="text-sm sm:text-base text-gray-700 text-left">
// //                             <span className="font-semibold">Cliente:</span> {clientDebts.cliente_int.nombre}
// //                           </p>
// //                           <p className="text-sm sm:text-base text-gray-700 text-left">
// //                             <span className="font-semibold">Dirección:</span> {debt.direccion}
// //                           </p>
// //                           <p className="text-sm sm:text-base text-gray-700 text-left">
// //                             {/* <span className="font-semibold">Meses:</span> {debt.num_meses.toFixed(2)} */}
// //                           </p>
// //                         </div>
// //                         <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 gap-2 bg-gray-100 p-2 rounded-md">
// //                           <p className="text-sm sm:text-base text-gray-700 text-left">
// //                             <span className="font-semibold">Fecha:</span> {new Date().toLocaleDateString()}
// //                           </p>
// //                           <p className={`text-sm sm:text-base text-gray-700 text-left sm:text-right ${debt.aviso === 'CONTRATO SUSPENDIDO' ? 'text-red-500' : 'text-green-200/10'}`}>
// //                             <span className="font-semibold">Aviso:</span> {debt.aviso}
// //                           </p>
// //                         </div>

// //                         <div className="bg-gray-100 p-3 sm:p-4 rounded-md mb-4 overflow-x-auto">
// //                           <h4 className="font-bold text-[#803f98] mb-2 text-sm sm:text-base">
// //                             Detalle de servicios
// //                           </h4>
// //                           <table className="w-full text-xs sm:text-sm">
// //                             <thead>
// //                               <tr className="border-b">
// //                                 <th className="text-left py-2">Descripción</th>
// //                                 <th className="text-right py-2">Meses</th>
// //                                 <th className="text-right py-2">Valor unitario</th>
// //                                 <th className="text-right py-2">Valor total</th>
// //                               </tr>
// //                             </thead>
// //                             <tbody>
// //                               {debt.pagos_individuales.map((pago, index) => (
// //                                 <tr key={`${pago.serial}-${index}`} className="border-b">
// //                                   <td className="py-2 text-left">{pago.descripcion}</td>
// //                                   <td className="text-right py-2">{pago.meses.toFixed(0)}</td>
// //                                   <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
// //                                   <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
// //                                 </tr>
// //                               ))}
// //                             </tbody>
// //                           </table>
// //                         </div>
// //                         <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end">
// //                           <div className="mb-4 sm:mb-0">
// //                             {debt.pendiente ? (
// //                               <p className="text-yellow-600 font-bold">Este es un pago pendiente</p>
// //                             ) : (
// //                               <button
// //                                 className={`bg-[#803f98] text-white font-bold py-2 px-4 rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-[#803f98] text-sm sm:text-base ${
// //                                   debt.aviso === "SUSPENDIDO" ? "opacity-50 cursor-not-allowed" : ""
// //                                 }`}
// //                                 onClick={() => handlePaymentClick(debt)}
// //                                 disabled={debt.aviso === "SUSPENDIDO"}
// //                               >
// //                                 <FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
// //                                 Pagar
// //                               </button>
// //                             )}
// //                           </div>
// //                           <div className="text-right">
// //                             <p className="text-sm sm:text-base text-gray-700">
// //                               <span className="font-semibold">Subtotal:</span>{" "}
// //                               <span className="ml-2">${debt.subtotal.toFixed(2)}</span>
// //                             </p>
// //                             <p className="text-sm sm:text-base text-gray-700">
// //                               <span className="font-semibold">IVA:</span>{" "}
// //                               <span className="ml-2">${debt.iva.toFixed(2)}</span>
// //                             </p>
// //                             <p className="text-sm sm:text-base text-gray-700">
// //                               <span className="font-semibold">ICE:</span>{" "}
// //                               <span className="ml-2">${debt.ice.toFixed(2)}</span>
// //                             </p>
// //                             <p className="text-lg sm:text-xl font-bold text-[#803f98] mt-2">
// //                               Total: ${debt.total.toFixed(2)}
// //                             </p>
// //                           </div>
// //                         </div>
// //                       </div>
// //                     )
// //                   )}
// //                 </div>

// //                 {Object.values(clientDebts.cliente_int.pagos_internet).length === 0 && (
// //                   <div className="bg-[#e6f7ff] p-6 rounded-lg shadow-md">
// //                     <p className="text-gray-700 font-bold">
// //                       <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
// //                       No se encontraron deudas de servicio de Internet.
// //                     </p>
// //                   </div>
// //                 )}
// //               </div>
// //             )}

// //             {clientDebts.cliente_tv && (
// //               <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
// //                 <h2 className="text-2xl font-bold text-[#803f98] mb-6">
// //                   <FontAwesomeIcon icon={faTv} className="mr-2" />
// //                   Servicios de TV
// //                 </h2>
// //                 {clientDebts.cliente_tv.nombre ? (
// //                   <div className="mb-6">
// //                     <p className="text-gray-700 font-bold">
// //                       <FontAwesomeIcon icon={faUser} className="mr-2" />
// //                       Nombre: {clientDebts.cliente_tv.nombre}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
// //                       Dirección: {clientDebts.cliente_tv.direccion}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faPhone} className="mr-2" />
// //                       Teléfono: {clientDebts.cliente_tv.telefono}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
// //                       Celular: {clientDebts.cliente_tv.celular}
// //                     </p>
// //                   </div>
// //                 ) : (
// //                   <div
// //                     className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
// //                     role="alert"
// //                   >
// //                     <p className="font-bold">
// //                       No se encontraron datos del cliente de TV.
// //                     </p>
// //                   </div>
// //                 )}
// //                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
// //                   {clientDebts.cliente_tv.pagos_tv &&
// //                     Object.values(clientDebts.cliente_tv.pagos_tv).map((debt, index) => (
// //                       <div
// //                         key={`tv-${index}`}
// //                         className={`bg-[#ffffff] p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border-t-4 border-[#f5831f] ${
// //                           debt.aviso === 'CONTRATO SUSPENDIDO' ? 'bg-[#ff4d4f]' : ''
// //                         }`}
// //                       > 
// //                         <div className="flex justify-between items-center mb-4 border-b pb-2">
// //                           <h3 className="text-xl font-bold text-[#f5831f]">
// //                             <FontAwesomeIcon icon={faTv} className="mr-2" />
// //                             Servicio de TV
// //                           </h3> 
// //                           <span className="text-xs text-gray-500">Contrato #{debt.suscripcion}</span>
// //                         </div>
// //                         <div className="mb-4 grid grid-cols-1 gap-3 bg-orange-100 p-2 rounded-md">
// //                           <p className="text-gray-700 text-left"><span className="font-semibold">Cliente:</span> {clientDebts.cliente_tv.nombre}</p>
// //                           <p className="text-gray-700 text-left"><span className="font-semibold">Dirección:</span> {clientDebts.cliente_tv.direccion}</p>
// //                           {/* <p className="text-gray-700 text-left"><span className="font-semibold">Meses:</span> {debt.num_meses.toFixed(2)}</p> */}
// //                         </div>
// //                         <div className="mb-4 grid grid-cols-2 gap-3 bg-gray-100 p-2 rounded-md">
// //                           <p className="text-gray-700 text-left"><span className="font-semibold">Fecha:</span> {new Date().toLocaleDateString()}</p>
// //                           <p className={`text-sm sm:text-base text-gray-700 text-left sm:text-right ${debt.aviso === 'CONTRATO SUSPENDIDO' ? 'text-red-500' : 'text-gray-100'}`}>
// //                             <span className="font-semibold">Aviso:</span> {debt.aviso}
// //                           </p>
// //                         </div>
// //                         <div className="bg-gray-100 p-4 rounded-md mb-4">
// //                           <h4 className="font-bold text-[#f5831f] mb-2">Detalle de servicios</h4>
// //                           <table className="w-full">
// //                             <thead>
// //                               <tr className="border-b">
// //                                 <th className="text-left py-2">Descripción</th>
// //                                 <th className="text-right py-2">Meses</th>
// //                                 <th className="text-right py-2">Valor unitario</th>
// //                                 <th className="text-right py-2">Valor total</th>
// //                               </tr>
// //                             </thead>
// //                             <tbody>
// //                               {debt.pagos_individuales.map((pago, index) => (
// //                                 <tr key={`${pago.serial}-${index}`} className="border-b">
// //                                   <td className="py-2 text-left">{pago.descripcion}</td>
// //                                   <td className="text-right py-2">{pago.meses.toFixed(0)}</td>
// //                                   <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
// //                                   <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
// //                                 </tr>   
// //                               ))}
// //                             </tbody>
// //                           </table>
// //                         </div>
// //                         <div className="flex justify-between items-end">
// //                           <div>
// //                             {debt.pendiente ? (
// //                               <p className="text-yellow-600 font-bold">Este es un pago pendiente</p>
// //                             ) : (
// //                               <button
// //                                 className={`bg-[#f5831f] text-white font-bold py-2 px-4 rounded-md hover:bg-[#ff7875] focus:outline-none focus:ring-2 focus:ring-[#ff4d4f] ${
// //                                   debt.aviso === 'SUSPENDIDO' ? 'opacity-50 cursor-not-allowed' : ''
// //                                 }`}
// //                                 onClick={() => handlePaymentClick(debt)}
// //                                 disabled={debt.aviso === 'SUSPENDIDO'}
// //                               >
// //                                 <FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
// //                                 Pagar
// //                               </button>
// //                             )}
// //                           </div>
// //                           <div className="text-right">
// //                             <p className="text-gray-700"><span className="font-semibold">Subtotal:</span> <span className="ml-2">${debt.subtotal.toFixed(2)}</span></p>
// //                             <p className="text-gray-700"><span className="font-semibold">IVA:</span> <span className="ml-2">${debt.iva.toFixed(2)}</span></p>
// //                             <p className="text-gray-700"><span className="font-semibold">ICE:</span> <span className="ml-2">${debt.ice.toFixed(2)}</span></p>
// //                             <p className="text-xl font-bold text-[#f5831f] mt-2">Total: ${debt.total.toFixed(2)}</p>
// //                           </div>
// //                         </div>
// //                       </div>
// //                     ))}
// //                 </div>
// //                 {(!clientDebts.cliente_tv.pagos_tv ||
// //                   Object.values(clientDebts.cliente_tv.pagos_tv).length === 0) && (
// //                   <div className="bg-[#fff1f0] p-6 rounded-lg shadow-md">
// //                     <p className="text-gray-700 font-bold">
// //                       <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
// //                       No se encontraron deudas de servicio de TV.
// //                     </p>
// //                   </div>
// //                 )}
// //               </div>
// //             )}
// //             {!clientDebts.cliente_int && !clientDebts.cliente_tv && (
// //               <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 col-span-2">
// //                 <p className="text-gray-700 font-bold">
// //                   <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
// //                   No se encontraron datos de deudas del cliente.
// //                 </p>
// //               </div>
// //             )}
// //           </div>
// //         )}
// //       </div>
// //       <Footer />

// //       {showModal && (
// //         <div className="fixed z-10 inset-0 overflow-y-auto">
// //           <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
// //             <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
// //             <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            







// //               <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${isAnimating ? 'animate-fade-out' : ''}`}>
// //               {isProcessing ? (
// //                 <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
// //                   <div className="sm:flex sm:items-start justify-center">
// //                     <div className="mt-3 text-center sm:mt-0 sm:text-left">
// //                       <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-[#f5831f] mb-4" />
// //                       <h3 className="text-lg leading-6 font-medium text-gray-900">Procesando pago</h3>
// //                       <div className="mt-2">
// //                         <p className="text-sm text-gray-500">Por favor, espere mientras procesamos su pago.</p>
// //                       </div>
// //                     </div>
// //                   </div>
// //                 </div>
// //               ) : isPaymentSuccess ? (
// //                 <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
// //                   <div className="flex flex-col items-center justify-center">
// //                     <div className="flex justify-center items-center" style={{ width: '100px', height: '100px' }}>
// //                       <dotlottie-player 
// //                         src="https://lottie.host/fc2c7f88-d12a-42b8-953c-e8c794436ccc/hNunZ600Yl.json" 
// //                         background="transparent" 
// //                         speed="1" 
// //                         style={{ width: '100px', height: '100px' }} 
// //                         loop 
// //                         autoplay
// //                       ></dotlottie-player>
// //                     </div>
// //                     <div className="mt-3 text-center">
// //                       <h3 className="text-lg leading-6 font-medium text-gray-900">Pago realizado con éxito</h3>
// //                       <div className="mt-2">
// //                         <p className="text-sm text-gray-500">Su pago ha sido procesado correctamente. Gracias por su transacción.</p>
// //                       </div>
// //                     </div>
// //                     <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
// //                       <button
// //                         type="button"
// //                         className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#803f98] text-base font-medium text-white hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:col-start-1 sm:text-sm"
// //                         onClick={handleGenerateInvoice}
// //                       >
// //                         <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
// //                         Ver Factura
// //                       </button>
// //                       <button
// //                         type="button"
// //                         className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:mt-0 sm:col-start-2 sm:text-sm"
// //                         onClick={handleGenerateThermalInvoice}
// //                       >
// //                         <FontAwesomeIcon icon={faPrint} className="mr-2" />
// //                         Ver Factura Térmica
// //                       </button>
// //                     </div>
// //                   </div>
// //                 </div>
// //               ) : (
// //                 <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
// //                   <div className="sm:flex sm:items-start">
// //                     <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
// //                       <div className={`border-t-4 ${selectedDebt && selectedDebt.servicio === 'internet' ? 'border-[#803f98]' : 'border-[#f5831f]'} rounded-t-lg shadow-lg p-4 mb-4`}>
// //                         <div className="flex justify-between items-center mb-4">
// //                           <span className="text-xs text-gray-500">Contrato #{selectedDebt && selectedDebt.suscripcion}</span>
// //                         </div>
// //                         {selectedDebt && selectedDebt.aviso === "CONTRATO SUSPENDIDO" && (
// //                           <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
// //                             <p className="font-bold">Atención: Este es un contrato suspendido.</p>
// //                           </div>
// //                         )}
// //                         {contractPayment && (
// //                           <>
// //                             <div className="mb-4">
// //                               <label htmlFor="months" className="block text-sm font-medium text-gray-700 mb-2">Número de meses:</label>
// //                               <select
// //                                 id="months"
// //                                 value={selectedMonths}
// //                                 onChange={handleMonthChange}
// //                                 className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
// //                               >
// //                                 {[...Array(maxMonths)].map((_, i) => (
// //                                   <option key={i + 1} value={i + 1}>{i + 1}</option>
// //                                 ))}
// //                               </select>
                              
// //                             </div>
// //                             <div className="bg-gray-100 p-4 rounded-md mb-4">
// //                               <h5 className="font-bold mb-2">Detalle de Servicios</h5>
// //                               <table className="w-full text-sm">
// //                                 <thead>
// //                                   <tr className="border-b">
// //                                     <th className="text-left py-2">Descripción</th>
// //                                     <th className="text-right py-2">Valor Unitario</th>
// //                                     <th className="text-right py-2">Valor Total</th>
// //                                   </tr>
// //                                 </thead>
// //                                 <tbody>
// //                                   {contractPayment.pagos_individuales.map((pago, index) => (
// //                                     <tr key={index} className="border-b">
// //                                       <td className="py-2">{pago.descripcion}</td>
// //                                       <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
// //                                       <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
// //                                     </tr>
// //                                   ))}
// //                                 </tbody>
// //                               </table>
// //                             </div>
// //                             <div className="bg-gray-100 p-4 rounded-md">
// //                               <h5 className="font-bold mb-2">Resumen de Pago</h5>
// //                               <div className="flex justify-between">
// //                                 <span>Subtotal:</span>
// //                                 <span>${contractPayment.subtotal.toFixed(2)}</span>
// //                               </div>
// //                               <div className="flex justify-between">
// //                                 <span>IVA:</span>
// //                                 <span>${contractPayment.iva.toFixed(2)}</span>
// //                               </div>
// //                               <div className="flex justify-between">
// //                                 <span>ICE:</span>
// //                                 <span>${contractPayment.ice.toFixed(2)}</span>
// //                               </div>
// //                               <div className="flex justify-between font-bold mt-2 pt-2 border-t">
// //                                 <span>Total:</span>
// //                                 <span>${contractPayment.total.toFixed(2)}</span>
// //                               </div>
// //                             </div>
// //                           </>
// //                         )}
// //                       </div>
// //                     </div>
// //                   </div>
// //                 </div>
// //               )}
// //               <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
// //                 {!isPaymentSuccess && !isProcessing && (
// //                   <>
// //                     <button
// //                       type="button"
// //                       className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#f5831f] text-base font-medium text-white hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#f5831f] sm:ml-3 sm:w-auto sm:text-sm"
// //                       onClick={handleConfirmPayment}
// //                     >
// //                       Confirmar Pago
// //                     </button>
// //                     <button
// //                       type="button"
// //                       className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
// //                       onClick={closeModal}
// //                     >
// //                       <FontAwesomeIcon icon={faTimes} className="mr-2" />
// //                       Cancelar
// //                     </button>
// //                   </>
// //                 )}
// //                 {isPaymentSuccess && (
// //                   <button
// //                     type="button"
// //                     className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#803f98] text-base font-medium text-white hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:ml-3 sm:w-auto sm:text-sm"
// //                     onClick={closeModal}
// //                   >
// //                     Cerrar
// //                   </button>
// //                 )}
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default ClientDebt;


// // import React, { useState, useEffect, useCallback } from 'react';
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { faTimes, faSpinner, faMoneyBillWave, faPrint, faFileInvoice, faSearch, faExclamationCircle, faInfoCircle, faUser, faMapMarkerAlt, faPhone, faMobileAlt, faWifi, faTv } from '@fortawesome/free-solid-svg-icons';
// // import apiService from "../services/apiService";
// // import Header from "../components/header";
// // import Footer from "../components/footer";
// // import moment from 'moment';
// // import InvoiceGenerator from './InvoiceGenerator';

// // const ClientDebt = () => {
// //   const [cedula, setCedula] = useState("");
// //   const [clientDebts, setClientDebts] = useState(null);
// //   const [isProcessing, setIsProcessing] = useState(false);
// //   const [error, setError] = useState(null);
// //   const [showModal, setShowModal] = useState(false);
// //   const [selectedDebt, setSelectedDebt] = useState(null);
// //   const [selectedMonths, setSelectedMonths] = useState(1);
// //   const [contractPayment, setContractPayment] = useState(null);
// //   const [maxMonths, setMaxMonths] = useState(6);
// //   const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
// //   const [isAnimating, setIsAnimating] = useState(false);
// //   const [invoiceNumber, setInvoiceNumber] = useState(null);
// //   const [isSearching, setIsSearching] = useState(false);

// //   const invoiceGenerator = InvoiceGenerator();

// //   const validateInput = (value) => {
// //     const regex = /^[0-9]*$/;
// //     if (!regex.test(value)) {
// //       setError('Solo se permiten números');
// //       return false;
// //     }
// //     setError('');
// //     return true;
// //   };

// //   const handleInputChange = (e) => {
// //     const value = e.target.value;
// //     if (validateInput(value)) {
// //       setCedula(value);
// //       setClientDebts(null);
// //       setError(null);
// //     }
// //   };

// //   const handleSearch = async () => {
// //     if (cedula.trim() === '') {
// //       setError('Este campo es requerido');
// //       return;
// //     }
// //     setIsSearching(true);
// //     setError(null);
// //     try {
// //       const user = JSON.parse(localStorage.getItem("user"));
// //       const branch = user.sucursal;
// //       const userId = user.id;

// //       if (!branch || !userId) {
// //         throw new Error('Información de usuario no disponible');
// //       }

// //       const response = await apiService.searchClientDebts(branch, cedula, userId);
// //       setClientDebts(response.data.pagos);
// //     } catch (err) {
// //       setError(err.message || 'Error al buscar deudas del cliente');
// //       setClientDebts(null);
// //     } finally {
// //       setIsSearching(false);
// //     }
// //   };








// //   const resetPaymentStates = () => {
// //     setShowModal(false);
// //     setIsProcessing(false);
// //     setIsPaymentSuccess(false);
// //     setSelectedDebt(null);
// //     setContractPayment(null);
// //     setSelectedMonths(1);
// //     setMaxMonths(6);
// //   };

// //   const handlePaymentClick = async (debt) => {
// //     resetPaymentStates();
// //     setSelectedDebt(debt);
    
// //     const initialMonths = Math.ceil(debt.num_meses);
// //     let limitMonths = 12; // Valor por defecto
  
// //     if (debt.aviso === "CONTRATO SUSPENDIDO") {
// //       limitMonths = Math.floor(debt.num_meses);
// //       setMaxMonths(limitMonths);
// //       setSelectedMonths(Math.min(initialMonths, limitMonths));
// //     } else {
// //       setMaxMonths(12);
// //       setSelectedMonths(Math.min(initialMonths, 12));
// //     }
    
// //     await fetchContractPayment(debt, Math.min(initialMonths, limitMonths));
// //     setShowModal(true);
// //   };

// //   const fetchContractPayment = async (debt, months) => {
// //     try {
// //       const user = JSON.parse(localStorage.getItem("user"));
// //       const branch = user.sucursal;
// //       const userId = user.id;

// //       const response = await apiService.searchClientContract(
// //         branch,
// //         debt.servicio,
// //         debt.suscripcion,
// //         months,
// //         userId
// //       );

// //       if (
// //         (response.data.pago_int && Object.keys(response.data.pago_int).length > 0) ||
// //         (response.data.pago_tv && Object.keys(response.data.pago_tv).length > 0)
// //       ) {
// //         const paymentKey = Object.keys(response.data.pago_int || response.data.pago_tv)[0];
// //         const payment = response.data.pago_int?.[paymentKey] || response.data.pago_tv?.[paymentKey];
// //         setContractPayment(payment);
// //       } else {
// //         throw new Error("No se encontraron pagos para este contrato");
// //       }
// //     } catch (err) {
// //       setError(err.message);
// //       setContractPayment(null);
// //     }
// //   };

// //   const handleMonthChange = async (event) => {
// //     const months = parseInt(event.target.value);
// //     setSelectedMonths(months);
// //     if (selectedDebt) {
// //       await fetchContractPayment(selectedDebt, months);
// //     }
// //   };

// //   const handleConfirmPayment = async () => {
// //     try {
// //       setIsProcessing(true);
// //       const user = JSON.parse(localStorage.getItem("user"));
// //       const branch = user.sucursal;
// //       const userId = user.id;
// //       const paymentInfo = contractPayment || selectedDebt;
// //       if (!paymentInfo) {
// //         throw new Error("No se ha seleccionado ningún pago");
// //       }
// //       const fecha1 = moment().format('YYYY-MM-DD HH:mm:ss.S');

// //       const fact_meses = paymentInfo.pagos_individuales.map(pago => ({
// //             meses: pago.meses,
// //               }));

// //       const facturas_d = paymentInfo.pagos_individuales.map(pago => ({
// //         cod_producto: pago.cod_producto,
// //         descripcion: pago.descripcion,
// //         cantidad: pago.meses,
// //         valor_t: pago.valor_total
// //       }));
// //       let paymentData = {
// //         tipo_aviso: 1,
// //         tipo_fac: 1,
// //         cod_doc: "",
// //         estado: 0,
// //         anulado: 0,
// //         numerodoc: 0,
// //         cod_cliente: paymentInfo.cod_cliente,
// //         cod_usuario: userId,
// //         fecha: fecha1,
// //         total: paymentInfo.total,
// //         contrato: paymentInfo.suscripcion,
// //         sucursal: parseInt(localStorage.getItem('userBranch')),
// //         sucursal: 5,
// //         //aqui tengo mi problema
// //         factura_com: {
// //           meses: selectedMonths  // pinches meses
// //         },      
// //         facturas_d: facturas_d
// //       };
// //       const response = await apiService.realizarPago(branch, paymentInfo.servicio, paymentData);
// //       if (response.status === "success") {
// //         setIsProcessing(false);
// //         setIsPaymentSuccess(true);
// //         setIsAnimating(true);
// //         setInvoiceNumber(response.data.cod_fac);
// //       } else {
// //         throw new Error(response.message || "Error al procesar el pago");
// //       }
// //     } catch (err) {
// //       console.error('Error al procesar el pago:', err);
// //       setError(err.message || 'Error al procesar el pago');
// //       setIsProcessing(false);
// //     }
// //   };

// //   const handleGenerateInvoice = () => {
// //     if (contractPayment) {
// //       invoiceGenerator.generateInvoicePDF({
// //         ...contractPayment,
// //         nombre_cliente: clientDebts.cliente_int?.nombre || clientDebts.cliente_tv?.nombre,
// //         ruc: clientDebts.cliente_int?.ruc || clientDebts.cliente_tv?.ruc,
// //         direccion: selectedDebt.direccion,
// //         invoiceNumber: invoiceNumber, 
// //         telefono: clientDebts.cliente_int?.telefono || clientDebts.cliente_tv?.telefono,
// //       });
// //     }
// //   };

// //   const handleGenerateThermalInvoice = () => {
// //     if (contractPayment) {
// //       invoiceGenerator.generateThermalPrint({
// //         ...contractPayment,
// //         nombre_cliente: clientDebts.cliente_int?.nombre || clientDebts.cliente_tv?.nombre,
// //         ruc: clientDebts.cliente_int?.ruc || clientDebts.cliente_tv?.ruc,
// //         direccion: selectedDebt.direccion,
// //         telefono: clientDebts.cliente_int?.telefono || clientDebts.cliente_tv?.telefono,
// //       });
// //     }
// //   };

// //   const closeModal = () => {
// //     resetPaymentStates();
// //   };

// //   useEffect(() => {
// //     const script = document.createElement('script');
// //     script.src = "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
// //     script.type = "module";
// //     document.body.appendChild(script);

// //     return () => {
// //       document.body.removeChild(script);
// //     }
// //   }, []);

// //   return (
// //     <div className="flex flex-col min-h-screen bg-[#FBFEF7] font-poppins">
// //       <Header />
// //       <div className="h-1 bg-[#f5831f]"></div>
// //       <div className="flex-grow flex flex-col p-6 md:p-12">
// //         <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-6">
// //           Búsqueda de deudas del cliente
// //         </h1>

// //         <div className="p-4 md:p-6 rounded-lg mb-8 max-w-3xl mx-auto">
// //           <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
// //             <div className="flex-grow w-full md:w-auto">
// //               <label htmlFor="cedula" className="text-gray-700 font-bold mb-2 block md:inline-block md:mr-2">
// //                 Cédula o RUC:
// //               </label>
// //               <input
// //                 type="text"
// //                 id="cedula"
// //                 value={cedula}
// //                 onChange={handleInputChange}
// //                 className="w-full md:w-64 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#803f98]"
// //               />
// //             </div>
// //             <button
// //               onClick={handleSearch}
// //               disabled={isSearching}
// //               className={`bg-[#803f98] text-white font-bold py-2 px-4 rounded-md hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-[#803f98] w-full md:w-auto ${isSearching ? 'opacity-50 cursor-not-allowed' : ''}`}
// //             >
// //               {isSearching ? (
// //                 <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
// //               ) : (
// //                 <FontAwesomeIcon icon={faSearch} className="mr-2" />
// //               )}
// //               {isSearching ? 'Buscando...' : 'Buscar'}
// //             </button>
// //           </div>
// //         </div>

// //         {error && (
// //           <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md mb-8">
// //             <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
// //             {error}
// //           </div>
// //         )}

// //         {clientDebts && (
// //           <div className="space-y-8">
// //             {clientDebts.cliente_int && (
// //               <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
// //                 <h2 className="text-2xl font-bold text-[#803f98] mb-6">
// //                   <FontAwesomeIcon icon={faWifi} className="mr-2" />
// //                   Servicios de Internet
// //                 </h2>
// //                 {clientDebts.cliente_int.nombre ? (
// //                   <div className="mb-6">
// //                     <p className="text-gray-700 font-bold">
// //                       <FontAwesomeIcon icon={faUser} className="mr-2" />
// //                       Nombre: {clientDebts.cliente_int.nombre}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
// //                       Dirección: {clientDebts.cliente_int.direccion}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faPhone} className="mr-2" />
// //                       Teléfono: {clientDebts.cliente_int.telefono}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
// //                       Celular: {clientDebts.cliente_int.celular}
// //                     </p>
// //                   </div>
// //                 ) : (
// //                   <div
// //                     className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
// //                     role="alert"
// //                   >
// //                     <p className="font-bold">
// //                       No se encontraron datos del cliente de Internet.
// //                     </p>
// //                   </div>
// //                 )}
// //                 <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 sm:gap-6">
// //                   {Object.values(clientDebts.cliente_int.pagos_internet).map(
// //                     (debt, index) => (
// //                       <div
// //                         key={`internet-${index}`}
// //                         className={`bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border-t-4 ${
// //                           debt.aviso === "CONTRATO SUSPENDIDO"
// //                             ? "border-[#ff4d4f]"
// //                             : "border-[#803f98]"
// //                         }`}
// //                       >
// //                         <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 border-b pb-2">
// //                           <h3 className="text-lg sm:text-xl font-bold text-[#803f98] mb-2 sm:mb-0">
// //                             <FontAwesomeIcon icon={faWifi} className="mr-2" />
// //                             Servicio de Internet
// //                           </h3>
// //                           <span className="text-xs text-gray-500">
// //                             Contrato #{debt.suscripcion}
// //                           </span>
// //                         </div>
// //                         <div className="mb-4 grid grid-cols-1 gap-2 bg-purple-100 p-2 rounded-md">
               








// //                           <p className="text-sm sm:text-base text-gray-700 text-left">
// //                             <span className="font-semibold">Cliente:</span> {clientDebts.cliente_int.nombre}
// //                           </p>
// //                           <p className="text-sm sm:text-base text-gray-700 text-left">
// //                             <span className="font-semibold">Dirección:</span> {debt.direccion}
// //                           </p>
// //                           <p className="text-sm sm:text-base text-gray-700 text-left">
// //                             {/* <span className="font-semibold">Meses:</span> {debt.num_meses.toFixed(2)} */}
// //                           </p>
// //                         </div>
// //                         <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 gap-2 bg-gray-100 p-2 rounded-md">
// //                           <p className="text-sm sm:text-base text-gray-700 text-left">
// //                             <span className="font-semibold">Fecha:</span> {new Date().toLocaleDateString()}
// //                           </p>
// //                           <p className={`text-sm sm:text-base text-gray-700 text-left sm:text-right ${debt.aviso === 'CONTRATO SUSPENDIDO' ? 'text-red-500' : 'text-green-200/10'}`}>
// //                             <span className="font-semibold">Aviso:</span> {debt.aviso}
// //                           </p>
// //                         </div>

// //                         <div className="bg-gray-100 p-3 sm:p-4 rounded-md mb-4 overflow-x-auto">
// //                           <h4 className="font-bold text-[#803f98] mb-2 text-sm sm:text-base">
// //                             Detalle de servicios
// //                           </h4>
// //                           <table className="w-full text-xs sm:text-sm">
// //                             <thead>
// //                               <tr className="border-b">
// //                                 <th className="text-left py-2">Descripción</th>
// //                                 <th className="text-right py-2">Meses</th>
// //                                 <th className="text-right py-2">Valor unitario</th>
// //                                 <th className="text-right py-2">Valor total</th>
// //                               </tr>
// //                             </thead>
// //                             <tbody>
// //                               {debt.pagos_individuales.map((pago, index) => (
// //                                 <tr key={`${pago.serial}-${index}`} className="border-b">
// //                                   <td className="py-2 text-left">{pago.descripcion}</td>
// //                                   <td className="text-right py-2">{pago.meses.toFixed(0)}</td>
// //                                   <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
// //                                   <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
// //                                 </tr>
// //                               ))}
// //                             </tbody>
// //                           </table>
// //                         </div>
// //                         <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end">
// //                           <div className="mb-4 sm:mb-0">
// //                             {debt.pendiente ? (
// //                               <p className="text-yellow-600 font-bold">Este es un pago pendiente</p>
// //                             ) : (
// //                               <button
// //                                 className={`bg-[#803f98] text-white font-bold py-2 px-4 rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-[#803f98] text-sm sm:text-base ${
// //                                   debt.aviso === "SUSPENDIDO" ? "opacity-50 cursor-not-allowed" : ""
// //                                 }`}
// //                                 onClick={() => handlePaymentClick(debt)}
// //                                 disabled={debt.aviso === "SUSPENDIDO"}
// //                               >
// //                                 <FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
// //                                 Pagar
// //                               </button>
// //                             )}
// //                           </div>
// //                           <div className="text-right">
// //                             <p className="text-sm sm:text-base text-gray-700">
// //                               <span className="font-semibold">Subtotal:</span>{" "}
// //                               <span className="ml-2">${debt.subtotal.toFixed(2)}</span>
// //                             </p>
// //                             <p className="text-sm sm:text-base text-gray-700">
// //                               <span className="font-semibold">IVA:</span>{" "}
// //                               <span className="ml-2">${debt.iva.toFixed(2)}</span>
// //                             </p>
// //                             <p className="text-sm sm:text-base text-gray-700">
// //                               <span className="font-semibold">ICE:</span>{" "}
// //                               <span className="ml-2">${debt.ice.toFixed(2)}</span>
// //                             </p>
// //                             <p className="text-lg sm:text-xl font-bold text-[#803f98] mt-2">
// //                               Total: ${debt.total.toFixed(2)}
// //                             </p>
// //                           </div>
// //                         </div>
// //                       </div>
// //                     )
// //                   )}
// //                 </div>

// //                 {Object.values(clientDebts.cliente_int.pagos_internet).length === 0 && (
// //                   <div className="bg-[#e6f7ff] p-6 rounded-lg shadow-md">
// //                     <p className="text-gray-700 font-bold">
// //                       <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
// //                       No se encontraron deudas de servicio de Internet.
// //                     </p>
// //                   </div>
// //                 )}
// //               </div>
// //             )}

// //             {clientDebts.cliente_tv && (
// //               <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
// //                 <h2 className="text-2xl font-bold text-[#803f98] mb-6">
// //                   <FontAwesomeIcon icon={faTv} className="mr-2" />
// //                   Servicios de TV
// //                 </h2>
// //                 {clientDebts.cliente_tv.nombre ? (
// //                   <div className="mb-6">
// //                     <p className="text-gray-700 font-bold">
// //                       <FontAwesomeIcon icon={faUser} className="mr-2" />
// //                       Nombre: {clientDebts.cliente_tv.nombre}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
// //                       Dirección: {clientDebts.cliente_tv.direccion}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faPhone} className="mr-2" />
// //                       Teléfono: {clientDebts.cliente_tv.telefono}
// //                     </p>
// //                     <p className="text-gray-700">
// //                       <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
// //                       Celular: {clientDebts.cliente_tv.celular}
// //                     </p>
// //                   </div>
// //                 ) : (
// //                   <div
// //                     className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
// //                     role="alert"
// //                   >
// //                     <p className="font-bold">
// //                       No se encontraron datos del cliente de TV.
// //                     </p>
// //                   </div>
// //                 )}
// //                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
// //                   {clientDebts.cliente_tv.pagos_tv &&
// //                     Object.values(clientDebts.cliente_tv.pagos_tv).map((debt, index) => (
// //                       <div
// //                         key={`tv-${index}`}
// //                         className={`bg-[#ffffff] p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border-t-4 border-[#f5831f] ${
// //                           debt.aviso === 'CONTRATO SUSPENDIDO' ? 'bg-[#ff4d4f]' : ''
// //                         }`}
// //                       > 
// //                         <div className="flex justify-between items-center mb-4 border-b pb-2">
// //                           <h3 className="text-xl font-bold text-[#f5831f]">
// //                             <FontAwesomeIcon icon={faTv} className="mr-2" />
// //                             Servicio de TV
// //                           </h3> 
// //                           <span className="text-xs text-gray-500">Contrato #{debt.suscripcion}</span>
// //                         </div>
// //                         <div className="mb-4 grid grid-cols-1 gap-3 bg-orange-100 p-2 rounded-md">
// //                           <p className="text-gray-700 text-left"><span className="font-semibold">Cliente:</span> {clientDebts.cliente_tv.nombre}</p>
// //                           <p className="text-gray-700 text-left"><span className="font-semibold">Dirección:</span> {clientDebts.cliente_tv.direccion}</p>
// //                           {/* <p className="text-gray-700 text-left"><span className="font-semibold">Meses:</span> {debt.num_meses.toFixed(2)}</p> */}
// //                         </div>
// //                         <div className="mb-4 grid grid-cols-2 gap-3 bg-gray-100 p-2 rounded-md">
// //                           <p className="text-gray-700 text-left"><span className="font-semibold">Fecha:</span> {new Date().toLocaleDateString()}</p>
// //                           <p className={`text-sm sm:text-base text-gray-700 text-left sm:text-right ${debt.aviso === 'CONTRATO SUSPENDIDO' ? 'text-red-500' : 'text-gray-100'}`}>
// //                             <span className="font-semibold">Aviso:</span> {debt.aviso}
// //                           </p>
// //                         </div>
// //                         <div className="bg-gray-100 p-4 rounded-md mb-4">
// //                           <h4 className="font-bold text-[#f5831f] mb-2">Detalle de servicios</h4>
// //                           <table className="w-full">
// //                             <thead>
// //                               <tr className="border-b">
// //                                 <th className="text-left py-2">Descripción</th>
// //                                 <th className="text-right py-2">Meses</th>
// //                                 <th className="text-right py-2">Valor unitario</th>
// //                                 <th className="text-right py-2">Valor total</th>
// //                               </tr>
// //                             </thead>
// //                             <tbody>
// //                               {debt.pagos_individuales.map((pago, index) => (
// //                                 <tr key={`${pago.serial}-${index}`} className="border-b">
// //                                   <td className="py-2 text-left">{pago.descripcion}</td>
// //                                   <td className="text-right py-2">{pago.meses.toFixed(0)}</td>
// //                                   <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
// //                                   <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
// //                                 </tr>   
// //                               ))}
// //                             </tbody>
// //                           </table>
// //                         </div>
// //                         <div className="flex justify-between items-end">
// //                           <div>
// //                             {debt.pendiente ? (
// //                               <p className="text-yellow-600 font-bold">Este es un pago pendiente</p>
// //                             ) : (
// //                               <button
// //                                 className={`bg-[#f5831f] text-white font-bold py-2 px-4 rounded-md hover:bg-[#ff7875] focus:outline-none focus:ring-2 focus:ring-[#ff4d4f] ${
// //                                   debt.aviso === 'SUSPENDIDO' ? 'opacity-50 cursor-not-allowed' : ''
// //                                 }`}
// //                                 onClick={() => handlePaymentClick(debt)}
// //                                 disabled={debt.aviso === 'SUSPENDIDO'}
// //                               >
// //                                 <FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
// //                                 Pagar
// //                               </button>
// //                             )}
// //                           </div>
// //                           <div className="text-right">
// //                             <p className="text-gray-700"><span className="font-semibold">Subtotal:</span> <span className="ml-2">${debt.subtotal.toFixed(2)}</span></p>
// //                             <p className="text-gray-700"><span className="font-semibold">IVA:</span> <span className="ml-2">${debt.iva.toFixed(2)}</span></p>
// //                             <p className="text-gray-700"><span className="font-semibold">ICE:</span> <span className="ml-2">${debt.ice.toFixed(2)}</span></p>
// //                             <p className="text-xl font-bold text-[#f5831f] mt-2">Total: ${debt.total.toFixed(2)}</p>
// //                           </div>
// //                         </div>
// //                       </div>
// //                     ))}
// //                 </div>
// //                 {(!clientDebts.cliente_tv.pagos_tv ||
// //                   Object.values(clientDebts.cliente_tv.pagos_tv).length === 0) && (
// //                   <div className="bg-[#fff1f0] p-6 rounded-lg shadow-md">
// //                     <p className="text-gray-700 font-bold">
// //                       <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
// //                       No se encontraron deudas de servicio de TV.
// //                     </p>
// //                   </div>
// //                 )}
// //               </div>
// //             )}
// //             {!clientDebts.cliente_int && !clientDebts.cliente_tv && (
// //               <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 col-span-2">
// //                 <p className="text-gray-700 font-bold">
// //                   <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
// //                   No se encontraron datos de deudas del cliente.
// //                 </p>
// //               </div>
// //             )}
// //           </div>
// //         )}
// //       </div>
// //       <Footer />

// //       {showModal && (
// //         <div className="fixed z-10 inset-0 overflow-y-auto">
// //           <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
// //             <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
// //             <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            







// //               <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${isAnimating ? 'animate-fade-out' : ''}`}>
// //               {isProcessing ? (
// //                 <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
// //                   <div className="sm:flex sm:items-start justify-center">
// //                     <div className="mt-3 text-center sm:mt-0 sm:text-left">
// //                       <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-[#f5831f] mb-4" />
// //                       <h3 className="text-lg leading-6 font-medium text-gray-900">Procesando pago</h3>
// //                       <div className="mt-2">
// //                         <p className="text-sm text-gray-500">Por favor, espere mientras procesamos su pago.</p>
// //                       </div>
// //                     </div>
// //                   </div>
// //                 </div>
// //               ) : isPaymentSuccess ? (
// //                 <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
// //                   <div className="flex flex-col items-center justify-center">
// //                     <div className="flex justify-center items-center" style={{ width: '100px', height: '100px' }}>
// //                       <dotlottie-player 
// //                         src="https://lottie.host/fc2c7f88-d12a-42b8-953c-e8c794436ccc/hNunZ600Yl.json" 
// //                         background="transparent" 
// //                         speed="1" 
// //                         style={{ width: '100px', height: '100px' }} 
// //                         loop 
// //                         autoplay
// //                       ></dotlottie-player>
// //                     </div>
// //                     <div className="mt-3 text-center">
// //                       <h3 className="text-lg leading-6 font-medium text-gray-900">Pago realizado con éxito</h3>
// //                       <div className="mt-2">
// //                         <p className="text-sm text-gray-500">Su pago ha sido procesado correctamente. Gracias por su transacción.</p>
// //                       </div>
// //                     </div>
// //                     <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
// //                       <button
// //                         type="button"
// //                         className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#803f98] text-base font-medium text-white hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:col-start-1 sm:text-sm"
// //                         onClick={handleGenerateInvoice}
// //                       >
// //                         <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
// //                         Ver Factura
// //                       </button>
// //                       <button
// //                         type="button"
// //                         className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:mt-0 sm:col-start-2 sm:text-sm"
// //                         onClick={handleGenerateThermalInvoice}
// //                       >
// //                         <FontAwesomeIcon icon={faPrint} className="mr-2" />
// //                         Ver Factura Térmica
// //                       </button>
// //                     </div>
// //                   </div>
// //                 </div>
// //               ) : (
// //                 <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
// //                   <div className="sm:flex sm:items-start">
// //                     <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
// //                       <div className={`border-t-4 ${selectedDebt && selectedDebt.servicio === 'internet' ? 'border-[#803f98]' : 'border-[#f5831f]'} rounded-t-lg shadow-lg p-4 mb-4`}>
// //                         <div className="flex justify-between items-center mb-4">
// //                           <span className="text-xs text-gray-500">Contrato #{selectedDebt && selectedDebt.suscripcion}</span>
// //                         </div>
// //                         {selectedDebt && selectedDebt.aviso === "CONTRATO SUSPENDIDO" && (
// //                           <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
// //                             <p className="font-bold">Atención: Este es un contrato suspendido.</p>
// //                           </div>
// //                         )}
// //                         {contractPayment && (
// //                           <>
// //                             <div className="mb-4">
// //                               <label htmlFor="months" className="block text-sm font-medium text-gray-700 mb-2">Número de meses:</label>
// //                               <select
// //                                 id="months"
// //                                 value={selectedMonths}
// //                                 onChange={handleMonthChange}
// //                                 className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
// //                               >
// //                                 {[...Array(maxMonths)].map((_, i) => (
// //                                   <option key={i + 1} value={i + 1}>{i + 1}</option>
// //                                 ))}
// //                               </select>
// //                             </div>
// //                             <div className="bg-gray-100 p-4 rounded-md mb-4">
// //                               <h5 className="font-bold mb-2">Detalle de Servicios</h5>
// //                               <table className="w-full text-sm">
// //                                 <thead>
// //                                   <tr className="border-b">
// //                                     <th className="text-left py-2">Descripción</th>
// //                                     <th className="text-right py-2">Valor Unitario</th>
// //                                     <th className="text-right py-2">Valor Total</th>
// //                                   </tr>
// //                                 </thead>
// //                                 <tbody>
// //                                   {contractPayment.pagos_individuales.map((pago, index) => (
// //                                     <tr key={index} className="border-b">
// //                                       <td className="py-2">{pago.descripcion}</td>
// //                                       <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
// //                                       <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
// //                                     </tr>
// //                                   ))}
// //                                 </tbody>
// //                               </table>
// //                             </div>
// //                             <div className="bg-gray-100 p-4 rounded-md">
// //                               <h5 className="font-bold mb-2">Resumen de Pago</h5>
// //                               <div className="flex justify-between">
// //                                 <span>Subtotal:</span>
// //                                 <span>${contractPayment.subtotal.toFixed(2)}</span>
// //                               </div>
// //                               <div className="flex justify-between">
// //                                 <span>IVA:</span>
// //                                 <span>${contractPayment.iva.toFixed(2)}</span>
// //                               </div>
// //                               <div className="flex justify-between">
// //                                 <span>ICE:</span>
// //                                 <span>${contractPayment.ice.toFixed(2)}</span>
// //                               </div>
// //                               <div className="flex justify-between font-bold mt-2 pt-2 border-t">
// //                                 <span>Total:</span>
// //                                 <span>${contractPayment.total.toFixed(2)}</span>
// //                               </div>
// //                             </div>
// //                           </>
// //                         )}
// //                       </div>
// //                     </div>
// //                   </div>
// //                 </div>
// //               )}
// //               <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
// //                 {!isPaymentSuccess && !isProcessing && (
// //                   <>
// //                     <button
// //                       type="button"
// //                       className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#f5831f] text-base font-medium text-white hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#f5831f] sm:ml-3 sm:w-auto sm:text-sm"
// //                       onClick={handleConfirmPayment}
// //                     >
// //                       Confirmar Pago
// //                     </button>
// //                     <button
// //                       type="button"
// //                       className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
// //                       onClick={closeModal}
// //                     >
// //                       <FontAwesomeIcon icon={faTimes} className="mr-2" />
// //                       Cancelar
// //                     </button>
// //                   </>
// //                 )}
// //                 {isPaymentSuccess && (
// //                   <button
// //                     type="button"
// //                     className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#803f98] text-base font-medium text-white hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:ml-3 sm:w-auto sm:text-sm"
// //                     onClick={closeModal}
// //                   >
// //                     Cerrar
// //                   </button>
// //                 )}
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default ClientDebt;





//   import React, { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes, faSpinner, faMoneyBillWave, faPrint, faFileInvoice, faSearch, faExclamationCircle, faInfoCircle, faUser, faMapMarkerAlt, faPhone, faMobileAlt, faWifi, faTv } from '@fortawesome/free-solid-svg-icons';
// import apiService from "../services/apiService";
// import Header from "../components/header";
// import Footer from "../components/footer";
// import moment from 'moment';
// import InvoiceGenerator from './InvoiceGenerator';

// const ClientDebt = () => {
//   const [cedula, setCedula] = useState("");
//   const [clientDebts, setClientDebts] = useState(null);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [error, setError] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [selectedDebt, setSelectedDebt] = useState(null);
//   const [selectedMonths, setSelectedMonths] = useState(1);
//   const [contractPayment, setContractPayment] = useState(null);
//   const [maxMonths, setMaxMonths] = useState(6);
//   const [monthOptions, setMonthOptions] = useState([]);
//   const [displayMonths, setDisplayMonths] = useState(1);
//   const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
//   const [isAnimating, setIsAnimating] = useState(false);
//   const [invoiceNumber, setInvoiceNumber] = useState(null);
//   const [isSearching, setIsSearching] = useState(false);

//   const invoiceGenerator = InvoiceGenerator();

//   const validateInput = (value) => {
//     const regex = /^[0-9]*$/;
//     if (!regex.test(value)) {
//       setError('Solo se permiten números');
//       return false;
//     }
//     setError('');
//     return true;
//   };

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     if (validateInput(value)) {
//       setCedula(value);
//       setClientDebts(null);
//       setError(null);
//     }
//   };

//   const handleSearch = async () => {
//     if (cedula.trim() === '') {
//       setError('Este campo es requerido');
//       return;
//     }
//     setIsSearching(true);
//     setError(null);
//     try {
//       const user = JSON.parse(localStorage.getItem("user"));
//       const branch = user.sucursal;
//       const userId = user.id;

//       if (!branch || !userId) {
//         throw new Error('Información de usuario no disponible');
//       }

//       const response = await apiService.searchClientDebts(branch, cedula, userId);
//       setClientDebts(response.data.pagos);
//     } catch (err) {
//       setError(err.message || 'Error al buscar deudas del cliente');
//       setClientDebts(null);
//     } finally {
//       setIsSearching(false);
//     }
//   };

//   const resetPaymentStates = () => {
//     setShowModal(false);
//     setIsProcessing(false);
//     setIsPaymentSuccess(false);
//     setSelectedDebt(null);
//     setContractPayment(null);
//     setSelectedMonths(1);
//     setDisplayMonths(1);
//     setMaxMonths(6);
//   };

//   const handlePaymentClick = async (debt) => {
//     resetPaymentStates();
//     setSelectedDebt(debt);
    
//     const initialMonths = debt.num_meses;
//     let limitMonths = 12; // Default value
  
//     if (debt.aviso === "CONTRATO SUSPENDIDO") {
//       limitMonths = Math.ceil(debt.num_meses);
//       setMaxMonths(limitMonths);
      
//       // Generate month options for suspended contracts
//       const options = [];
//       for (let i = 1; i <= limitMonths; i++) {
//         if (i < initialMonths) {
//           options.push(i);
//         } else {
//           options.push(Number(initialMonths.toFixed(2)));
//           break;
//         }
//       }
//       setMonthOptions(options);
//       setSelectedMonths(limitMonths);
//       setDisplayMonths(initialMonths);
//     } else {
//       setMaxMonths(12);
//       setMonthOptions([...Array(12)].map((_, i) => i + 1));
//       setSelectedMonths(Math.min(Math.ceil(initialMonths), 12));
//       setDisplayMonths(Math.min(Math.ceil(initialMonths), 12));
//     }
    
//     await fetchContractPayment(debt, Math.ceil(initialMonths));
//     setShowModal(true);
//   };

//   const fetchContractPayment = async (debt, months) => {
//     try {
//       const user = JSON.parse(localStorage.getItem("user"));
//       const branch = user.sucursal;
//       const userId = user.id;

//       const response = await apiService.searchClientContract(
//         branch,
//         debt.servicio,
//         debt.suscripcion,
//         months,
//         userId
//       );

//       if (
//         (response.data.pago_int && Object.keys(response.data.pago_int).length > 0) ||
//         (response.data.pago_tv && Object.keys(response.data.pago_tv).length > 0)
//       ) {
//         const paymentKey = Object.keys(response.data.pago_int || response.data.pago_tv)[0];
//         const payment = response.data.pago_int?.[paymentKey] || response.data.pago_tv?.[paymentKey];
//         setContractPayment(payment);
//       } else {
//         throw new Error("No se encontraron pagos para este contrato");
//       }
//     } catch (err) {
//       setError(err.message);
//       setContractPayment(null);
//     }
//   };

//   const handleMonthChange = async (event) => {
//     const months = parseFloat(event.target.value);
//     setDisplayMonths(months);
//     const roundedMonths = Math.ceil(months);
//     setSelectedMonths(roundedMonths);
//     if (selectedDebt) {
//       await fetchContractPayment(selectedDebt, roundedMonths);
//     }
//   };

//   const handleConfirmPayment = async () => {
//     try {
//       setIsProcessing(true);
//       const user = JSON.parse(localStorage.getItem("user"));
//       const branch = user.sucursal;
//       const userId = user.id;
//       const paymentInfo = contractPayment || selectedDebt;
//       if (!paymentInfo) {
//         throw new Error("No se ha seleccionado ningún pago");
//       }
//       const fecha1 = moment().format('YYYY-MM-DD HH:mm:ss.S');

//       const fact_meses = paymentInfo.pagos_individuales.map(pago => ({
//         meses: pago.meses,
//       }));

//       const facturas_d = paymentInfo.pagos_individuales.map(pago => ({
//         cod_producto: pago.cod_producto,
//         descripcion: pago.descripcion,
//         cantidad: pago.meses,
//         valor_t: pago.valor_total
//       }));

//       let paymentData = {
//         tipo_aviso: 1,
//         tipo_fac: 1,
//         cod_doc: "",
//         estado: 0,
//         anulado: 0,
//         numerodoc: 0,
//         cod_cliente: paymentInfo.cod_cliente,
//         cod_usuario: userId,
//         fecha: fecha1,
//         total: paymentInfo.total,
//         contrato: paymentInfo.suscripcion,
//         sucursal: parseInt(localStorage.getItem('userBranch')),
//         factura_com: {
//           meses: selectedMonths  // Usar selectedMonths (redondeado) para el backend
//         },      
//         facturas_d: facturas_d
//       };
//       const response = await apiService.realizarPago(branch, paymentInfo.servicio, paymentData);
//       if (response.status === "success") {
//         setIsProcessing(false);
//         setIsPaymentSuccess(true);
//         setIsAnimating(true);
//         setInvoiceNumber(response.data.cod_fac);
//       } else {
//         throw new Error(response.message || "Error al procesar el pago");
//       }
//     } catch (err) {
//       console.error('Error al procesar el pago:', err);
//       setError(err.message || 'Error al procesar el pago');
//       setIsProcessing(false);
//     }
//   };

//   const handleGenerateInvoice = () => {
//     if (contractPayment) {
//       invoiceGenerator.generateInvoicePDF({
//         ...contractPayment,
//         nombre_cliente: clientDebts.cliente_int?.nombre || clientDebts.cliente_tv?.nombre,
//         ruc: clientDebts.cliente_int?.ruc || clientDebts.cliente_tv?.ruc,
//         direccion: selectedDebt.direccion,
//         invoiceNumber: invoiceNumber, 
//         telefono: clientDebts.cliente_int?.telefono || clientDebts.cliente_tv?.telefono,
//       });
//     }
//   };

//   const handleGenerateThermalInvoice = () => {
//     if (contractPayment) {
//       invoiceGenerator.generateThermalPrint({
//         ...contractPayment,
//         nombre_cliente: clientDebts.cliente_int?.nombre || clientDebts.cliente_tv?.nombre,
//         ruc: clientDebts.cliente_int?.ruc || clientDebts.cliente_tv?.ruc,
//         direccion: selectedDebt.direccion,
//         telefono: clientDebts.cliente_int?.telefono || clientDebts.cliente_tv?.telefono,
//       });
//     }
//   };

//   const closeModal = () => {
//     resetPaymentStates();
//   };

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
//     script.type = "module";
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     }
//   }, []);

//   return (
//     <div className="flex flex-col min-h-screen bg-[#FBFEF7] font-poppins">
//       <Header />
//       <div className="h-1 bg-[#f5831f]"></div>
//       <div className="flex-grow flex flex-col p-6 md:p-12">
//         <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-6">
//           Búsqueda de deudas del cliente
//         </h1>

//         <div className="p-4 md:p-6 rounded-lg mb-8 max-w-3xl mx-auto">
//           <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
//             <div className="flex-grow w-full md:w-auto">
//               <label htmlFor="cedula" className="text-gray-700 font-bold mb-2 block md:inline-block md:mr-2">
//                 Cédula o RUC:
//               </label>
//               <input
//                 type="text"
//                 id="cedula"
//                 value={cedula}
//                 onChange={handleInputChange}
//                 className="w-full md:w-64 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#803f98]"
//               />
//             </div>
//             <button
//               onClick={handleSearch}
//               disabled={isSearching}
//               className={`bg-[#803f98] text-white font-bold py-2 px-4 rounded-md hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-[#803f98] w-full md:w-auto ${isSearching ? 'opacity-50 cursor-not-allowed' : ''}`}
//             >
//               {isSearching ? (
//                 <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
//               ) : (
//                 <FontAwesomeIcon icon={faSearch} className="mr-2" />
//               )}
//               {isSearching ? 'Buscando...' : 'Buscar'}
//             </button>
//           </div>
//         </div>

//         {error && (
//           <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md mb-8">
//             <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
//             {error}
//           </div>
//         )}

//         {clientDebts && (
//           <div className="space-y-8">
//             {clientDebts.cliente_int && (
//               <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
//                 <h2 className="text-2xl font-bold text-[#803f98] mb-6">
//                   <FontAwesomeIcon icon={faWifi} className="mr-2" />
//                   Servicios de Internet
//                 </h2>
//                 {clientDebts.cliente_int.nombre ? (
//                   <div className="mb-6">
//                     <p className="text-gray-700 font-bold">
//                       <FontAwesomeIcon icon={faUser} className="mr-2" />
//                       Nombre: {clientDebts.cliente_int.nombre}
//                     </p>
//                     <p className="text-gray-700">
//                       <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
//                       Dirección: {clientDebts.cliente_int.direccion}
//                     </p>
//                     <p className="text-gray-700">
//                       <FontAwesomeIcon icon={faPhone} className="mr-2" />
//                       Teléfono: {clientDebts.cliente_int.telefono}
//                     </p>
//                     <p className="text-gray-700">
//                       <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
//                       Celular: {clientDebts.cliente_int.celular}
//                     </p>
//                   </div>
//                 ) : (
//                   <div
//                     className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
//                     role="alert"
//                   >
//                     <p className="font-bold">
//                       No se encontraron datos del cliente de Internet.
//                     </p>
//                   </div>
//                 )}
//                 <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 sm:gap-6">
//                   {Object.values(clientDebts.cliente_int.pagos_internet).map(
//                     (debt, index) => (



//                    <div
//                         key={`internet-${index}`}
//                         className={`bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border-t-4 ${
//                           debt.aviso === "CONTRATO SUSPENDIDO"
//                             ? "border-[#ff4d4f]"
//                             : "border-[#803f98]"
//                         }`}
//                       >
//                         <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 border-b pb-2">
//                           <h3 className="text-lg sm:text-xl font-bold text-[#803f98] mb-2 sm:mb-0">
//                             <FontAwesomeIcon icon={faWifi} className="mr-2" />
//                             Servicio de Internet
//                           </h3>
//                           <span className="text-xs text-gray-500">
//                             Contrato #{debt.suscripcion}
//                           </span>
//                         </div>
//                         <div className="mb-4 grid grid-cols-1 gap-2 bg-purple-100 p-2 rounded-md">
//                           <p className="text-sm sm:text-base text-gray-700 text-left">
//                             <span className="font-semibold">Cliente:</span> {clientDebts.cliente_int.nombre}
//                           </p>
//                           <p className="text-sm sm:text-base text-gray-700 text-left">
//                             <span className="font-semibold">Dirección:</span> {debt.direccion}
//                           </p>
//                           <p className="text-sm sm:text-base text-gray-700 text-left">
//                             <span className="font-semibold">Meses:</span> {debt.num_meses.toFixed(2)}
//                           </p>
//                         </div>
//                         <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 gap-2 bg-gray-100 p-2 rounded-md">
//                           <p className="text-sm sm:text-base text-gray-700 text-left">
//                             <span className="font-semibold">Fecha:</span> {new Date().toLocaleDateString()}
//                           </p>
//                           <p className={`text-sm sm:text-base text-gray-700 text-left sm:text-right ${debt.aviso === 'CONTRATO SUSPENDIDO' ? 'text-red-500' : 'text-green-200/10'}`}>
//                             <span className="font-semibold">Aviso:</span> {debt.aviso}
//                           </p>
//                         </div>

//                         <div className="bg-gray-100 p-3 sm:p-4 rounded-md mb-4 overflow-x-auto">
//                           <h4 className="font-bold text-[#803f98] mb-2 text-sm sm:text-base">
//                             Detalle de servicios
//                           </h4>
//                           <table className="w-full text-xs sm:text-sm">
//                             <thead>
//                               <tr className="border-b">
//                                 <th className="text-left py-2">Descripción</th>
//                                 <th className="text-right py-2">Meses</th>
//                                 <th className="text-right py-2">Valor unitario</th>
//                                 <th className="text-right py-2">Valor total</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {debt.pagos_individuales.map((pago, index) => (
//                                 <tr key={`${pago.serial}-${index}`} className="border-b">
//                                   <td className="py-2 text-left">{pago.descripcion}</td>
//                                   <td className="text-right py-2">{pago.meses.toFixed(2)}</td>
//                                   <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
//                                   <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
//                                 </tr>
//                               ))}
//                             </tbody>
//                           </table>
//                         </div>
//                         <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end">
//                           <div className="mb-4 sm:mb-0">
//                             {debt.pendiente ? (
//                               <p className="text-yellow-600 font-bold">Este es un pago pendiente</p>
//                             ) : (
//                               <button
//                                 className={`bg-[#803f98] text-white font-bold py-2 px-4 rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-[#803f98] text-sm sm:text-base ${
//                                   debt.aviso === "SUSPENDIDO" ? "opacity-50 cursor-not-allowed" : ""
//                                 }`}
//                                 onClick={() => handlePaymentClick(debt)}
//                                 disabled={debt.aviso === "SUSPENDIDO"}
//                               >
//                                 <FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
//                                 Pagar
//                               </button>
//                             )}
//                           </div>
//                           <div className="text-right">
//                             <p className="text-sm sm:text-base text-gray-700">
//                               <span className="font-semibold">Subtotal:</span>{" "}
//                               <span className="ml-2">${debt.subtotal.toFixed(2)}</span>
//                             </p>
//                             <p className="text-sm sm:text-base text-gray-700">
//                               <span className="font-semibold">IVA:</span>{" "}
//                               <span className="ml-2">${debt.iva.toFixed(2)}</span>
//                             </p>
//                             <p className="text-sm sm:text-base text-gray-700">
//                               <span className="font-semibold">ICE:</span>{" "}
//                               <span className="ml-2">${debt.ice.toFixed(2)}</span>
//                             </p>
//                             <p className="text-lg sm:text-xl font-bold text-[#803f98] mt-2">
//                               Total: ${debt.total.toFixed(2)}
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                     )
//                   )}
//                 </div>

//                 {Object.values(clientDebts.cliente_int.pagos_internet).length === 0 && (
//                   <div className="bg-[#e6f7ff] p-6 rounded-lg shadow-md">
//                     <p className="text-gray-700 font-bold">
//                       <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
//                       No se encontraron deudas de servicio de Internet.
//                     </p>
//                   </div>
//                 )}
//               </div>
//             )}

//             {clientDebts.cliente_tv && (
//               <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
//                 <h2 className="text-2xl font-bold text-[#803f98] mb-6">
//                   <FontAwesomeIcon icon={faTv} className="mr-2" />
//                   Servicios de TV
//                 </h2>
//                 {clientDebts.cliente_tv.nombre ? (
//                   <div className="mb-6">
//                     <p className="text-gray-700 font-bold">
//                       <FontAwesomeIcon icon={faUser} className="mr-2" />
//                       Nombre: {clientDebts.cliente_tv.nombre}
//                     </p>
//                     <p className="text-gray-700">
//                       <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
//                       Dirección: {clientDebts.cliente_tv.direccion}
//                     </p>
//                     <p className="text-gray-700">
//                       <FontAwesomeIcon icon={faPhone} className="mr-2" />
//                       Teléfono: {clientDebts.cliente_tv.telefono}
//                     </p>
//                     <p className="text-gray-700">
//                       <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
//                       Celular: {clientDebts.cliente_tv.celular}
//                     </p>
//                   </div>
//                 ) : (
//                   <div
//                     className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
//                     role="alert"
//                   >
//                     <p className="font-bold">
//                       No se encontraron datos del cliente de TV.
//                     </p>
//                   </div>
//                 )}
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//                   {clientDebts.cliente_tv.pagos_tv &&
//                     Object.values(clientDebts.cliente_tv.pagos_tv).map((debt, index) => (
//                       <div
//                         key={`tv-${index}`}
//                         className={`bg-[#ffffff] p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border-t-4 border-[#f5831f] ${
//                           debt.aviso === 'CONTRATO SUSPENDIDO' ? 'bg-[#ff4d4f]' : ''
//                         }`}
//                       > 
//                         <div className="flex justify-between items-center mb-4 border-b pb-2">
//                           <h3 className="text-xl font-bold text-[#f5831f]">
//                             <FontAwesomeIcon icon={faTv} className="mr-2" />
//                             Servicio de TV
//                           </h3> 
//                           <span className="text-xs text-gray-500">Contrato #{debt.suscripcion}</span>
//                         </div>
//                         <div className="mb-4 grid grid-cols-1 gap-3 bg-orange-100 p-2 rounded-md">
//                           <p className="text-gray-700 text-left"><span className="font-semibold">Cliente:</span> {clientDebts.cliente_tv.nombre}</p>
//                           <p className="text-gray-700 text-left"><span className="font-semibold">Dirección:</span> {clientDebts.cliente_tv.direccion}</p>
//                           <p className="text-gray-700 text-left"><span className="font-semibold">Meses:</span> {debt.num_meses.toFixed(2)}</p>
//                         </div>
//                         <div className="mb-4 grid grid-cols-2 gap-3 bg-gray-100 p-2 rounded-md">
//                           <p className="text-gray-700 text-left"><span className="font-semibold">Fecha:</span> {new Date().toLocaleDateString()}</p>
//                           <p className={`text-sm sm:text-base text-gray-700 text-left sm:text-right ${debt.aviso === 'CONTRATO SUSPENDIDO' ? 'text-red-500' : 'text-gray-100'}`}>
//                             <span className="font-semibold">Aviso:</span> {debt.aviso}
//                           </p>
//                         </div>
//                         <div className="bg-gray-100 p-4 rounded-md mb-4">
//                           <h4 className="font-bold text-[#f5831f] mb-2">Detalle de servicios</h4>
//                           <table className="w-full">
//                             <thead>
//                               <tr className="border-b">
//                                 <th className="text-left py-2">Descripción</th>
//                                 <th className="text-right py-2">Meses</th>
//                                 <th className="text-right py-2">Valor unitario</th>
//                                 <th className="text-right py-2">Valor total</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {debt.pagos_individuales.map((pago, index) => (
//                                 <tr key={`${pago.serial}-${index}`} className="border-b">
//                                   <td className="py-2 text-left">{pago.descripcion}</td>
//                                   <td className="text-right py-2">{pago.meses.toFixed(2)}</td>
//                                   <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
//                                   <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
//                                 </tr>   
//                               ))}
//                             </tbody>
//                           </table>
//                         </div>
//                         <div className="flex justify-between items-end">
//                           <div>
//                             {debt.pendiente ? (
//                               <p className="text-yellow-600 font-bold">Este es un pago pendiente</p>
//                             ) : (
//                               <button
//                                 className={`bg-[#f5831f] text-white font-bold py-2 px-4 rounded-md hover:bg-[#ff7875] focus:outline-none focus:ring-2 focus:ring-[#ff4d4f] ${
//                                   debt.aviso === 'SUSPENDIDO' ? 'opacity-50 cursor-not-allowed' : ''
//                                 }`}
//                                 onClick={() => handlePaymentClick(debt)}
//                                 disabled={debt.aviso === 'SUSPENDIDO'}
//                               >
//                                 <FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
//                                 Pagar
//                               </button>
//                             )}
//                           </div>
//                           <div className="text-right">
//                             <p className="text-gray-700"><span className="font-semibold">Subtotal:</span> <span className="ml-2">${debt.subtotal.toFixed(2)}</span></p>
//                             <p className="text-gray-700"><span className="font-semibold">IVA:</span> <span className="ml-2">${debt.iva.toFixed(2)}</span></p>
//                             <p className="text-gray-700"><span className="font-semibold">ICE:</span> <span className="ml-2">${debt.ice.toFixed(2)}</span></p>
//                             <p className="text-xl font-bold text-[#f5831f] mt-2">Total: ${debt.total.toFixed(2)}</p>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                 </div>
//                 {(!clientDebts.cliente_tv.pagos_tv ||
//                   Object.values(clientDebts.cliente_tv.pagos_tv).length === 0) && (
//                   <div className="bg-[#fff1f0] p-6 rounded-lg shadow-md">
//                     <p className="text-gray-700 font-bold">
//                       <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
//                       No se encontraron deudas de servicio de TV.
//                     </p>
//                   </div>
//                 )}
//               </div>
//             )}
         









//               {!clientDebts.cliente_int && !clientDebts.cliente_tv && (
//                 <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 col-span-2">
//                   <p className="text-gray-700 font-bold">
//                     <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
//                     No se encontraron datos de deudas del cliente.
//                   </p>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//         <Footer />
  
//         {showModal && (
//           <div className="fixed z-10 inset-0 overflow-y-auto">
//             <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//               <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
//               <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              
//               <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${isAnimating ? 'animate-fade-out' : ''}`}>
//                 {isProcessing ? (
//                   <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                     <div className="sm:flex sm:items-start justify-center">
//                       <div className="mt-3 text-center sm:mt-0 sm:text-left">
//                         <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-[#f5831f] mb-4" />
//                         <h3 className="text-lg leading-6 font-medium text-gray-900">Procesando pago</h3>
//                         <div className="mt-2">
//                           <p className="text-sm text-gray-500">Por favor, espere mientras procesamos su pago.</p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ) : isPaymentSuccess ? (
//                   <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                     <div className="flex flex-col items-center justify-center">
//                       <div className="flex justify-center items-center" style={{ width: '100px', height: '100px' }}>
//                         <dotlottie-player 
//                           src="https://lottie.host/fc2c7f88-d12a-42b8-953c-e8c794436ccc/hNunZ600Yl.json" 
//                           background="transparent" 
//                           speed="1" 
//                           style={{ width: '100px', height: '100px' }} 
//                           loop 
//                           autoplay
//                         ></dotlottie-player>
//                       </div>
//                       <div className="mt-3 text-center">
//                         <h3 className="text-lg leading-6 font-medium text-gray-900">Pago realizado con éxito</h3>
//                         <div className="mt-2">
//                           <p className="text-sm text-gray-500">Su pago ha sido procesado correctamente. Gracias por su transacción.</p>
//                         </div>
//                       </div>
//                       <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
//                         <button
//                           type="button"
//                           className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#803f98] text-base font-medium text-white hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:col-start-1 sm:text-sm"
//                           onClick={handleGenerateInvoice}
//                         >
//                           <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
//                           Ver Factura
//                         </button>
//                         <button
//                           type="button"
//                           className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:mt-0 sm:col-start-2 sm:text-sm"
//                           onClick={handleGenerateThermalInvoice}
//                         >
//                           <FontAwesomeIcon icon={faPrint} className="mr-2" />
//                           Ver Factura Térmica
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                     <div className="sm:flex sm:items-start">
//                       <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
//                         <div className={`border-t-4 ${selectedDebt && selectedDebt.servicio === 'internet' ? 'border-[#803f98]' : 'border-[#f5831f]'} rounded-t-lg shadow-lg p-4 mb-4`}>
//                           <div className="flex justify-between items-center mb-4">
//                             <span className="text-xs text-gray-500">Contrato #{selectedDebt && selectedDebt.suscripcion}</span>
//                           </div>
//                           {selectedDebt && selectedDebt.aviso === "CONTRATO SUSPENDIDO" && (
//                             <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
//                               <p className="font-bold">Atención: Este es un contrato suspendido.</p>
//                             </div>
//                           )}
//                           {contractPayment && (
//                             <>
//                               <div className="mb-4">
//                                 <label htmlFor="months" className="block text-sm font-medium text-gray-700 mb-2">Número de meses:</label>
//                                 <select
//                                   id="months"
//                                   value={displayMonths}
//                                   onChange={handleMonthChange}
//                                   className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
//                                 >
//                                   {monthOptions.map((month) => (
//                                     <option key={month} value={month}>{month}</option>
//                                   ))}
//                                 </select>
//                               </div>
//                               <div className="bg-gray-100 p-4 rounded-md mb-4">
//                                 <h5 className="font-bold mb-2">Detalle de Servicios</h5>
//                                 <table className="w-full text-sm">
//                                   <thead>
//                                     <tr className="border-b">
//                                       <th className="text-left py-2">Descripción</th>
//                                       <th className="text-right py-2">Valor Unitario</th>
//                                       <th className="text-right py-2">Valor Total</th>
//                                     </tr>
//                                   </thead>
//                                   <tbody>
//                                     {contractPayment.pagos_individuales.map((pago, index) => (
//                                       <tr key={index} className="border-b">
//                                         <td className="py-2">{pago.descripcion}</td>
//                                         <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
//                                         <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
//                                       </tr>
//                                     ))}
//                                   </tbody>
//                                 </table>
//                               </div>
//                               <div className="bg-gray-100 p-4 rounded-md">
//                                 <h5 className="font-bold mb-2">Resumen de Pago</h5>
//                                 <div className="flex justify-between">
//                                   <span>Subtotal:</span>
//                                   <span>${contractPayment.subtotal.toFixed(2)}</span>
//                                 </div>
//                                 <div className="flex justify-between">
//                                   <span>IVA:</span>
//                                   <span>${contractPayment.iva.toFixed(2)}</span>
//                                 </div>
//                                 <div className="flex justify-between">
//                                   <span>ICE:</span>
//                                   <span>${contractPayment.ice.toFixed(2)}</span>
//                                 </div>
//                                 <div className="flex justify-between font-bold mt-2 pt-2 border-t">
//                                   <span>Total:</span>
//                                   <span>${contractPayment.total.toFixed(2)}</span>
//                                 </div>
//                               </div>
//                             </>
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//                 <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
//                   {!isPaymentSuccess && !isProcessing && (
//                     <>
//                       <button
//                         type="button"
//                         className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#f5831f] text-base font-medium text-white hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#f5831f] sm:ml-3 sm:w-auto sm:text-sm"
//                         onClick={handleConfirmPayment}
//                       >
//                         Confirmar Pago
//                       </button>
//                       <button
//                         type="button"
//                         className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//                         onClick={closeModal}
//                       >
//                         <FontAwesomeIcon icon={faTimes} className="mr-2" />
//                         Cancelar
//                       </button>
//                     </>
//                   )}
//                   {isPaymentSuccess && (
//                     <button
//                       type="button"
//                       className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#803f98] text-base font-medium text-white hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:ml-3 sm:w-auto sm:text-sm"
//                       onClick={closeModal}
//                     >
//                       Cerrar
//                     </button>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     );
//   };
  
//   export default ClientDebt;





import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner, faMoneyBillWave, faPrint, faFileInvoice, faSearch, faExclamationCircle, faInfoCircle, faUser, faMapMarkerAlt, faPhone, faMobileAlt, faWifi, faTv } from '@fortawesome/free-solid-svg-icons';
import apiService from "../services/apiService";
import Header from "../components/header";
import Footer from "../components/footer";
import moment from 'moment';
import InvoiceGenerator from './InvoiceGenerator';

const ClientDebt = () => {
  const [cedula, setCedula] = useState("");
  const [clientDebts, setClientDebts] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedDebt, setSelectedDebt] = useState(null);
  const [selectedMonths, setSelectedMonths] = useState(1);
  const [contractPayment, setContractPayment] = useState(null);
  const [maxMonths, setMaxMonths] = useState(6);
  const [monthOptions, setMonthOptions] = useState([]);
  const [displayMonths, setDisplayMonths] = useState(1);
  const [exactMonths, setExactMonths] = useState(1);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const invoiceGenerator = InvoiceGenerator();

  const validateInput = (value) => {
    const regex = /^[0-9]*$/;
    if (!regex.test(value)) {
      setError('Solo se permiten números');
      return false;
    }
    setError('');
    return true;
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (validateInput(value)) {
      setCedula(value);
      setClientDebts(null);
      setError(null);
    }
  };

  const handleSearch = async () => {
    if (cedula.trim() === '') {
      setError('Este campo es requerido');
      return;
    }
    setIsSearching(true);
    setError(null);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const branch = user.sucursal;
      const userId = user.id;

      if (!branch || !userId) {
        throw new Error('Información de usuario no disponible');
      }

      const response = await apiService.searchClientDebts(branch, cedula, userId);
      setClientDebts(response.data.pagos);
    } catch (err) {
      setError(err.message || 'Error al buscar deudas del cliente');
      setClientDebts(null);
    } finally {
      setIsSearching(false);
    }
  };

  const resetPaymentStates = () => {
    setShowModal(false);
    setIsProcessing(false);
    setIsPaymentSuccess(false);
    setSelectedDebt(null);
    setContractPayment(null);
    setSelectedMonths(1);
    setDisplayMonths(1);
    setExactMonths(1);
    setMaxMonths(6);
  };

  const handlePaymentClick = async (debt) => {
    resetPaymentStates();
    setSelectedDebt(debt);
    
    const initialMonths = debt.num_meses;
    let limitMonths = 12; // Default value
  
    if (debt.aviso === "CONTRATO SUSPENDIDO") {
      limitMonths = Math.ceil(debt.num_meses);
      setMaxMonths(limitMonths);
      
      // Generate month options for suspended contracts
      const options = [];
      for (let i = 1; i <= limitMonths; i++) {
        if (i < initialMonths) {
          options.push(i);
        } else {
          options.push(Number(initialMonths.toFixed(2)));
          break;
        }
      }
      setMonthOptions(options);
      setSelectedMonths(limitMonths);
      setDisplayMonths(initialMonths);
      setExactMonths(initialMonths);
    } else {
      setMaxMonths(12);
      setMonthOptions([...Array(12)].map((_, i) => i + 1));
      const roundedMonths = Math.min(Math.ceil(initialMonths), 12);
      setSelectedMonths(roundedMonths);
      setDisplayMonths(roundedMonths);
      setExactMonths(roundedMonths);
    }
    
    await fetchContractPayment(debt, Math.ceil(initialMonths));
    setShowModal(true);
  };

  const fetchContractPayment = async (debt, months) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const branch = user.sucursal;
      const userId = user.id;

      const response = await apiService.searchClientContract(
        branch,
        debt.servicio,
        debt.suscripcion,
        months,
        userId
      );

      if (
        (response.data.pago_int && Object.keys(response.data.pago_int).length > 0) ||
        (response.data.pago_tv && Object.keys(response.data.pago_tv).length > 0)
      ) {
        const paymentKey = Object.keys(response.data.pago_int || response.data.pago_tv)[0];
        const payment = response.data.pago_int?.[paymentKey] || response.data.pago_tv?.[paymentKey];
        setContractPayment(payment);
      } else {
        throw new Error("No se encontraron pagos para este contrato");
      }
    } catch (err) {
      setError(err.message);
      setContractPayment(null);
    }
  };

  const handleMonthChange = async (event) => {
    const months = parseFloat(event.target.value);
    setDisplayMonths(months);
    
    if (selectedDebt && selectedDebt.aviso === "CONTRATO SUSPENDIDO") {
      setExactMonths(months);
      const roundedMonths = Math.ceil(months);
      setSelectedMonths(roundedMonths);
      await fetchContractPayment(selectedDebt, roundedMonths);
    } else {
      setSelectedMonths(months);
      setExactMonths(months);
      await fetchContractPayment(selectedDebt, months);
    }
  };

  const handleConfirmPayment = async () => {
    try {
      setIsProcessing(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const branch = user.sucursal;
      const userId = user.id;
      const paymentInfo = contractPayment || selectedDebt;
      if (!paymentInfo) {
        throw new Error("No se ha seleccionado ningún pago");
      }
      const fecha1 = moment().format('YYYY-MM-DD HH:mm:ss.S');

      const facturas_d = paymentInfo.pagos_individuales.map(pago => ({
        cod_producto: pago.cod_producto,
        descripcion: pago.descripcion,
        cantidad: pago.meses,
        valor_t: pago.valor_total
      }));

      let paymentData = {
        tipo_aviso: 1,
        tipo_fac: 1,
        cod_doc: "",
        estado: 0,
        anulado: 0,
        numerodoc: 0,
        cod_cliente: paymentInfo.cod_cliente,
        cod_usuario: userId,
        fecha: fecha1,
        total: paymentInfo.total,
        contrato: paymentInfo.suscripcion,
        sucursal: parseInt(localStorage.getItem('userBranch')),
        factura_com: {
          meses: selectedDebt.aviso === "CONTRATO SUSPENDIDO" ? exactMonths : selectedMonths
        },      
        facturas_d: facturas_d
      };
      const response = await apiService.realizarPago(branch, paymentInfo.servicio, paymentData);
      if (response.status === "success") {
        setIsProcessing(false);
        setIsPaymentSuccess(true);
        setIsAnimating(true);
        setInvoiceNumber(response.data.cod_fac);
      } else {
        throw new Error(response.message || "Error al procesar el pago");
      }
    } catch (err) {
      console.error('Error al procesar el pago:', err);
      setError(err.message || 'Error al procesar el pago');
      setIsProcessing(false);
    }
  };

  const handleGenerateInvoice = () => {
    if (contractPayment) {
      invoiceGenerator.generateInvoicePDF({
        ...contractPayment,
        nombre_cliente: clientDebts.cliente_int?.nombre || clientDebts.cliente_tv?.nombre,
        ruc: clientDebts.cliente_int?.ruc || clientDebts.cliente_tv?.ruc,
        direccion: selectedDebt.direccion,
        invoiceNumber: invoiceNumber, 
        telefono: clientDebts.cliente_int?.telefono || clientDebts.cliente_tv?.telefono,
      });
    }
  };

  const handleGenerateThermalInvoice = () => {
    if (contractPayment) {
      invoiceGenerator.generateThermalPrint({
        ...contractPayment,
        nombre_cliente: clientDebts.cliente_int?.nombre || clientDebts.cliente_tv?.nombre,
        ruc: clientDebts.cliente_int?.ruc || clientDebts.cliente_tv?.ruc,
        direccion: selectedDebt.direccion,
        telefono: clientDebts.cliente_int?.telefono || clientDebts.cliente_tv?.telefono,
      });
    }
  };

  const closeModal = () => {
    resetPaymentStates();
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
    script.type = "module";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-[#FBFEF7] font-poppins">
      <Header />
      <div className="h-1 bg-[#f5831f]"></div>
      <div className="flex-grow flex flex-col p-6 md:p-12">
        <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-6">
          Búsqueda de deudas del cliente
        </h1>

        <div className="p-4 md:p-6 rounded-lg mb-8 max-w-3xl mx-auto">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex-grow w-full md:w-auto">
              <label htmlFor="cedula" className="text-gray-700 font-bold mb-2 block md:inline-block md:mr-2">
                Cédula o RUC:
              </label>
              <input
                type="text"
                id="cedula"
                value={cedula}
                onChange={handleInputChange}
                className="w-full md:w-64 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#803f98]"
              />
            </div>
            <button
              onClick={handleSearch}
              disabled={isSearching}
              className={`bg-[#803f98] text-white font-bold py-2 px-4 rounded-md hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-[#803f98] w-full md:w-auto ${isSearching ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isSearching ? (
                <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
              ) : (
                <FontAwesomeIcon icon={faSearch} className="mr-2" />
              )}
              {isSearching ? 'Buscando...' : 'Buscar'}
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md mb-8">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
            {error}
          </div>
        )}

        {clientDebts && (
          <div className="space-y-8">
            {clientDebts.cliente_int && (
              <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                <h2 className="text-2xl font-bold text-[#803f98] mb-6">
                  <FontAwesomeIcon icon={faWifi} className="mr-2" />
                  Servicios de Internet
                </h2>
                {clientDebts.cliente_int.nombre ? (
                  <div className="mb-6">
                    <p className="text-gray-700 font-bold">
                      <FontAwesomeIcon icon={faUser} className="mr-2" />
                      Nombre: {clientDebts.cliente_int.nombre}
                    </p>
                    <p className="text-gray-700">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                      Dirección: {clientDebts.cliente_int.direccion}
                    </p>
                    <p className="text-gray-700">
                      <FontAwesomeIcon icon={faPhone} className="mr-2" />
                      Teléfono: {clientDebts.cliente_int.telefono}
                    </p>
                    <p className="text-gray-700">
                      <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
                      Celular: {clientDebts.cliente_int.celular}
                    </p>
                  </div>
                ) : (
                  <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
                    <p className="font-bold">No se encontraron datos del cliente de Internet.</p>
                  </div>
                )}
             










                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 sm:gap-6">
                {Object.values(clientDebts.cliente_int.pagos_internet).map(
                  (debt, index) => (
                    <div
                      key={`internet-${index}`}
                      className={`bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border-t-4 ${
                        debt.aviso === "CONTRATO SUSPENDIDO"
                          ? "border-[#ff4d4f]"
                          : "border-[#803f98]"
                      }`}
                    >
                      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 border-b pb-2">
                        <h3 className="text-lg sm:text-xl font-bold text-[#803f98] mb-2 sm:mb-0">
                          <FontAwesomeIcon icon={faWifi} className="mr-2" />
                          Servicio de Internet
                        </h3>
                        <span className="text-xs text-gray-500">
                          Contrato #{debt.suscripcion}
                        </span>
                      </div>
                      <div className="mb-4 grid grid-cols-1 gap-2 bg-purple-100 p-2 rounded-md">
                        <p className="text-sm sm:text-base text-gray-700 text-left">
                          <span className="font-semibold">Cliente:</span> {clientDebts.cliente_int.nombre}
                        </p>
                        <p className="text-sm sm:text-base text-gray-700 text-left">
                          <span className="font-semibold">Dirección:</span> {debt.direccion}
                        </p>
                        <p className="text-sm sm:text-base text-gray-700 text-left">
                          <span className="font-semibold">Meses:</span> {debt.num_meses.toFixed(2)}
                        </p>
                      </div>
                      <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 gap-2 bg-gray-100 p-2 rounded-md">
                        <p className="text-sm sm:text-base text-gray-700 text-left">
                          <span className="font-semibold">Fecha:</span> {new Date().toLocaleDateString()}
                        </p>
                        <p className={`text-sm sm:text-base text-gray-700 text-left sm:text-right ${debt.aviso === 'CONTRATO SUSPENDIDO' ? 'text-red-500' : 'text-green-200/10'}`}>
                          <span className="font-semibold">Aviso:</span> {debt.aviso}
                        </p>
                      </div>
                      <div className="bg-gray-100 p-3 sm:p-4 rounded-md mb-4 overflow-x-auto">
                        <h4 className="font-bold text-[#803f98] mb-2 text-sm sm:text-base">
                          Detalle de servicios
                        </h4>
                        <table className="w-full text-xs sm:text-sm">
                          <thead>
                            <tr className="border-b">
                              <th className="text-left py-2">Descripción</th>
                              <th className="text-right py-2">Meses</th>
                              <th className="text-right py-2">Valor unitario</th>
                              <th className="text-right py-2">Valor total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {debt.pagos_individuales.map((pago, index) => (
                              <tr key={`${pago.serial}-${index}`} className="border-b">
                                <td className="py-2 text-left">{pago.descripcion}</td>
                                <td className="text-right py-2">{pago.meses.toFixed(2)}</td>
                                <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
                                <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end">
                        <div className="mb-4 sm:mb-0">
                          {debt.pendiente ? (
                            <p className="text-yellow-600 font-bold">Este es un pago pendiente</p>
                          ) : (
                            <button
                              className={`bg-[#803f98] text-white font-bold py-2 px-4 rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-[#803f98] text-sm sm:text-base ${
                                debt.aviso === "SUSPENDIDO" ? "opacity-50 cursor-not-allowed" : ""
                              }`}
                              onClick={() => handlePaymentClick(debt)}
                              disabled={debt.aviso === "SUSPENDIDO"}
                            >
                              <FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
                              Pagar
                            </button>
                          )}
                        </div>
                        <div className="text-right">
                          <p className="text-sm sm:text-base text-gray-700">
                            <span className="font-semibold">Subtotal:</span>{" "}
                            <span className="ml-2">${debt.subtotal.toFixed(2)}</span>
                          </p>
                          <p className="text-sm sm:text-base text-gray-700">
                            <span className="font-semibold">IVA:</span>{" "}
                            <span className="ml-2">${debt.iva.toFixed(2)}</span>
                          </p>
                          <p className="text-sm sm:text-base text-gray-700">
                            <span className="font-semibold">ICE:</span>{" "}
                            <span className="ml-2">${debt.ice.toFixed(2)}</span>
                          </p>
                          <p className="text-lg sm:text-xl font-bold text-[#803f98] mt-2">
                            Total: ${debt.total.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              {Object.values(clientDebts.cliente_int.pagos_internet).length === 0 && (
                <div className="bg-[#e6f7ff] p-6 rounded-lg shadow-md">
                  <p className="text-gray-700 font-bold">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                    No se encontraron deudas de servicio de Internet.
                  </p>
                </div>
              )}
            </div>
          )}

          {clientDebts.cliente_tv && (
            <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h2 className="text-2xl font-bold text-[#803f98] mb-6">
                <FontAwesomeIcon icon={faTv} className="mr-2" />
                Servicios de TV
              </h2>
              {clientDebts.cliente_tv.nombre ? (
                <div className="mb-6">
                  <p className="text-gray-700 font-bold">
                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                    Nombre: {clientDebts.cliente_tv.nombre}
                  </p>
                  <p className="text-gray-700">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                    Dirección: {clientDebts.cliente_tv.direccion}
                  </p>
                  <p className="text-gray-700">
                    <FontAwesomeIcon icon={faPhone} className="mr-2" />
                    Teléfono: {clientDebts.cliente_tv.telefono}
                  </p>
                  <p className="text-gray-700">
                    <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
                    Celular: {clientDebts.cliente_tv.celular}
                  </p>
                </div>
              ) : (
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
                  <p className="font-bold">No se encontraron datos del cliente de TV.</p>
                </div>
              )}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {clientDebts.cliente_tv.pagos_tv &&
                  Object.values(clientDebts.cliente_tv.pagos_tv).map((debt, index) => (
                    <div
                      key={`tv-${index}`}
                      className={`bg-[#ffffff] p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border-t-4 border-[#f5831f] ${
                        debt.aviso === 'CONTRATO SUSPENDIDO' ? 'bg-[#ff4d4f]' : ''
                      }`}
                    > 
                      <div className="flex justify-between items-center mb-4 border-b pb-2">
                        <h3 className="text-xl font-bold text-[#f5831f]">
                          <FontAwesomeIcon icon={faTv} className="mr-2" />
                          Servicio de TV
                        </h3> 
                        <span className="text-xs text-gray-500">Contrato #{debt.suscripcion}</span>
                      </div>
                      <div className="mb-4 grid grid-cols-1 gap-3 bg-orange-100 p-2 rounded-md">
                        <p className="text-gray-700 text-left"><span className="font-semibold">Cliente:</span> {clientDebts.cliente_tv.nombre}</p>
                        <p className="text-gray-700 text-left"><span className="font-semibold">Dirección:</span> {clientDebts.cliente_tv.direccion}</p>
                        <p className="text-gray-700 text-left"><span className="font-semibold">Meses:</span> {debt.num_meses.toFixed(2)}</p>
                      </div>
                      <div className="mb-4 grid grid-cols-2 gap-3 bg-gray-100 p-2 rounded-md">
                        <p className="text-gray-700 text-left"><span className="font-semibold">Fecha:</span> {new Date().toLocaleDateString()}</p>
                        <p className={`text-sm sm:text-base text-gray-700 text-left sm:text-right ${debt.aviso === 'CONTRATO SUSPENDIDO' ? 'text-red-500' : 'text-gray-100'}`}>
                          <span className="font-semibold">Aviso:</span> {debt.aviso}
                        </p>
                      </div>
                      <div className="bg-gray-100 p-4 rounded-md mb-4">
                        <h4 className="font-bold text-[#f5831f] mb-2">Detalle de servicios</h4>
                        <table className="w-full">
                          <thead>
                            <tr className="border-b">
                              <th className="text-left py-2">Descripción</th>
                              <th className="text-right py-2">Meses</th>
                              <th className="text-right py-2">Valor unitario</th>
                              <th className="text-right py-2">Valor total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {debt.pagos_individuales.map((pago, index) => (
                              <tr key={`${pago.serial}-${index}`} className="border-b">
                                <td className="py-2 text-left">{pago.descripcion}</td>
                                <td className="text-right py-2">{pago.meses.toFixed(2)}</td>
                                <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
                                <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
                              </tr>   
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex justify-between items-end">
                        <div>
                          {debt.pendiente ? (
                            <p className="text-yellow-600 font-bold">Este es un pago pendiente</p>
                          ) : (
                            <button
                              className={`bg-[#f5831f] text-white font-bold py-2 px-4 rounded-md hover:bg-[#ff7875] focus:outline-none focus:ring-2 focus:ring-[#ff4d4f] ${
                                debt.aviso === 'SUSPENDIDO' ? 'opacity-50 cursor-not-allowed' : ''
                              }`}
                              onClick={() => handlePaymentClick(debt)}
                              disabled={debt.aviso === 'SUSPENDIDO'}
                            >
                              <FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
                              Pagar
                            </button>
                          )}
                        </div>
                        <div className="text-right">
                          <p className="text-gray-700"><span className="font-semibold">Subtotal:</span> <span className="ml-2">${debt.subtotal.toFixed(2)}</span></p>
                          <p className="text-gray-700"><span className="font-semibold">IVA:</span> <span className="ml-2">${debt.iva.toFixed(2)}</span></p>
                          <p className="text-gray-700"><span className="font-semibold">ICE:</span> <span className="ml-2">${debt.ice.toFixed(2)}</span></p>
                          <p className="text-xl font-bold text-[#f5831f] mt-2">Total: ${debt.total.toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {(!clientDebts.cliente_tv.pagos_tv ||
                Object.values(clientDebts.cliente_tv.pagos_tv).length === 0) && (
                <div className="bg-[#fff1f0] p-6 rounded-lg shadow-md">
                  <p className="text-gray-700 font-bold">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />














                    No se encontraron deudas de servicio de TV.
                    </p>
                  </div>
                )}
              </div>
            )}
            {!clientDebts.cliente_int && !clientDebts.cliente_tv && (
              <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 col-span-2">
                <p className="text-gray-700 font-bold">
                  <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                  No se encontraron datos de deudas del cliente.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />

      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${isAnimating ? 'animate-fade-out' : ''}`}>
              {isProcessing ? (
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start justify-center">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-[#f5831f] mb-4" />
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Procesando pago</h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">Por favor, espere mientras procesamos su pago.</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : isPaymentSuccess ? (
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="flex flex-col items-center justify-center">
                    <div className="flex justify-center items-center" style={{ width: '100px', height: '100px' }}>
                      <dotlottie-player 
                        src="https://lottie.host/fc2c7f88-d12a-42b8-953c-e8c794436ccc/hNunZ600Yl.json" 
                        background="transparent" 
                        speed="1" 
                        style={{ width: '100px', height: '100px' }} 
                        loop 
                        autoplay
                      ></dotlottie-player>
                    </div>
                    <div className="mt-3 text-center">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Pago realizado con éxito</h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">Su pago ha sido procesado correctamente. Gracias por su transacción.</p>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#803f98] text-base font-medium text-white hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:col-start-1 sm:text-sm"
                        onClick={handleGenerateInvoice}
                      >
                        <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
                        Ver Factura
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:mt-0 sm:col-start-2 sm:text-sm"
                        onClick={handleGenerateThermalInvoice}
                      >
                        <FontAwesomeIcon icon={faPrint} className="mr-2" />
                        Ver Factura Térmica
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <div className={`border-t-4 ${selectedDebt && selectedDebt.servicio === 'internet' ? 'border-[#803f98]' : 'border-[#f5831f]'} rounded-t-lg shadow-lg p-4 mb-4`}>
                        <div className="flex justify-between items-center mb-4">
                          <span className="text-xs text-gray-500">Contrato #{selectedDebt && selectedDebt.suscripcion}</span>
                        </div>
                        {selectedDebt && selectedDebt.aviso === "CONTRATO SUSPENDIDO" && (
                          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                            <p className="font-bold">Atención: Este es un contrato suspendido.</p>
                          </div>
                        )}
                        {contractPayment && (
                          <>
                            <div className="mb-4">
                              <label htmlFor="months" className="block text-sm font-medium text-gray-700 mb-2">Número de meses:</label>
                              <select
                                id="months"
                                value={displayMonths}
                                onChange={handleMonthChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              >
                                {monthOptions.map((month) => (
                                  <option key={month} value={month}>{month}</option>
                                ))}
                              </select>
                            </div>
                            <div className="bg-gray-100 p-4 rounded-md mb-4">
                              <h5 className="font-bold mb-2">Detalle de Servicios</h5>
                              <table className="w-full text-sm">
                                <thead>
                                  <tr className="border-b">
                                    <th className="text-left py-2">Descripción</th>
                                    <th className="text-right py-2">Valor Unitario</th>
                                    <th className="text-right py-2">Valor Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {contractPayment.pagos_individuales.map((pago, index) => (
                                    <tr key={index} className="border-b">
                                      <td className="py-2">{pago.descripcion}</td>
                                      <td className="text-right py-2">${pago.valor_unitario.toFixed(2)}</td>
                                      <td className="text-right py-2">${pago.valor_total.toFixed(2)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div className="bg-gray-100 p-4 rounded-md">
                              <h5 className="font-bold mb-2">Resumen de Pago</h5>
                              <div className="flex justify-between">
                                <span>Subtotal:</span>
                                <span>${contractPayment.subtotal.toFixed(2)}</span>
                              </div>
                              <div className="flex justify-between">
                                <span>IVA:</span>
                                <span>${contractPayment.iva.toFixed(2)}</span>
                              </div>
                              <div className="flex justify-between">
                                <span>ICE:</span>
                                <span>${contractPayment.ice.toFixed(2)}</span>
                              </div>
                              <div className="flex justify-between font-bold mt-2 pt-2 border-t">
                                <span>Total:</span>
                                <span>${contractPayment.total.toFixed(2)}</span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {!isPaymentSuccess && !isProcessing && (
                  <>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#f5831f] text-base font-medium text-white hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#f5831f] sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleConfirmPayment}
                    >
                      Confirmar Pago
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={closeModal}
                    >
                      <FontAwesomeIcon icon={faTimes} className="mr-2" />
                      Cancelar
                    </button>
                  </>
                )}
                {isPaymentSuccess && (
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#803f98] text-base font-medium text-white hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#803f98] sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                  >
                    Cerrar
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDebt;