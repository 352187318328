

// // import axios from 'axios';
// // import sucursalesData from './sucursales.json';

// // const apiService = {
// //   getSucursales: async () => {
// //     //console.log('Obteniendo sucursales...');
// //     return sucursalesData;
// //   },

// //   login: async (branch, usuario, contrasenia) => {
// //     //console.log('Iniciando login para sucursal:', branch);
// //     try {
// //       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
// //       if (!sucursal) {
// //         console.error('Sucursal no encontrada:', branch);
// //         throw new Error('Sucursal no encontrada');
// //       }
// //       // const url = `http://${sucursal.ip}:50101/cbcobros/auth/login`;
// //       const url = `http://${sucursal.ip}:50100/cbcobros/auth/login`;
// //       //console.log('URL de login:', url);
// //       const response = await axios.post(url, {
// //         usuario, 
// //         contrasenia,
// //       }, {
// //         headers: {
// //           'Content-Type': 'application/json'
// //         }
// //       });
// //       //console.log('Respuesta de login:', response.data);
// //       return response.data;
// //     } catch (error) {
// //       console.error('Login failed:', error);
// //       throw error;
// //     }
// //   },

// //   searchClientDebts: async (branch, cedula, userId) => {
// //     //console.log('Iniciando búsqueda de deudas del cliente. Sucursal:', branch, 'Cédula:', cedula, 'Usuario ID:', userId);
// //     try {
// //       if (!apiService.validateEcuadorianId(cedula)) {
// //         console.error('Cédula o RUC inválido:', cedula);
// //         throw new Error('La cédula o RUC ingresado no es válido');
// //       }

// //       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
// //       if (!sucursal) {
// //         console.error('Sucursal no encontrada:', branch);
// //         throw new Error('Sucursal no encontrada');
// //       }

// //       const url = `http://${sucursal.ip}:50101/cbcobros/cobro/pagos-pendientes?cedula=${cedula}&usuario=${userId}`;
// //       //console.log('URL de búsqueda:', url);
      
// //       const token = localStorage.getItem('token');
// //       //console.log('Token utilizado:', token);

// //       const response = await axios.get(url, {
// //         headers: {
// //           'Content-Type': 'application/json',
// //           'Authorization': `Bearer ${token}`
// //         }
// //       });

// //       //console.log('Respuesta completa de búsqueda de deudas:', response);
// //       //console.log('Datos de la respuesta:', response.data);
// //       //console.log('Código de respuestaaaaaaa:', response.status);

// //       if (!response.status === 200) {
// //         console.error('La búsqueda no fue exitosa:', response.data.message);
// //         throw new Error(response.data.message || 'Error en la búsqueda de pagos pendientes');
// //       }

// //       if (!response.data.data || !response.data.data.pagos) {
// //         //console.log('No se encontraron pagos pendientes para el cliente:', cedula);
// //         throw new Error('No se encontraron pagos pendientes para este cliente');
// //       }

// //       //console.log('Pagos pendientes encontrados:', response.data.data.pagos);
// //       return response.data;
// //     } catch (error) {
// //       console.error('Error detallado en la búsqueda de deudas del cliente:', error);
// //       if (error.response) {
// //         console.error('Respuesta de error del servidor:', error.response.data);
// //         if (error.response.status === 401) {
// //           throw new Error('No autorizado. Token de acceso no válido o caducado');
// //         } else if (error.response.status === 404) {
// //           throw new Error('Cliente no encontrado');
// //         }
// //       }
// //       throw error;
// //     }
// //   },

// //   searchClientContract: async (branch, servicio, contrato, meses, userId) => {
// //     //console.log('Iniciando búsqueda de pagos por contrato. Sucursal:', branch, 'Servicio:', servicio, 'Contrato:', contrato, 'Meses:', meses, 'Usuario ID:', userId);
// //     try {
// //       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
// //       if (!sucursal) {
// //         console.error('Sucursal no encontrada:', branch);
// //         throw new Error('Sucursal no encontrada');
// //       }
  
// //       const servicioParam = servicio.toLowerCase() === 'internet' ? 'int' : servicio.toLowerCase();
  
// //       const url = `http://${sucursal.ip}:50101/cbcobros/cobro/pago-contrato?servicio=${servicioParam}&contrato=${contrato}&meses=${meses}&usuario=${userId}`;
// //       //console.log('URL de búsqueda de contrato:', url);
  
// //       const token = localStorage.getItem('token');
// //       //console.log('Token utilizado:', token);
  
// //       const response = await axios.get(url, {
// //         headers: {
// //           'Content-Type': 'application/json',
// //           'Authorization': `Bearer ${token}`
// //         }
// //       });
  
// //       //console.log('Respuesta completa de búsqueda de pagos por contrato:', response);
// //       //console.log('Datos de la respuesta:', response.data);
  
// //       if (!response.status === 200) {
// //         console.error('La búsqueda de contrato no fue exitosa:', response.data.message);
// //         throw new Error(response.data.message || 'Error en la búsqueda de pagos por contrato');
// //       }
  
// //       const pagoKey = servicioParam === 'int' ? 'pago_int' : 'pago_tv';
      
// //       if (!response.data.data || !response.data.data[pagoKey]) {
// //         //console.log(`No se encontraron pagos para el contrato: ${contrato}`);
// //         throw new Error('No se encontraron pagos para este contrato');
// //       }
  
// //       //console.log(`Pagos por contrato encontrados:`, response.data.data[pagoKey]);
// //       return response.data;
// //     } catch (error) {
// //       console.error('Error detallado en la búsqueda de pagos por contrato:', error);
// //       if (error.response) {
// //         console.error('Respuesta de error del servidor:', error.response.data);
// //         if (error.response.status === 401) {
// //           throw new Error('No autorizado. Token de acceso no válido o caducado');
// //         } else if (error.response.status === 404) {
// //           throw new Error('Contrato no encontrado');
// //         }
// //       }
// //       throw error;
// //     }
// //   },

// //   realizarPago: async (branch, servicio, paymentData) => {
// //     //console.log('Realizando pago para sucursal:', branch, 'Servicio:', servicio);
// //     try {
// //       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
// //       if (!sucursal) {
// //         console.error('Sucursal no encontrada:', branch);
// //         throw new Error('Sucursal no encontrada');
// //       }
// //       const servicioParam = servicio.toLowerCase() === 'internet' ? 'int' : servicio.toLowerCase();
// //       const url = `http://${sucursal.ip}:50101/cbcobros/cobro/pagar?servicio=${servicioParam}&inicial=${localStorage.getItem('inicial')}`;
// //       //console.log('URL de pago:', url);

// //       const token = localStorage.getItem('token');
// //       //console.log('Token utilizado:', token);

// //       const response = await axios.post(url, paymentData, {
// //         headers: {
// //           'Content-Type': 'application/json',
// //           'Authorization': `Bearer ${token}`
// //         }
// //       });

// //       //console.log('Respuesta completa del pago:', response);
// //       return response.data;
// //     } catch (error) {
// //       console.error('Error al realizar el pago:', error);
// //       throw error;
// //     }
// //   },


// //   getDashboardInfo: async (branch) => {
// //     //console.log('Obteniendo información del dashboard para sucursal:', branch);
// //     try {
// //       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
// //       if (!sucursal) {
// //         console.error('Sucursal no encontrada:', branch);
// //         throw new Error('Sucursal no encontrada');
// //       }
// //       const today = new Date().toISOString().split('T')[0];
// //       const inicial = localStorage.getItem('inicial');
// //       const url = `http://${sucursal.ip}:50101/cbcobros/informacion/informacion-cobros?fecha=${today}&inicial=${inicial}`;
// //       const token = localStorage.getItem('token');
// //       //console.log('URL de información del dashboard:', url);
// //       //console.log('Token utilizado:', token);
// //       const response = await axios.get(url, {
// //         headers: {
// //           'Authorization': `Bearer ${token}`
// //         }
// //       });
// //       //console.log('Respuesta completa de información del dashboard:', response);
// //       return response.data;
// //     } catch (error) {
// //       console.error('Error al obtener información del dashboard:', error);
// //       throw error;
// //     }
// //   },

 

// //   // getCobrosRealizados: async (branch) => {
// //   //   //console.log('Obteniendo cobros realizados para sucursal:', branch);
// //   //   try {
// //   //     const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
// //   //     if (!sucursal) {
// //   //       console.error('Sucursal no encontrada:', branch);
// //   //       throw new Error('Sucursal no encontrada');
// //   //     }
// //   //     const today = new Date().toISOString().split('T')[0];
// //   //     const inicial = localStorage.getItem('inicial');
// //   //     const url = `http://${sucursal.ip}:5000/cbcobros/informacion/inf-cobros-realizados?fecha=${today}&inicial=${inicial}`;
// //   //     const token = localStorage.getItem('token');
// //   //     //console.log('URL de cobros realizados:', url);
// //   //     //console.log('Token utilizado:', token);
// //   //     const response = await axios.get(url, {
// //   //       headers: {
// //   //         'Authorization': `Bearer ${token}`,
// //   //         'Content-Type': 'application/json'
// //   //       }
// //   //     });
// //   //     //console.log('Respuesta completa de cobros realizados:', response);
// //   //     return response.data;
// //   //   } catch (error) {
// //   //     console.error('Error al obtener cobros realizados:', error);
// //   //     throw error;
// //   //   }
// //   // },

// //   getCobrosRealizados: async (branch, selectedDate = null) => {
// //     //console.log('Obteniendo cobros realizados para sucursal:', branch);
// //     try {
// //       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
// //       if (!sucursal) {
// //         console.error('Sucursal no encontrada:', branch);
// //         throw new Error('Sucursal no encontrada');
// //       }
      
// //       const inicial = localStorage.getItem('inicial');
// //       const date = selectedDate || new Date().toISOString().split('T')[0];
// //       const url = `http://${sucursal.ip}:50101/cbcobros/informacion/inf-cobros-realizados?fecha=${date}&inicial=${inicial}`;
      
// //       const token = localStorage.getItem('token');
// //       //console.log('URL de cobros realizados:', url);
// //       //console.log('Token utilizado:', token);
      
// //       const response = await axios.get(url, {
// //         headers: {
// //           'Authorization': `Bearer ${token}`,
// //           'Content-Type': 'application/json'
// //         }
// //       });
// //       //console.log('Respuesta completa de cobros realizados:', response);
// //       return response.data;
// //     } catch (error) {
// //       console.error('Error al obtener cobros realizados:', error);
// //       throw error;
// //     }
// //   },

// //   validateEcuadorianId: (id) => {
// //     if (!/^\d{10,13}$/.test(id)) {
// //       return false;
// //     }

// //     if (id.length === 13) {
// //       return apiService.validateEcuadorianId(id.substring(0, 10));
// //     }

// //     const province = parseInt(id.substring(0, 2), 10);
// //     if (province < 1 || province > 24) {
// //       return false;
// //     }  
// //     const thirdDigit = parseInt(id.charAt(2), 10);
// //     if (thirdDigit < 0 || thirdDigit > 5) {
// //       return false;
// //     }

// //     let total = 0;
// //     const coefficients = [2, 1, 2, 1, 2, 1, 2, 1, 2];
// //     for (let i = 0; i < 9; i++) {
// //       let value = parseInt(id.charAt(i), 10) * coefficients[i];
// //       total += value > 9 ? value - 9 : value;
// //     }

// //     const verifier = total % 10 ? 10 - total % 10 : 0;
// //     return verifier === parseInt(id.charAt(9), 10);
// //   }
// // };

// // export default apiService;






// import axios from 'axios';
// import sucursalesData from './sucursales.json';

// const apiService = {
//   getSucursales: async () => {
//     //console.log('Obteniendo sucursales...');
//     return sucursalesData;
//   },

//   login: async (branch, usuario, contrasenia) => {
//     //console.log('Iniciando login para sucursal:', branch);
//     try {
//       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
//       if (!sucursal) {
//         console.error('Sucursal no encontrada:', branch);
//         throw new Error('Sucursal no encontrada');
//       }
//       const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/auth/login`;
//       //console.log('URL de login:', url);
//       const response = await axios.post(url, {
//         usuario, 
//         contrasenia,
//       }, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       //console.log('Respuesta de login:', response.data);
//       return response.data;
//     } catch (error) {
//       console.error('Login failed:', error);
//       throw error;
//     }
//   },

//   searchClientDebts: async (branch, cedula, userId) => {
//     //console.log('Iniciando búsqueda de deudas del cliente. Sucursal:', branch, 'Cédula:', cedula, 'Usuario ID:', userId);
//     try {
//       if (!apiService.validateEcuadorianId(cedula)) {
//         console.error('Cédula o RUC inválido:', cedula);
//         throw new Error('La cédula o RUC ingresado no es válido');
//       }

//       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
//       if (!sucursal) {
//         console.error('Sucursal no encontrada:', branch);
//         throw new Error('Sucursal no encontrada');
//       }

//       const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/cobro/pagos-pendientes?cedula=${cedula}&usuario=${userId}`;
//       //console.log('URL de búsqueda:', url);
      
//       const token = localStorage.getItem('token');
//       //console.log('Token utilizado:', token);

//       const response = await axios.get(url, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         }
//       });

//       //console.log('Respuesta completa de búsqueda de deudas:', response);
//       //console.log('Datos de la respuesta:', response.data);
//       //console.log('Código de respuestaaaaaaa:', response.status);

//       if (!response.status === 200) {
//         console.error('La búsqueda no fue exitosa:', response.data.message);
//         throw new Error(response.data.message || 'Error en la búsqueda de pagos pendientes');
//       }

//       if (!response.data.data || !response.data.data.pagos) {
//         //console.log('No se encontraron pagos pendientes para el cliente:', cedula);
//         throw new Error('No se encontraron pagos pendientes para este cliente');
//       }

//       //console.log('Pagos pendientes encontrados:', response.data.data.pagos);
//       return response.data;
//     } catch (error) {
//       console.error('Error detallado en la búsqueda de deudas del cliente:', error);
//       if (error.response) {
//         console.error('Respuesta de error del servidor:', error.response.data);
//         if (error.response.status === 401) {
//           throw new Error('No autorizado. Token de acceso no válido o caducado');
//         } else if (error.response.status === 404) {
//           throw new Error('Cliente no encontrado');
//         }
//       }
//       throw error;
//     }
//   },

//   searchClientContract: async (branch, servicio, contrato, meses, userId) => {
//     //console.log('Iniciando búsqueda de pagos por contrato. Sucursal:', branch, 'Servicio:', servicio, 'Contrato:', contrato, 'Meses:', meses, 'Usuario ID:', userId);
//     try {
//       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
//       if (!sucursal) {
//         console.error('Sucursal no encontrada:', branch);
//         throw new Error('Sucursal no encontrada');
//       }
  
//       const servicioParam = servicio.toLowerCase() === 'internet' ? 'int' : servicio.toLowerCase();
  
//       const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/cobro/pago-contrato?servicio=${servicioParam}&contrato=${contrato}&meses=${meses}&usuario=${userId}`;
//       //console.log('URL de búsqueda de contrato:', url);
  
//       const token = localStorage.getItem('token');
//       //console.log('Token utilizado:', token);
  
//       const response = await axios.get(url, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         }
//       });
  
//       //console.log('Respuesta completa de búsqueda de pagos por contrato:', response);
//       //console.log('Datos de la respuesta:', response.data);
  
//       if (!response.status === 200) {
//         console.error('La búsqueda de contrato no fue exitosa:', response.data.message);
//         throw new Error(response.data.message || 'Error en la búsqueda de pagos por contrato');
//       }
  
//       const pagoKey = servicioParam === 'int' ? 'pago_int' : 'pago_tv';
      
//       if (!response.data.data || !response.data.data[pagoKey]) {
//         //console.log(`No se encontraron pagos para el contrato: ${contrato}`);
//         throw new Error('No se encontraron pagos para este contrato');
//       }
  
//       //console.log(`Pagos por contrato encontrados:`, response.data.data[pagoKey]);
//       return response.data;
//     } catch (error) {
//       console.error('Error detallado en la búsqueda de pagos por contrato:', error);
//       if (error.response) {
//         console.error('Respuesta de error del servidor:', error.response.data);
//         if (error.response.status === 401) {
//           throw new Error('No autorizado. Token de acceso no válido o caducado');
//         } else if (error.response.status === 404) {
//           throw new Error('Contrato no encontrado');
//         }
//       }
//       throw error;
//     }
//   },

//   realizarPago: async (branch, servicio, paymentData) => {
//     //console.log('Realizando pago para sucursal:', branch, 'Servicio:', servicio);
//     try {
//       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
//       if (!sucursal) {
//         console.error('Sucursal no encontrada:', branch);
//         throw new Error('Sucursal no encontrada');
//       }
//       const servicioParam = servicio.toLowerCase() === 'internet' ? 'int' : servicio.toLowerCase();
//       const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/cobro/pagar?servicio=${servicioParam}&inicial=${localStorage.getItem('inicial')}`;
//       //console.log('URL de pago:', url);

//       const token = localStorage.getItem('token');
//       //console.log('Token utilizado:', token);

//       const response = await axios.post(url, paymentData, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         }
//       });

//       //console.log('Respuesta completa del pago:', response);
//       return response.data;
//     } catch (error) {
//       console.error('Error al realizar el pago:', error);
//       throw error;
//     }
//   },

//   getDashboardInfo: async (branch) => {
//     //console.log('Obteniendo información del dashboard para sucursal:', branch);
//     try {
//       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
//       if (!sucursal) {
//         console.error('Sucursal no encontrada:', branch);
//         throw new Error('Sucursal no encontrada');
//       }
//       const today = new Date().toISOString().split('T')[0];
//       const inicial = localStorage.getItem('inicial');
//       const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/informacion/informacion-cobros?fecha=${today}&inicial=${inicial}`;
//       const token = localStorage.getItem('token');
//       //console.log('URL de información del dashboard:', url);
//       //console.log('Token utilizado:', token);
//       const response = await axios.get(url, {
//         headers: {
//           'Authorization': `Bearer ${token}`
//         }
//       });
//       //console.log('Respuesta completa de información del dashboard:', response);
//       return response.data;
//     } catch (error) {
//       console.error('Error al obtener información del dashboard:', error);
//       throw error;
//     }
//   },

//   getCobrosRealizados: async (branch, selectedDate = null) => {
//     //console.log('Obteniendo cobros realizados para sucursal:', branch);
//     try {
//       const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
//       if (!sucursal) {
//         console.error('Sucursal no encontrada:', branch);
//         throw new Error('Sucursal no encontrada');
//       }
      
//       const inicial = localStorage.getItem('inicial');
//       const date = selectedDate || new Date().toISOString().split('T')[0];
//       const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/informacion/inf-cobros-realizados?fecha=${date}&inicial=${inicial}`;
      
//       const token = localStorage.getItem('token');
//       //console.log('URL de cobros realizados:', url);
//       //console.log('Token utilizado:', token);
      
//       const response = await axios.get(url, {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           'Content-Type': 'application/json'
//         }
//       });
//       //console.log('Respuesta completa de cobros realizados:', response);
//       return response.data;
//     } catch (error) {
//       console.error('Error al obtener cobros realizados:', error);
//       throw error;
//     }
//   },

//   validateEcuadorianId: (id) => {
//     if (!/^\d{10,13}$/.test(id)) {
//       return false;
//     }

//     if (id.length === 13) {
//       return apiService.validateEcuadorianId(id.substring(0, 10));
//     }

//     const province = parseInt(id.substring(0, 2), 10);
//     if (province < 1 || province > 24) {
//       return false;
//     }  
//     const thirdDigit = parseInt(id.charAt(2), 10);
//     if (thirdDigit < 0 || thirdDigit > 5) {
//       return false;
//     }

//     let total = 0;
//     const coefficients = [2, 1, 2, 1, 2, 1, 2, 1, 2];
//     for (let i = 0; i < 9; i++) {
//       let value = parseInt(id.charAt(i), 10) * coefficients[i];
//       total += value > 9 ? value - 9 : value;
//     }

//     const verifier = total % 10 ? 10 - total % 10 : 0;
//     return verifier === parseInt(id.charAt(9), 10);
//   }
// };

// export default apiService;








import axios from 'axios';
import sucursalesData from './sucursales.json';
import { getDecryptedItem } from '../encryption';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expirado, limpiar localStorage y redirigir al login
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

const apiService = {
  getSucursales: async () => {
    //console.log('Obteniendo sucursales...');
    return sucursalesData;
  },

  login: async (branch, usuario, contrasenia) => {
    //console.log('Iniciando login para sucursal:', branch);
    try {
      const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
      if (!sucursal) {
        console.error('Sucursal no encontrada:', branch);
        throw new Error('Sucursal no encontrada');
      }
      const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/auth/login`;
      //console.log('URL de login:', url);
      const response = await axiosInstance.post(url, {
        usuario, 
        contrasenia,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      //console.log('Respuesta de login:', response.data);
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  },

  searchClientDebts: async (branch, cedula, userId) => {
    //console.log('Iniciando búsqueda de deudas del cliente. Sucursal:', branch, 'Cédula:', cedula, 'Usuario ID:', userId);
    try {
      if (!apiService.validateEcuadorianId(cedula)) {
        console.error('Cédula o RUC inválido:', cedula);
        throw new Error('La cédula o RUC ingresado no es válido');
      }

      const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
      if (!sucursal) {
        console.error('Sucursal no encontrada:', branch);
        throw new Error('Sucursal no encontrada');
      }

      const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/cobro/pagos-pendientes?cedula=${cedula}&usuario=${userId}`;
      //console.log('URL de búsqueda:', url);
      
      const token = getDecryptedItem('token');
      //console.log('Token utilizado:', token);

      const response = await axiosInstance.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      // console.log('Respuesta completa de búsqueda de deudas:', response);
      // console.log('Datos de la respuesta:', response.data);
      // console.log('Código de respuesta:', response.status);

      if (response.status !== 200) {
        console.error('La búsqueda no fue exitosa:', response.data.message);
        throw new Error(response.data.message || 'Error en la búsqueda de pagos pendientes');
      }

      if (!response.data.data || !response.data.data.pagos) {
        //console.log('No se encontraron pagos pendientes para el cliente:', cedula);
        throw new Error('No se encontraron pagos pendientes para este cliente');
      }

      //console.log('Pagos pendientes encontrados:', response.data.data.pagos);
      return response.data;
    } catch (error) {
      console.error('Error detallado en la búsqueda de deudas del cliente:', error);
      if (error.response) {
        console.error('Respuesta de error del servidor:', error.response.data);
        if (error.response.status === 401) {
          throw new Error('No autorizado. Token de acceso no válido o caducado');
        } else if (error.response.status === 404) {
          throw new Error('Cliente no encontrado');
        }
      }
      throw error;
    }
  },

  searchClientContract: async (branch, servicio, contrato, meses, userId) => {
    //console.log('Iniciando búsqueda de pagos por contrato. Sucursal:', branch, 'Servicio:', servicio, 'Contrato:', contrato, 'Meses:', meses, 'Usuario ID:', userId);
    try {
      const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
      if (!sucursal) {
        console.error('Sucursal no encontrada:', branch);
        throw new Error('Sucursal no encontrada');
      }
  
      const servicioParam = servicio.toLowerCase() === 'internet' ? 'int' : servicio.toLowerCase();
  
      const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/cobro/pago-contrato?servicio=${servicioParam}&contrato=${contrato}&meses=${meses}&usuario=${userId}`;
      //console.log('URL de búsqueda de contrato:', url);
  
      const token = getDecryptedItem('token');
      //console.log('Token utilizado:', token);
  
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      //console.log('Respuesta completa de búsqueda de pagos por contrato:', response);
      //console.log('Datos de la respuesta:', response.data);
  
      if (response.status !== 200) {
        console.error('La búsqueda de contrato no fue exitosa:', response.data.message);
        throw new Error(response.data.message || 'Error en la búsqueda de pagos por contrato');
      }
  
      const pagoKey = servicioParam === 'int' ? 'pago_int' : 'pago_tv';
      
      if (!response.data.data || !response.data.data[pagoKey]) {
        //console.log(`No se encontraron pagos para el contrato: ${contrato}`);
        throw new Error('No se encontraron pagos para este contrato');
      }
  
      //console.log(`Pagos por contrato encontrados:`, response.data.data[pagoKey]);
      return response.data;
    } catch (error) {
      console.error('Error detallado en la búsqueda de pagos por contrato:', error);
      if (error.response) {
        console.error('Respuesta de error del servidor:', error.response.data);
        if (error.response.status === 401) {
          throw new Error('No autorizado. Token de acceso no válido o caducado');
        } else if (error.response.status === 404) {
          throw new Error('Contrato no encontrado');
        }
      }
      throw error;
    }
  },

  realizarPago: async (branch, servicio, paymentData) => {
    //console.log('Realizando pago para sucursal:', branch, 'Servicio:', servicio);
    try {
      const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
      if (!sucursal) {
        console.error('Sucursal no encontrada:', branch);
        throw new Error('Sucursal no encontrada');
      }
      const servicioParam = servicio.toLowerCase() === 'internet' ? 'int' : servicio.toLowerCase();
      const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/cobro/pagar?servicio=${servicioParam}&inicial=${localStorage.getItem('inicial')}`;
      //console.log('URL de pago:', url);

      const token = getDecryptedItem('token');
      //console.log('Token utilizado:', token);

      const response = await axios.post(url, paymentData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      //console.log('Respuesta completa del pago:', response);
      return response.data;
    } catch (error) {
      console.error('Error al realizar el pago:', error);
      throw error;
    }
  },



  getDashboardInfo: async (branch) => {
    //console.log('Obteniendo información del dashboard para sucursal:', branch);
    try {
      const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
      if (!sucursal) {
        console.error('Sucursal no encontrada:', branch);
        throw new Error('Sucursal no encontrada');
      }
      const today = new Date().toISOString().split('T')[0];
      const inicial = localStorage.getItem('inicial');
      const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/informacion/informacion-cobros?fecha=${today}&inicial=${inicial}`;
      const token = getDecryptedItem('token');
      //console.log('URL de información del dashboard:', url);
      //console.log('Token utilizado:', token);
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      //console.log('Respuesta completa de información del dashboard:', response);
      return response.data;
    } catch (error) {
      console.error('Error al obtener información del dashboard:', error);
      throw error;
    }
  },

  // getCobrosRealizados: async (branch, selectedDate = null) => {
  //   //console.log('Obteniendo cobros realizados para sucursal:', branch);
  //   try {
  //     const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
  //     if (!sucursal) {
  //       console.error('Sucursal no encontrada:', branch);
  //       throw new Error('Sucursal no encontrada');
  //     }
      
  //     const inicial = localStorage.getItem('inicial');
  //     const date = selectedDate || new Date().toISOString().split('T')[0];
  //     //antes tenia esta url pero se cambio por una nueva
  //     const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/informacion/inf-cobros-realizados?fecha=${date}&inicial=${inicial}`;
      
  //     const token = getDecryptedItem('token');
  //     //console.log('URL de cobros realizados:', url);
  //     //console.log('Token utilizado:', token);
      
  //     const response = await axios.get(url, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     //console.log('Respuesta completa de cobros realizados:', response);
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error al obtener cobros realizados:', error);
  //     throw error;
  //   }
  // },


  getCobrosRealizados: async (branch, startDate, endDate) => {
    try {
      const sucursal = sucursalesData.sucursales.find(s => s.nombre === branch);
      if (!sucursal) {
        console.error('Sucursal no encontrada:', branch);
        throw new Error('Sucursal no encontrada');
      }
     
      const inicial = localStorage.getItem('inicial');
      const url = `http://${sucursal.ip}:${sucursal.puerto}/cbcobros/informacion/inf-cobros-realizados?fecha_ini=${startDate}&fecha_fin=${endDate}&inicial=${inicial}`;
     
      const token = getDecryptedItem('token');
     
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error al obtener cobros realizados:', error);
      throw error;
    }
  },


  validateEcuadorianId: (id) => {
    if (!/^\d{10,13}$/.test(id)) {
      return false;
    }

    if (id.length === 13) {
      return apiService.validateEcuadorianId(id.substring(0, 10));
    }

    const province = parseInt(id.substring(0, 2), 10);
    if (province < 1 || province > 24) {
      return false;
    }  
    const thirdDigit = parseInt(id.charAt(2), 10);
    if (thirdDigit < 0 || thirdDigit > 5) {
      return false;
    }

    let total = 0;
    const coefficients = [2, 1, 2, 1, 2, 1, 2, 1, 2];
    for (let i = 0; i < 9; i++) {
      let value = parseInt(id.charAt(i), 10) * coefficients[i];
      total += value > 9 ? value - 9 : value;
    }

    const verifier = total % 10 ? 10 - total % 10 : 0;
    return verifier === parseInt(id.charAt(9), 10);
  }
};

export default apiService;