


// // // // import React, { useState, useEffect } from 'react';
// // // // import { useNavigate } from 'react-router-dom';
// // // // import Header from '../components/header';
// // // // import Footer from '../components/footer';
// // // // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // // // import { faPrint, faReceipt, faChevronDown, faChevronUp, faGlobe, faTv, faList, faMoneyBillWave, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
// // // // import { jsPDF } from "jspdf";
// // // // import 'jspdf-autotable';
// // // // import apiService from '../services/apiService';
// // // // import logo from '../assets/logo.png';

// // // // const PaymentHistory = () => {
// // // //   const [payments, setPayments] = useState([]);
// // // //   const [filteredPayments, setFilteredPayments] = useState([]);
// // // //   const [expandedPayment, setExpandedPayment] = useState(null);
// // // //   const [isLoading, setIsLoading] = useState(true);
// // // //   const [filter, setFilter] = useState('all');
// // // //   const [totalCobros, setTotalCobros] = useState(0);
// // // //   const [totalRecaudado, setTotalRecaudado] = useState(0);
// // // //   const navigate = useNavigate();

// // // //   useEffect(() => {
// // // //     const fetchPayments = async () => {
// // // //       try {
// // // //         const storedUser = localStorage.getItem("user");
// // // //         if (!storedUser) {
// // // //           navigate('/login');
// // // //           return;
// // // //         }

// // // //         const user = JSON.parse(storedUser);
// // // //         if (!user || !user.sucursal) {
// // // //           throw new Error('Sucursal no encontrada');
// // // //         }

// // // //         const branch = user.sucursal;
// // // //         //console.log('Branch:', branch);
// // // //         const response = await apiService.getCobrosRealizados(branch);

// // // //         const allPayments = [
// // // //           ...(response.data.int || []).map(p => ({ ...p, type: 'internet' })),
// // // //           ...(response.data.tv || []).map(p => ({ ...p, type: 'tv' }))
// // // //         ];

// // // //         setPayments(allPayments);
// // // //         setFilteredPayments(allPayments);
// // // //         setTotalCobros(allPayments.length);
// // // //         setTotalRecaudado(allPayments.reduce((sum, p) => sum + p.total, 0));
// // // //       } catch (error) {
// // // //         console.error('Error fetching payments:', error);
// // // //         if (error.response && error.response.status === 401) {
// // // //           navigate('/login');
// // // //         }
// // // //       } finally {
// // // //         setIsLoading(false);
// // // //       }
// // // //     };

// // // //     fetchPayments();
// // // //   }, [navigate]);

// // // //   useEffect(() => {
// // // //     if (filter === 'all') {
// // // //       setFilteredPayments(payments);
// // // //     } else {
// // // //       setFilteredPayments(payments.filter(p => p.type === filter));
// // // //     }
// // // //   }, [filter, payments]);

// // // //   const generateThermalPrint = (payment) => {
// // // //     const doc = new jsPDF({
// // // //       unit: 'mm',
// // // //       format: [80, 150]
// // // //     });

// // // //     doc.setFontSize(10);
// // // //     doc.setFont('helvetica', 'bold');
// // // //     doc.text('COMPROBANTE DE PAGO', 40, 10, { align: 'center' });
// // // //     doc.setFontSize(8);
// // // //     doc.setFont('helvetica', 'normal');

// // // //     doc.text(`Factura N°: ${payment.cod_fac}`, 5, 20);
// // // //     doc.text(`Fecha: ${new Date(payment.fecha).toLocaleDateString()}`, 5, 25);
// // // //     doc.text(`Cliente: ${payment.nombre_cliente || 'N/A'}`, 5, 30);
// // // //     doc.text(`RUC/CI: ${payment.ruc || 'N/A'}`, 5, 35);
// // // //     doc.text(`Contrato: ${payment.contrato || 'N/A'}`, 5, 40);

// // // //     doc.line(5, 45, 75, 45);

// // // //     doc.text('Descripción', 5, 50);
// // // //     doc.text('Valor', 70, 50, { align: 'right' });

// // // //     let yPos = 55;
// // // //     payment.informacion_facturas_d.forEach(item => {
// // // //       doc.text(item.descripcion, 5, yPos, { maxWidth: 50 });
// // // //       doc.text(`$${item.valor_t.toFixed(2)}`, 70, yPos, { align: 'right' });
// // // //       yPos += 10;
// // // //     });

// // // //     doc.line(5, yPos, 75, yPos);
// // // //     yPos += 5;

// // // //     doc.text('TOTAL:', 5, yPos);
// // // //     doc.setFont('helvetica', 'bold');
// // // //     doc.text(`$${payment.total.toFixed(2)}`, 70, yPos, { align: 'right' });

// // // //     yPos += 10;
// // // //     doc.setFont('helvetica', 'normal');
// // // //     doc.setFontSize(6);
// // // //     doc.text('Este documento no tiene validez tributaria', 40, yPos, { align: 'center' });
    
// // // //     doc.save(`comprobante_termico_${payment.cod_fac}.pdf`);
// // // //   };

// // // //   const generateInvoicePDF = (payment) => {
// // // //     const doc = new jsPDF();
// // // //     const pageWidth = doc.internal.pageSize.width;
    
// // // //     const centerText = (text, y) => {
// // // //       const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
// // // //       const x = (pageWidth - textWidth) / 2;
// // // //       doc.text(text, x, y);
// // // //     };

// // // //     doc.addImage(logo, 'PNG', 10, 10, 40, 20);
// // // //     doc.setFontSize(18);
// // // //     doc.setFont('helvetica', 'bold');
// // // //     centerText('COMPROBANTE DE PAGO', 20);

// // // //     doc.setFontSize(10);
// // // //     doc.setFont('helvetica', 'normal');
// // // //     doc.text(`N° Comprobante: ${payment.cod_fac}`, 10, 35);
// // // //     doc.text(`Fecha: ${new Date(payment.fecha).toLocaleDateString()}`, pageWidth - 10, 35, { align: 'right' });

// // // //     doc.setFontSize(12);
// // // //     doc.setFont('helvetica', 'bold');
// // // //     doc.text('Información del Cliente', 10, 45);
// // // //     doc.setFont('helvetica', 'normal');
// // // //     doc.setFontSize(10);
// // // //     doc.text(`Nombre: ${payment.nombre_cliente || 'N/A'}`, 10, 52);
// // // //     doc.text(`RUC/CI: ${payment.ruc || 'N/A'}`, 10, 58);
// // // //     doc.text(`Dirección: ${payment.direccion || 'N/A'}`, 10, 64);
// // // //     doc.text(`Teléfono: ${payment.telefono || 'N/A'}`, 10, 70);
// // // //     doc.text(`Contrato: ${payment.contrato || 'N/A'}`, 10, 76);

// // // //     doc.setFontSize(12);
// // // //     doc.setFont('helvetica', 'bold');
// // // //     doc.text('Detalles del Servicio', 10, 86);
// // // //     doc.autoTable({
// // // //       startY: 92,
// // // //       head: [['Descripción', 'Cantidad', 'Valor Unitario', 'Valor Total']],
// // // //       body: payment.informacion_facturas_d.map(item => [
// // // //         item.descripcion,
// // // //         item.cantidad,
// // // //         `$${(item.valor_t / item.cantidad).toFixed(2)}`,
// // // //         `$${item.valor_t.toFixed(2)}`
// // // //       ]),
// // // //       theme: 'striped',
// // // //       headStyles: { fillColor: [128, 63, 152], textColor: 255 },
// // // //       columnStyles: {
// // // //         0: { cellWidth: 'auto' },
// // // //         1: { cellWidth: 30, halign: 'center' },
// // // //         2: { cellWidth: 40, halign: 'right' },
// // // //         3: { cellWidth: 40, halign: 'right' }
// // // //       },
// // // //       styles: { cellPadding: 5, fontSize: 10 },
// // // //     });

// // // //     const finalY = doc.lastAutoTable.finalY + 10;
// // // //     doc.setFontSize(12);
// // // //     doc.setFont('helvetica', 'bold');
// // // //     doc.text('TOTAL:', pageWidth - 50, finalY);
// // // //     doc.setFontSize(14);
// // // //     doc.text(`$${payment.total.toFixed(2)}`, pageWidth - 10, finalY, { align: 'right' });

// // // //     doc.setFont('helvetica', 'italic');
// // // //     doc.setFontSize(8);
// // // //     centerText('Este comprobante no tiene validez tributaria', 280);

// // // //     doc.save(`comprobante_factura_${payment.cod_fac}.pdf`);
// // // //   };

// // // //   const toggleExpand = (id) => {
// // // //     setExpandedPayment(expandedPayment === id ? null : id);
// // // //   };

// // // //   if (isLoading) {
// // // //     return (
// // // //       <div className="flex items-center justify-center min-h-screen bg-[#FBFEF7]">
// // // //         <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#803f98]"></div>
// // // //       </div>
// // // //     );
// // // //   }

// // // //   return (
// // // //     <div className="flex flex-col min-h-screen bg-[#FBFEF7]">
// // // //       <Header />
// // // //       <div className="h-1 bg-[#f5831f]"></div>
// // // //       <div className="flex-grow flex flex-col p-6 md:p-12">
// // // //         <div className="flex flex-col md:flex-row justify-between items-center mb-8">
// // // //           <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-4 md:mb-0">
// // // //             Historial de Pagos
// // // //           </h1>
// // // //           <div className="flex space-x-4">
// // // //             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
// // // //               <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-[#803f98] text-2xl mr-3" />
// // // //               <div>
// // // //                 <p className="text-sm text-gray-600">Total de cobros hoy</p>
// // // //                 <p className="text-xl font-bold text-[#803f98]">{totalCobros}</p>
// // // //               </div>
// // // //             </div>
// // // //             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
// // // //               <FontAwesomeIcon icon={faMoneyBillWave} className="text-[#f5831f] text-2xl mr-3" />
// // // //               <div>
// // // //                 <p className="text-sm text-gray-600">Total recaudado</p>
// // // //                 <p className="text-xl font-bold text-[#f5831f]">${totalRecaudado.toFixed(2)}</p>
// // // //               </div>
// // // //             </div>
// // // //           </div>
// // // //         </div>
        
// // // //         <div className="mb-6">
// // // //           <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
// // // //             <div className="flex items-center space-x-4">
// // // //               <label htmlFor="service-filter" className="text-lg font-semibold text-[#803f98]">Filtrar por servicio:</label>
// // // //               <div className="relative">
// // // //                 <select
// // // //                   id="service-filter"
// // // //                   value={filter}
// // // //                   onChange={(e) => setFilter(e.target.value)}
// // // //                   className="appearance-none bg-white border border-[#803f98] text-[#803f98] py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-[#f5831f] transition duration-300 ease-in-out"
// // // //                 >
// // // //                   <option value="all">Todos los servicios</option>
// // // //                   <option value="internet">Internet</option>
// // // //                   <option value="tv">TV</option>
// // // //                 </select>
// // // //                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-[#803f98]">
// // // //                   <FontAwesomeIcon icon={faChevronDown} />
// // // //                 </div>
// // // //               </div>
// // // //             </div>
// // // //             <div className="flex space-x-2">
// // // //               <button
// // // //                 onClick={() => setFilter('all')}
// // // //                 className={`flex items-center justify-center px-4 py-2 rounded-full ${filter === 'all' ? 'bg-[#803f98] text-white' : 'bg-white text-[#803f98] border border-[#803f98]'} transition duration-300 ease-in-out`}
// // // //               >
// // // //                 <FontAwesomeIcon icon={faList} className="mr-2" />
// // // //                 Todos
// // // //               </button>
// // // //               <button
// // // //                 onClick={() => setFilter('internet')}
// // // //                 className={`flex items-center justify-center px-4 py-2 rounded-full ${filter === 'internet' ? 'bg-[#803f98] text-white' : 'bg-white text-[#803f98] border border-[#803f98]'} transition duration-300 ease-in-out`}
// // // //               >
// // // //                 <FontAwesomeIcon icon={faGlobe} className="mr-2" />
// // // //                 Internet
// // // //               </button>
// // // //               <button
// // // //                 onClick={() => setFilter('tv')}
// // // //                 className={`flex items-center justify-center px-4 py-2 rounded-full ${filter === 'tv' ? 'bg-[#803f98] text-white' : 'bg-white text-[#803f98] border border-[#803f98]'} transition duration-300 ease-in-out`}
// // // //               >
// // // //                 <FontAwesomeIcon icon={faTv} className="mr-2" />
// // // //                 TV
// // // //               </button>
// // // //             </div>
// // // //           </div>
// // // //         </div>
        
// // // //         <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg mb-8 overflow-x-auto">


// // // //         <table className="w-full">
// // // //   <thead>
// // // //     <tr className="bg-[#803f98] text-white">
// // // //       <th className="p-2">#</th>
// // // //       <th className="p-2">Fecha</th>
// // // //       <th className="p-2">RUC/Cédula</th>
// // // //       <th className="p-2">Cliente</th>
// // // //       <th className="p-2">Servicio</th>
// // // //       <th className="p-2">Total</th>
// // // //       <th className="p-2">Acciones</th>
// // // //     </tr>
// // // //   </thead>
// // // //   <tbody>
// // // //     {filteredPayments.map((payment) => (
// // // //       <React.Fragment key={payment.cod_fac}>
// // // //         <tr className={`border-b ${payment.type === 'internet' ? 'bg-purple-100/30' : 'bg-orange-100/30'}`}>
// // // //           <td className="p-2">{payment.cod_fac}</td>
// // // //           <td className="p-2">{new Date(payment.fecha).toLocaleDateString()}</td>
// // // //           <td className="p-2">{payment.ruc || 'N/A'}</td>
// // // //           <td className="p-2">{payment.nombre_cliente || 'N/A'}</td>
// // // //           <td className="p-2">{payment.type === 'internet' ? 'Internet' : 'TV'}</td>
// // // //           <td className="p-2">${payment.total.toFixed(2)}</td>
// // // //           <td className="p-2 space-x-2">
// // // //             <button onClick={() => generateThermalPrint(payment)} className="bg-[#f5831f] text-white font-bold py-1 px-2 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f] inline-flex items-center">
// // // //               <FontAwesomeIcon icon={faPrint} className="mr-1" />
// // // //               Térmica
// // // //             </button>
// // // //             <button onClick={() => generateInvoicePDF(payment)} className="bg-[#803f98] text-white font-bold py-1 px-2 rounded-md hover:bg-[#6a2f7a] focus:outline-none focus:ring-2 focus:ring-[#803f98] inline-flex items-center">
// // // //               <FontAwesomeIcon icon={faReceipt} className="mr-1" />
// // // //               Factura
// // // //             </button>
// // // //             <button onClick={() => toggleExpand(payment.cod_fac)} className="bg-gray-200 text-gray-700 font-bold py-1 px-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 inline-flex items-center">
// // // //               <FontAwesomeIcon icon={expandedPayment === payment.cod_fac ? faChevronUp : faChevronDown} />
// // // //             </button>
// // // //           </td>
// // // //         </tr>
// // // //         {expandedPayment === payment.cod_fac && (
// // // //           <tr>
// // // //             <td colSpan="7">
// // // //               <div className="p-2 bg-gray-200 rounded-md">
// // // //                 <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
// // // //                   <div>
// // // //                     <p><strong>Dirección:</strong> {payment.direccion || 'N/A'}</p>
// // // //                     <p><strong>Teléfono:</strong> {payment.telefono || 'N/A'}</p>
// // // //                     <p><strong>Celular:</strong> {payment.celular || 'N/A'}</p>
// // // //                   </div>
// // // //                   <div>
// // // //                     <p><strong>Contrato:</strong> {payment.contrato || 'N/A'}</p>
// // // //                     <p><strong>Código de Cliente:</strong> {payment.cod_cliente || 'N/A'}</p>
// // // //                     <p><strong>Código de Documento:</strong> {payment.cod_doc || 'N/A'}</p>
// // // //                   </div>
// // // //                 </div>
// // // //                 <h5 className="font-bold mt-2 mb-1">Detalles del servicio:</h5>
// // // //                 <table className="w-full text-sm">
// // // //                   <thead>
// // // //                     <tr className="bg-[#803f98] text-white">
// // // //                       <th className="p-1">Descripción</th>
// // // //                       <th className="p-1">Cantidad</th>
// // // //                       <th className="p-1">Valor</th>
// // // //                     </tr>
// // // //                   </thead>
// // // //                   <tbody>
// // // //                     {payment.informacion_facturas_d.map((detalle, index) => (
// // // //                       <tr key={index} className="border-b">
// // // //                         <td className="p-1">{detalle.descripcion}</td>
// // // //                         <td className="p-1">{detalle.cantidad}</td>
// // // //                         <td className="p-1">${detalle.valor_t.toFixed(2)}</td>
// // // //                       </tr>
// // // //                     ))}
// // // //                   </tbody>
// // // //                 </table>
// // // //               </div>
// // // //             </td>
// // // //           </tr>
// // // //         )}
// // // //       </React.Fragment>
// // // //     ))}
// // // //   </tbody>
// // // // </table>
// // // //         </div>
// // // //       </div>
// // // //       <Footer />
// // // //     </div>
// // // //   );
// // // // };

// // // // export default PaymentHistory;





// // // import React, { useState, useEffect } from 'react';
// // // import { useNavigate } from 'react-router-dom';
// // // import Header from '../components/header';
// // // import Footer from '../components/footer';
// // // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // // import { faPrint, faReceipt, faChevronDown, faChevronUp, faGlobe, faTv, faList, faMoneyBillWave, faFileInvoiceDollar, faCalendar, faFileExport } from '@fortawesome/free-solid-svg-icons';
// // // import { jsPDF } from "jspdf";
// // // import 'jspdf-autotable';
// // // import apiService from '../services/apiService';
// // // import logo from '../assets/logo.png';

// // // const PaymentHistory = () => {
// // //   const [payments, setPayments] = useState([]);
// // //   const [filteredPayments, setFilteredPayments] = useState([]);
// // //   const [expandedPayment, setExpandedPayment] = useState(null);
// // //   const [isLoading, setIsLoading] = useState(true);
// // //   const [filter, setFilter] = useState('all');
// // //   const [totalCobros, setTotalCobros] = useState(0);
// // //   const [totalRecaudado, setTotalRecaudado] = useState(0);
// // //   const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
// // //   const [noDataMessage, setNoDataMessage] = useState('');
// // //   const navigate = useNavigate();

// // //   useEffect(() => {
// // //     fetchPayments();
// // //   }, []);

// // //   const fetchPayments = async () => {
// // //     try {
// // //       const storedUser = localStorage.getItem("user");
// // //       if (!storedUser) {
// // //         navigate('/login');
// // //         return;
// // //       }

// // //       const user = JSON.parse(storedUser);
// // //       if (!user || !user.sucursal) {
// // //         throw new Error('Sucursal no encontrada');
// // //       }

// // //       const branch = user.sucursal;
// // //       const response = await apiService.getCobrosRealizados(branch, selectedDate);

// // //       if (response.status === 204 || !response.data) {
// // //         setNoDataMessage('No se encontraron cobros para la fecha seleccionada');
// // //         setPayments([]);
// // //         setFilteredPayments([]);
// // //         setTotalCobros(0);
// // //         setTotalRecaudado(0);
// // //       } else {
// // //         const allPayments = [
// // //           ...(response.data.int || []).map(p => ({ ...p, type: 'internet' })),
// // //           ...(response.data.tv || []).map(p => ({ ...p, type: 'tv' }))
// // //         ];

// // //         setPayments(allPayments);
// // //         applyFilters(allPayments);
// // //         setNoDataMessage('');
// // //       }
// // //     } catch (error) {
// // //       console.error('Error fetching payments:', error);
// // //       if (error.response && error.response.status === 401) {
// // //         navigate('/login');
// // //       }
// // //     } finally {
// // //       setIsLoading(false);
// // //     }
// // //   };

// // //   const applyFilters = (paymentsToFilter) => {
// // //     let filtered = paymentsToFilter;
// // //     if (filter !== 'all') {
// // //       filtered = filtered.filter(p => p.type === filter);
// // //     }
// // //     setFilteredPayments(filtered);
// // //     setTotalCobros(filtered.length);
// // //     setTotalRecaudado(filtered.reduce((sum, p) => sum + p.total, 0));
// // //   };

// // //   useEffect(() => {
// // //     applyFilters(payments);
// // //   }, [filter, payments]);

// // //   const handleDateChange = async () => {
// // //     setIsLoading(true);
// // //     await fetchPayments();
// // //   };

// // //   const generateReport = () => {
// // //     const doc = new jsPDF();
// // //     const pageWidth = doc.internal.pageSize.width;
// // //     const pageHeight = doc.internal.pageSize.height;
    
// // //     // Add logo
// // //   const logoWidth = 60; // Ajustado para mantener la proporción
// // //   const logoHeight = 12.24; // Calculado para mantener la proporción original
// // //   doc.addImage(logo, 'PNG', (pageWidth - logoWidth) / 2, 10, logoWidth, logoHeight);
    
// // //     // Add title
// // //     doc.setFontSize(18);
// // //     doc.text('Reporte de Cobros', pageWidth / 2, 40, { align: 'center' });
    
// // //     // Add date and user info
// // //     const userInitial = localStorage.getItem('inicial') || '';
// // //     const userName = JSON.parse(localStorage.getItem('user') || '{}').nombre_usuario || '';
// // //     doc.setFontSize(12);
// // //     doc.text(`Fecha: ${selectedDate}`, 20, 50);
// // //     doc.text(`Usuario: ${userInitial} - ${userName}`, 20, 57);
    
// // //     // Add summary
// // //     doc.text(`Total de cobros: ${totalCobros}`, 20, 67);
// // //     doc.text(`Total recaudado: $${totalRecaudado.toFixed(2)}`, 20, 74);
    
// // //     // Add table
// // //     doc.autoTable({
// // //       startY: 80,
// // //       head: [['#', 'Fecha', 'RUC/Cédula', 'Cliente', 'Servicio', 'Total']],
// // //       body: filteredPayments.map(p => [
// // //         p.cod_fac,
// // //         new Date(p.fecha).toLocaleDateString(),
// // //         p.ruc || 'N/A',
// // //         p.nombre_cliente || 'N/A',
// // //         p.type === 'internet' ? 'Internet' : 'TV',
// // //         `$${p.total.toFixed(2)}`
// // //       ]),
// // //     });
    
// // //     doc.save(`reporte_cobros_${selectedDate}.pdf`);
// // //   };

// // //   const generateThermalPrint = (payment) => {
// // //     const doc = new jsPDF({
// // //       unit: 'mm',
// // //       format: [80, 150]
// // //     });

// // //     doc.setFontSize(10);
// // //     doc.setFont('helvetica', 'bold');
// // //     doc.text('COMPROBANTE DE PAGO', 40, 10, { align: 'center' });
// // //     doc.setFontSize(8);
// // //     doc.setFont('helvetica', 'normal');

// // //     doc.text(`Factura N°: ${payment.cod_fac}`, 5, 20);
// // //     doc.text(`Fecha: ${new Date(payment.fecha).toLocaleDateString()}`, 5, 25);
// // //     doc.text(`Cliente: ${payment.nombre_cliente || 'N/A'}`, 5, 30);
// // //     doc.text(`RUC/CI: ${payment.ruc || 'N/A'}`, 5, 35);
// // //     doc.text(`Contrato: ${payment.contrato || 'N/A'}`, 5, 40);

// // //     doc.line(5, 45, 75, 45);

// // //     doc.text('Descripción', 5, 50);
// // //     doc.text('Valor', 70, 50, { align: 'right' });

// // //     let yPos = 55;
// // //     payment.informacion_facturas_d.forEach(item => {
// // //       doc.text(item.descripcion, 5, yPos, { maxWidth: 50 });
// // //       doc.text(`$${item.valor_t.toFixed(2)}`, 70, yPos, { align: 'right' });
// // //       yPos += 10;
// // //     });

// // //     doc.line(5, yPos, 75, yPos);
// // //     yPos += 5;

// // //     doc.text('TOTAL:', 5, yPos);
// // //     doc.setFont('helvetica', 'bold');
// // //     doc.text(`$${payment.total.toFixed(2)}`, 70, yPos, { align: 'right' });

// // //     yPos += 10;
// // //     doc.setFont('helvetica', 'normal');
// // //     doc.setFontSize(6);
// // //     doc.text('Este documento no tiene validez tributaria', 40, yPos, { align: 'center' });
    
// // //     doc.save(`comprobante_termico_${payment.cod_fac}.pdf`);
// // //   };








// // //   const generateInvoicePDF = (payment) => {
// // //     const doc = new jsPDF();
// // //     const pageWidth = doc.internal.pageSize.getWidth();
// // //     const pageHeight = doc.internal.pageSize.getHeight();
  
// // //     // Helper function for centered text
// // //     const centerText = (text, y, fontSize = 12, fontStyle = 'normal') => {
// // //       doc.setFontSize(fontSize);
// // //       doc.setFont('helvetica', fontStyle);
// // //       const textWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;
// // //       const x = (pageWidth - textWidth) / 2;
// // //       doc.text(text, x, y);
// // //     };
  
// // //     // Add logo
// // //     const logoWidth = 50;
// // //     const logoHeight = 10.2;
// // //     const logoX = 10;
// // //     doc.addImage(logo, 'PNG', logoX, 10, logoWidth, logoHeight);
  
// // //     // Title and invoice number
// // //     doc.setFont('helvetica', 'bold');
// // //     doc.setFontSize(20);
// // //     doc.text('Comprobante', pageWidth - 10, 20, { align: 'right' });
// // //     doc.setFontSize(12);
// // //     doc.text(`N°: ${payment.cod_fac}`, pageWidth - 10, 27, { align: 'right' });
  
// // //     // Client information in rows
// // //     doc.setFont('helvetica', 'bold');
// // //     doc.setFontSize(12);
// // //     doc.text('Información del Cliente', 10, 40);
    
// // //     doc.setFont('helvetica', 'normal');
// // //     doc.setFontSize(10);
// // //     doc.text(`Nombre: ${payment.nombre_cliente || 'N/A'}`, 10, 48);
// // //     doc.text(`RUC/CI: ${payment.ruc || 'N/A'}`, 10, 54);
// // //     doc.text(`Dirección: ${payment.direccion || 'N/A'}`, 10, 60);
// // //     doc.text(`Teléfono: ${payment.telefono || 'N/A'}`, 10, 66);
// // //     doc.text(`Contrato: ${payment.contrato || 'N/A'}`, 10, 72);
  
// // //     // Invoice details
// // //     doc.autoTable({
// // //       startY: 80,
// // //       head: [['Descripción', 'Cantidad', 'Valor Unitario', 'Valor Total']],
// // //       body: payment.informacion_facturas_d.map(item => [
// // //         item.descripcion,
// // //         item.cantidad,
// // //         `$${(item.valor_t / item.cantidad).toFixed(2)}`,
// // //         `$${item.valor_t.toFixed(2)}`
// // //       ]),
// // //       theme: 'striped',
// // //       headStyles: { fillColor: [128, 63, 152], textColor: 255, fontStyle: 'bold' },
// // //       bodyStyles: { fontSize: 9 },
// // //       columnStyles: {
// // //         0: { cellWidth: 'auto' },
// // //         1: { cellWidth: 30, halign: 'center' },
// // //         2: { cellWidth: 40, halign: 'right' },
// // //         3: { cellWidth: 40, halign: 'right' }
// // //       },
// // //       styles: { cellPadding: 5 },
// // //     });
  
// // //     // Total in a box
// // //     const finalY = doc.lastAutoTable.finalY + 10;
// // //     doc.setFillColor(240, 240, 240);
// // //     doc.rect(pageWidth - 70, finalY - 5, 60, 10, 'F');
// // //     doc.setFont('helvetica', 'bold');
// // //     doc.setFontSize(12);
// // //     doc.text('TOTAL:', pageWidth - 65, finalY + 3);
// // //     doc.setFontSize(14);
// // //     doc.text(`$${payment.total.toFixed(2)}`, pageWidth - 12, finalY + 3, { align: 'right' });
  
// // //     // Date and footer
// // //     doc.setFont('helvetica', 'normal');
// // //     doc.setFontSize(9);
// // //     doc.text(`Fecha de emisión: ${new Date(payment.fecha).toLocaleDateString()}`, 10, pageHeight - 20);
// // //     doc.setFont('helvetica', 'italic');
// // //     doc.setFontSize(8);
// // //     centerText('Este comprobante no tiene validez tributaria', pageHeight - 10);
  
// // //     doc.save(`factura_${payment.cod_fac}.pdf`);
// // //   };





// // //   const toggleExpand = (id) => {
// // //     setExpandedPayment(expandedPayment === id ? null : id);
// // //   };

// // //   if (isLoading) {
// // //     return (
// // //       <div className="flex items-center justify-center min-h-screen bg-[#FBFEF7]">
// // //         <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#803f98]"></div>
// // //       </div>
// // //     );
// // //   }

// // //   return (
// // //     <div className="flex flex-col min-h-screen bg-[#FBFEF7]">
// // //       <Header />
// // //       <div className="h-1 bg-[#f5831f]"></div>
// // //       <div className="flex-grow flex flex-col p-6 md:p-12">
// // //         <div className="flex flex-col md:flex-row justify-between items-center mb-8">
// // //           <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-4 md:mb-0">
// // //             Historial de Pagos
// // //           </h1>
// // //           <div className="flex space-x-4">
// // //             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
// // //               <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-[#803f98] text-2xl mr-3" />
// // //               <div>
// // //                 <p className="text-sm text-gray-600">Total de cobros</p>
// // //                 <p className="text-xl font-bold text-[#803f98]">{totalCobros}</p>
// // //               </div>
// // //             </div>
// // //             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
// // //               <FontAwesomeIcon icon={faMoneyBillWave} className="text-[#f5831f] text-2xl mr-3" />
// // //               <div>
// // //                 <p className="text-sm text-gray-600">Total recaudado</p>
// // //                 <p className="text-xl font-bold text-[#f5831f]">${totalRecaudado.toFixed(2)}</p>
// // //               </div>
// // //             </div>
// // //           </div>
// // //         </div>
        
// // //         <div className="mb-6">
// // //           <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
// // //             <div className="flex items-center space-x-4">
// // //               <label htmlFor="service-filter" className="text-lg font-semibold text-[#803f98]">Filtrar por servicio:</label>
// // //               <div className="relative">
// // //                 <select
// // //                   id="service-filter"
// // //                   value={filter}
// // //                   onChange={(e) => setFilter(e.target.value)}
// // //                   className="appearance-none bg-white border border-[#803f98] text-[#803f98] py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-[#f5831f] transition duration-300 ease-in-out"
// // //                 >
// // //                   <option value="all">Todos los servicios</option>
// // //                   <option value="internet">Internet</option>
// // //                   <option value="tv">TV</option>
                  





// // //                   </select>
// // //                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-[#803f98]">
// // //                   <FontAwesomeIcon icon={faChevronDown} />
// // //                 </div>
// // //               </div>
// // //             </div>
// // //             <div className="flex space-x-2">
// // //               <input
// // //                 type="date"
// // //                 value={selectedDate}
// // //                 onChange={(e) => setSelectedDate(e.target.value)}
// // //                 className="border border-[#803f98] rounded px-2 py-1"
// // //               />
// // //               <button
// // //                 onClick={handleDateChange}
// // //                 className="bg-[#803f98] text-white font-bold py-1 px-2 rounded-md hover:bg-[#6a2f7a] focus:outline-none focus:ring-2 focus:ring-[#803f98] inline-flex items-center"
// // //               >
// // //                 <FontAwesomeIcon icon={faCalendar} className="mr-1" />
// // //                 Aplicar Fecha
// // //               </button>
// // //               <button
// // //                 onClick={generateReport}
// // //                 className="bg-[#f5831f] text-white font-bold py-1 px-2 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f] inline-flex items-center"
// // //               >
// // //                 <FontAwesomeIcon icon={faFileExport} className="mr-1" />
// // //                 Generar Reporte
// // //               </button>
// // //             </div>
// // //           </div>
// // //         </div>
        
// // //         {noDataMessage ? (
// // //           <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
// // //             <p className="font-bold">{noDataMessage}</p>
// // //           </div>
// // //         ) : (
// // //           <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg mb-8 overflow-x-auto">
// // //             <table className="w-full">
// // //               <thead>
// // //                 <tr className="bg-[#803f98] text-white">
// // //                   <th className="p-2">#</th>
// // //                   <th className="p-2">Fecha</th>
// // //                   <th className="p-2">RUC/Cédula</th>
// // //                   <th className="p-2">Cliente</th>
// // //                   <th className="p-2">Servicio</th>
// // //                   <th className="p-2">Total</th>
// // //                   <th className="p-2">Acciones</th>
// // //                 </tr>
// // //               </thead>
// // //               <tbody>
// // //                 {filteredPayments.map((payment) => (
// // //                   <React.Fragment key={payment.cod_fac}>
// // //                     <tr className={`border-b ${payment.type === 'internet' ? 'bg-purple-100/30' : 'bg-orange-100/30'}`}>
// // //                       <td className="p-2">{payment.cod_fac}</td>
// // //                       <td className="p-2">{new Date(payment.fecha).toLocaleDateString()}</td>
// // //                       <td className="p-2">{payment.ruc || 'N/A'}</td>
// // //                       <td className="p-2">{payment.nombre_cliente || 'N/A'}</td>
// // //                       <td className="p-2">{payment.type === 'internet' ? 'Internet' : 'TV'}</td>
// // //                       <td className="p-2">${payment.total.toFixed(2)}</td>
// // //                       <td className="p-2 space-x-2">
// // //                         <button
// // //                           onClick={() => generateThermalPrint(payment)}
// // //                           className="bg-[#f5831f] text-white font-bold py-1 px-2 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f] inline-flex items-center"
// // //                         >
// // //                           <FontAwesomeIcon icon={faPrint} className="mr-1" />
// // //                           Térmica
// // //                         </button>
// // //                         <button
// // //                           onClick={() => generateInvoicePDF(payment)}
// // //                           className="bg-[#803f98] text-white font-bold py-1 px-2 rounded-md hover:bg-[#6a2f7a] focus:outline-none focus:ring-2 focus:ring-[#803f98] inline-flex items-center"
// // //                         >
// // //                           <FontAwesomeIcon icon={faReceipt} className="mr-1" />
// // //                           Factura
// // //                         </button>
// // //                         <button
// // //                           onClick={() => toggleExpand(payment.cod_fac)}
// // //                           className="bg-gray-200 text-gray-700 font-bold py-1 px-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 inline-flex items-center"
// // //                         >
// // //                           <FontAwesomeIcon icon={expandedPayment === payment.cod_fac ? faChevronUp : faChevronDown} />
// // //                         </button>
// // //                       </td>
// // //                     </tr>
// // //                     {expandedPayment === payment.cod_fac && (
// // //                       <tr>
// // //                         <td colSpan="7">
// // //                           <div className="p-2 bg-gray-200 rounded-md">
// // //                             <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
// // //                               <div>
// // //                                 <p><strong>Dirección:</strong> {payment.direccion || 'N/A'}</p>
// // //                                 <p><strong>Teléfono:</strong> {payment.telefono || 'N/A'}</p>
// // //                                 <p><strong>Celular:</strong> {payment.celular || 'N/A'}</p>
// // //                               </div>
// // //                               <div>
// // //                                 <p><strong>Contrato:</strong> {payment.contrato || 'N/A'}</p>
// // //                                 <p><strong>Código de Cliente:</strong> {payment.cod_cliente || 'N/A'}</p>
// // //                                 <p><strong>Código de Documento:</strong> {payment.cod_doc || 'N/A'}</p>
// // //                               </div>
// // //                             </div>
// // //                             <h5 className="font-bold mt-2 mb-1">Detalles del servicio:</h5>
// // //                             <table className="w-full text-sm">
// // //                               <thead>
// // //                                 <tr className="bg-[#803f98] text-white">
// // //                                   <th className="p-1">Descripción</th>
// // //                                   <th className="p-1">Cantidad</th>
// // //                                   <th className="p-1">Valor</th>
// // //                                 </tr>
// // //                               </thead>
// // //                               <tbody>
// // //                                 {payment.informacion_facturas_d.map((detalle, index) => (
// // //                                   <tr key={index} className="border-b">
// // //                                     <td className="p-1">{detalle.descripcion}</td>
// // //                                     <td className="p-1">{detalle.cantidad}</td>
// // //                                     <td className="p-1">${detalle.valor_t.toFixed(2)}</td>
// // //                                   </tr>
// // //                                 ))}
// // //                               </tbody>
// // //                             </table>
// // //                           </div>
// // //                         </td>
// // //                       </tr>
// // //                     )}
// // //                   </React.Fragment>
// // //                 ))}
// // //               </tbody>
// // //             </table>
// // //           </div>
// // //         )}
// // //       </div>
// // //       <Footer />
// // //     </div>
// // //   );
// // // };

// // // export default PaymentHistory;



// // import React, { useState, useEffect } from 'react';
// // import { useNavigate } from 'react-router-dom';
// // import Header from '../components/header';
// // import Footer from '../components/footer';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faPrint, faReceipt, faChevronDown, faChevronUp, faGlobe, faTv, faList, faMoneyBillWave, faFileInvoiceDollar, faCalendar, faFileExport } from '@fortawesome/free-solid-svg-icons';
// // import { jsPDF } from "jspdf";
// // import 'jspdf-autotable';
// // import apiService from '../services/apiService';
// // import logo from '../assets/logo.png';
// // import InvoiceGenerator from './InvoiceGenerator';

// // const PaymentHistory = () => {
// //   const [payments, setPayments] = useState([]);
// //   const [filteredPayments, setFilteredPayments] = useState([]);
// //   const [expandedPayment, setExpandedPayment] = useState(null);
// //   const [isLoading, setIsLoading] = useState(true);
// //   const [filter, setFilter] = useState('all');
// //   const [totalCobros, setTotalCobros] = useState(0);
// //   const [totalRecaudado, setTotalRecaudado] = useState(0);
// //   const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
// //   const [noDataMessage, setNoDataMessage] = useState('');
// //   const navigate = useNavigate();

// //   const invoiceGenerator = InvoiceGenerator();

// //   useEffect(() => {
// //     fetchPayments();
// //   }, []);

// //   const fetchPayments = async () => {
// //     try {
// //       const storedUser = localStorage.getItem("user");
// //       if (!storedUser) {
// //         navigate('/login');
// //         return;
// //       }

// //       const user = JSON.parse(storedUser);
// //       if (!user || !user.sucursal) {
// //         throw new Error('Sucursal no encontrada');
// //       }

// //       const branch = user.sucursal;
// //       const response = await apiService.getCobrosRealizados(branch, selectedDate);

// //       if (response.status === 204 || !response.data) {
// //         setNoDataMessage('No se encontraron cobros para la fecha seleccionada');
// //         setPayments([]);
// //         setFilteredPayments([]);
// //         setTotalCobros(0);
// //         setTotalRecaudado(0);
// //       } else {
// //         const allPayments = [
// //           ...(response.data.int || []).map(p => ({ ...p, type: 'internet' })),
// //           ...(response.data.tv || []).map(p => ({ ...p, type: 'tv' }))
// //         ];

// //         setPayments(allPayments);
// //         applyFilters(allPayments);
// //         setNoDataMessage('');
// //       }
// //     } catch (error) {
// //       console.error('Error fetching payments:', error);
// //       if (error.response && error.response.status === 401) {
// //         navigate('/login');
// //       }
// //     } finally {
// //       setIsLoading(false);
// //     }
// //   };

// //   const applyFilters = (paymentsToFilter) => {
// //     let filtered = paymentsToFilter;
// //     if (filter !== 'all') {
// //       filtered = filtered.filter(p => p.type === filter);
// //     }
// //     setFilteredPayments(filtered);
// //     setTotalCobros(filtered.length);
// //     setTotalRecaudado(filtered.reduce((sum, p) => sum + p.total, 0));
// //   };

// //   useEffect(() => {
// //     applyFilters(payments);
// //   }, [filter, payments]);

// //   const handleDateChange = async () => {
// //     setIsLoading(true);
// //     await fetchPayments();
// //   };

// //   const generateReport = () => {
// //     const doc = new jsPDF();
// //     const pageWidth = doc.internal.pageSize.width;
// //     const pageHeight = doc.internal.pageSize.height;
    
// //     // Add logo
// //     const logoWidth = 60;
// //     const logoHeight = 12.24;
// //     doc.addImage(logo, 'PNG', (pageWidth - logoWidth) / 2, 10, logoWidth, logoHeight);
    
// //     // Add title
// //     doc.setFontSize(18);
// //     doc.text('Reporte de Cobros', pageWidth / 2, 40, { align: 'center' });
    
// //     // Add date and user info
// //     const userInitial = localStorage.getItem('inicial') || '';
// //     const userName = JSON.parse(localStorage.getItem('user') || '{}').nombre_usuario || '';
// //     doc.setFontSize(12);
// //     doc.text(`Fecha: ${selectedDate}`, 20, 50);
// //     doc.text(`Usuario: ${userInitial} - ${userName}`, 20, 57);
    
// //     // Add summary
// //     doc.text(`Total de cobros: ${totalCobros}`, 20, 67);
// //     doc.text(`Total recaudado: $${totalRecaudado.toFixed(2)}`, 20, 74);
    
// //     // Add table
// //     doc.autoTable({
// //       startY: 80,
// //       head: [['# COMP.', 'Fecha', 'RUC/CED', 'Cliente', 'Servicio', 'Total']],
// //       body: filteredPayments.map(p => [
// //         `${userInitial}`+p.cod_fac,
// //         new Date(p.fecha).toLocaleDateString(),
// //         p.ruc || 'N/A',
// //         p.nombre_cliente || 'N/A',
// //         p.type === 'internet' ? 'Internet' : 'TV',
// //         `$${p.total.toFixed(2)}`
// //       ]),
// //     });
    
// //     doc.save(`reporte_cobros_${selectedDate}.pdf`);
// //   };

// //   const handleGenerateInvoice = (payment) => {
// //     invoiceGenerator.generateInvoicePDF(payment);
// //   };

// //   const handleGenerateThermalInvoice = (payment) => {
// //     invoiceGenerator.generateThermalPrint(payment);
// //   };

// //   const toggleExpand = (id) => {
// //     setExpandedPayment(expandedPayment === id ? null : id);
// //   };

// //   if (isLoading) {
// //     return (
// //       <div className="flex items-center justify-center min-h-screen bg-[#FBFEF7]">
// //         <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#803f98]"></div>
// //       </div>
// //     );
// //   }



  
// //   return (
// //     <div className="flex flex-col min-h-screen font-poppins bg-[#FBFEF7]">
// //       <Header />
// //       <div className="h-1 bg-[#f5831f]"></div>
// //       <div className="flex-grow flex flex-col p-6 md:p-12">
// //         <div className="flex flex-col md:flex-row justify-between items-center mb-8">
// //           <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-4 md:mb-0">
// //             Historial de Pagos
// //           </h1>
// //           <div className="flex space-x-4">
// //             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
// //               <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-[#803f98] text-2xl mr-3" />
// //               <div>
// //                 <p className="text-sm text-gray-600">Total de cobros</p>
// //                 <p className="text-xl font-bold text-[#803f98]">{totalCobros}</p>
// //               </div>
// //             </div>
// //             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
// //               <FontAwesomeIcon icon={faMoneyBillWave} className="text-[#f5831f] text-2xl mr-3" />
// //               <div>
// //                 <p className="text-sm text-gray-600">Total recaudado</p>
// //                 <p className="text-xl font-bold text-[#f5831f]">${totalRecaudado.toFixed(2)}</p>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
        
// //         <div className="mb-6">
// //           <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
// //             <div className="flex items-center space-x-4">
// //               <label htmlFor="service-filter" className="text-lg font-semibold text-[#803f98]">Filtrar por servicio:</label>
// //               <div className="relative">
// //                 <select
// //                   id="service-filter"
// //                   value={filter}
// //                   onChange={(e) => setFilter(e.target.value)}
// //                   className="appearance-none bg-white border border-[#803f98] text-[#803f98] py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-[#f5831f] transition duration-300 ease-in-out"
// //                 >
// //                   <option value="all">Todos los servicios</option>
// //                   <option value="internet">Internet</option>
// //                   <option value="tv">TV</option>
// //                 </select>
// //                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-[#803f98]">
// //                   <FontAwesomeIcon icon={faChevronDown} />
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="flex space-x-2">
// //               <input
// //                 type="date"
// //                 value={selectedDate}
// //                 onChange={(e) => setSelectedDate(e.target.value)}
// //                 className="border border-[#803f98] rounded px-2 py-1"
// //               />
// //               <button
// //                 onClick={handleDateChange}
// //                 className="bg-[#803f98] text-white font-bold py-1 px-2 rounded-md hover:bg-[#6a2f7a] focus:outline-none focus:ring-2 focus:ring-[#803f98] inline-flex items-center"
// //               >
// //                 <FontAwesomeIcon icon={faCalendar} className="mr-1" />
// //                 Aplicar Fecha
// //               </button>
// //               <button
// //                 onClick={generateReport}
// //                 className="bg-[#f5831f] text-white font-bold py-1 px-2 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f] inline-flex items-center"
// //               >
// //                 <FontAwesomeIcon icon={faFileExport} className="mr-1" />
// //                 Generar Reporte
// //               </button>
// //             </div>
// //           </div>
// //         </div>
        
// //         {noDataMessage ? (
// //           <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
// //             <p className="font-bold">{noDataMessage}</p>
// //           </div>
// //         ) : (
// //           <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg mb-8 overflow-x-auto">
// //             <table className="w-full">
// //               <thead>
// //                 <tr className="bg-[#803f98] text-white">
// //                   <th className="p-2">#</th>
// //                   <th className="p-2">Fecha</th>
// //                   <th className="p-2">RUC/Cédula</th>
// //                   <th className="p-2">Cliente</th>
// //                   <th className="p-2">Servicio</th>
// //                   <th className="p-2">Total</th>
// //                   <th className="p-2">Acciones</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {filteredPayments.map((payment) => (
// //                   <React.Fragment key={payment.cod_fac}>
// //                     <tr className={`border-b ${payment.type === 'internet' ? 'bg-purple-100/30' : 'bg-orange-100/30'}`}>
// //                       <td className="p-2">{payment.cod_fac}</td>
// //                       <td className="p-2">{new Date(payment.fecha).toLocaleDateString()}</td>
// //                       <td className="p-2">{payment.ruc || 'N/A'}</td>
// //                       <td className="p-2">{payment.nombre_cliente || 'N/A'}</td>
// //                       <td className="p-2">{payment.type === 'internet' ? 'Internet' : 'TV'}</td>
// //                       <td className="p-2">${payment.total.toFixed(2)}</td>
// //                       <td className="p-2 space-x-2">
// //                         <button
// //                           onClick={() => handleGenerateThermalInvoice(payment)}
// //                           className="bg-[#f5831f] text-white font-bold py-1 px-2 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f] inline-flex items-center"
// //                         >
// //                           <FontAwesomeIcon icon={faPrint} className="mr-1" />
// //                           Térmica
// //                         </button>
// //                         <button
// //                           onClick={() => handleGenerateInvoice(payment)}
// //                           className="bg-[#803f98] text-white font-bold py-1 px-2 rounded-md hover:bg-[#6a2f7a] focus:outline-none focus:ring-2 focus:ring-[#803f98] inline-flex items-center"
// //                         >
// //                           <FontAwesomeIcon icon={faReceipt} className="mr-1" />
// //                           Factura
// //                         </button>
// //                         <button
// //                           onClick={() => toggleExpand(payment.cod_fac)}
// //                           className="bg-gray-200 text-gray-700 font-bold py-1 px-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 inline-flex items-center"
// //                         >
// //                           <FontAwesomeIcon icon={expandedPayment === payment.cod_fac ? faChevronUp : faChevronDown} />
// //                         </button>
// //                       </td>
// //                     </tr>
// //                     {expandedPayment === payment.cod_fac && (
// //                       <tr>
// //                         <td colSpan="7">
// //                           <div className="p-2 bg-gray-200 rounded-md">
// //                             <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
// //                               <div>
// //                                 <p><strong>Dirección:</strong> {payment.direccion || 'N/A'}</p>
// //                                 <p><strong>Teléfono:</strong> {payment.telefono || 'N/A'}</p>
// //                                 <p><strong>Celular:</strong> {payment.celular || 'N/A'}</p>
// //                               </div>
// //                               <div>
// //                                 <p><strong>Contrato:</strong> {payment.contrato || 'N/A'}</p>
// //                                 <p><strong>Código de Cliente:</strong> {payment.cod_cliente || 'N/A'}</p>
// //                                 <p><strong>Código de Documento:</strong> {payment.cod_doc || 'N/A'}</p>
// //                               </div>
// //                             </div>
// //                             <h5 className="font-bold mt-2 mb-1">Detalles del servicio:</h5>
// //                             <table className="w-full text-sm">
// //                               <thead>
// //                                 <tr className="bg-[#803f98] text-white">
// //                                   <th className="p-1">Descripción</th>
// //                                   <th className="p-1">Cantidad</th>
// //                                   <th className="p-1">Valor</th>
// //                                 </tr>
// //                               </thead>
// //                               <tbody>
// //                                 {payment.informacion_facturas_d && payment.informacion_facturas_d.map((detalle, index) => (
// //                                   <tr key={index} className="border-b">
// //                                     <td className="p-1">{detalle.descripcion}</td>
// //                                     <td className="p-1">{detalle.cantidad}</td>
// //                                     <td className="p-1">${detalle.valor_t.toFixed(2)}</td>
// //                                   </tr>
// //                                 ))}
// //                                 {(!payment.informacion_facturas_d || payment.informacion_facturas_d.length === 0) && (
// //                                   <tr>
// //                                     <td colSpan="3" className="p-1 text-center">No hay detalles disponibles</td>
// //                                   </tr>
// //                                 )}
// //                               </tbody>
// //                             </table>
// //                           </div>
// //                         </td>
// //                       </tr>
// //                     )}
// //                   </React.Fragment>
// //                 ))}
// //               </tbody>
// //             </table>
// //           </div>
// //         )}
// //       </div>
// //       <Footer />
// //     </div>
// //   );
// // };

// // export default PaymentHistory;



// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Header from '../components/header';
// import Footer from '../components/footer';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPrint, faReceipt, faChevronDown, faChevronUp, faGlobe, faTv, faList, faMoneyBillWave, faFileInvoiceDollar, faCalendar, faFileExport, faBan, faCheckCircle, faCoins } from '@fortawesome/free-solid-svg-icons';
// import { jsPDF } from "jspdf";
// import 'jspdf-autotable';
// import apiService from '../services/apiService';
// import logo from '../assets/logo.png';
// import InvoiceGenerator from './InvoiceGenerator';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// const PaymentHistory = () => {
//   const [payments, setPayments] = useState([]);
//   const [filteredPayments, setFilteredPayments] = useState([]);
//   const [expandedPayment, setExpandedPayment] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [filter, setFilter] = useState('all');
//   const [totalCobros, setTotalCobros] = useState(0);
//   const [totalRecaudado, setTotalRecaudado] = useState(0);
//   const [totalMesesFacturados, setTotalMesesFacturados] = useState(0);
//   const [totalAnulados, setTotalAnulados] = useState(0);
//   const [totalNoAnulados, setTotalNoAnulados] = useState(0);
//   const [dateRange, setDateRange] = useState([new Date(), new Date()]);
//   const [startDate, endDate] = dateRange;
//   const [noDataMessage, setNoDataMessage] = useState('');
//   const navigate = useNavigate();

//   const invoiceGenerator = InvoiceGenerator();

//   useEffect(() => {
//     fetchPayments();
//   }, []);

//   const fetchPayments = async () => {
//     try {
//       const storedUser = localStorage.getItem("user");
//       if (!storedUser) {
//         navigate('/login');
//         return;
//       }

//       const user = JSON.parse(storedUser);
//       if (!user || !user.sucursal) {
//         throw new Error('Sucursal no encontrada');
//       }

//       const branch = user.sucursal;
//       const response = await apiService.getCobrosRealizados(
//         branch, 
//         startDate.toISOString().split('T')[0], 
//         endDate.toISOString().split('T')[0]
//       );

//       if (response.status === 204 || !response.data) {
//         setNoDataMessage('No se encontraron cobros para el rango de fechas seleccionado');
//         setPayments([]);
//         setFilteredPayments([]);
//         resetTotals();
//       } else {
//         const allPayments = [
//           ...(response.data.int || []).map(p => ({ ...p, type: 'internet' })),
//           ...(response.data.tv || []).map(p => ({ ...p, type: 'tv' }))
//         ];

//         setPayments(allPayments);
//         applyFilters(allPayments);
//         setNoDataMessage('');
//       }
//     } catch (error) {
//       console.error('Error fetching payments:', error);
//       if (error.response && error.response.status === 401) {
//         navigate('/login');
//       }
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const resetTotals = () => {
//     setTotalCobros(0);
//     setTotalRecaudado(0);
//     setTotalMesesFacturados(0);
//     setTotalAnulados(0);
//     setTotalNoAnulados(0);
//   };

//   const applyFilters = (paymentsToFilter) => {
//     let filtered = paymentsToFilter;
//     if (filter !== 'all') {
//       filtered = filtered.filter(p => p.type === filter);
//     }
//     setFilteredPayments(filtered);
//     calculateTotals(filtered);
//   };

//   const calculateTotals = (filtered) => {
//     setTotalCobros(filtered.length);
//     setTotalRecaudado(filtered.reduce((sum, p) => sum + p.total, 0));
//     setTotalMesesFacturados(filtered.reduce((sum, p) => sum + p.meses, 0));
//     setTotalAnulados(filtered.filter(p => p.anulado !== 0).length);
//     setTotalNoAnulados(filtered.filter(p => p.anulado === 0).length);
//   };

//   useEffect(() => {
//     applyFilters(payments);
//   }, [filter, payments]);

//   const handleDateChange = async (update) => {
//     setDateRange(update);
//   };

//   const handleApplyDateFilter = async () => {
//     setIsLoading(true);
//     await fetchPayments();
//   };

//   const generateReport = () => {
//     const doc = new jsPDF();
//     const pageWidth = doc.internal.pageSize.width;
//     const pageHeight = doc.internal.pageSize.height;
    
//     // Add logo
//     const logoWidth = 60;
//     const logoHeight = 12.24;
//     doc.addImage(logo, 'PNG', (pageWidth - logoWidth) / 2, 10, logoWidth, logoHeight);
    
//     // Add title
//     doc.setFontSize(18);
//     doc.text('Reporte de Cobros', pageWidth / 2, 40, { align: 'center' });
    
//     // Add date range and user info
//     const userInitial = localStorage.getItem('inicial') || '';
//     const userName = JSON.parse(localStorage.getItem('user') || '{}').nombre_usuario || '';
//     doc.setFontSize(12);
//     doc.text(`Rango de fechas: ${startDate.toLocaleDateString()} a ${endDate.toLocaleDateString()}`, 20, 50);
//     doc.text(`Usuario: ${userInitial} - ${userName}`, 20, 57);
    
//     // Add summary
//     doc.text(`Total de cobros: ${totalCobros}`, 20, 67);
//     doc.text(`Total recaudado: $${totalRecaudado.toFixed(2)}`, 20, 74);
//     doc.text(`Total meses facturados: ${totalMesesFacturados.toFixed(2)}`, 20, 81);
//     doc.text(`Anulados: ${totalAnulados}`, 20, 88);
//     doc.text(`No anulados: ${totalNoAnulados}`, 20, 95);
//     doc.text(`Total a comisionar: $${(totalNoAnulados * 0.30).toFixed(2)}`, 20, 102);
    
//     // Add table
//     doc.autoTable({
//       startY: 110,
//       head: [['# COMP.', 'Fecha', 'RUC/CED', 'Cliente', 'Servicio', 'Meses', 'Total', 'Estado']],
//       body: filteredPayments.map(p => [
//         `${userInitial}${p.cod_fac}`,
//         new Date(p.fecha).toLocaleDateString(),
//         p.ruc || 'N/A',
//         p.nombre_cliente || 'N/A',
//         p.type === 'internet' ? 'Internet' : 'TV',
//         p.meses.toFixed(2),
//         `$${p.total.toFixed(2)}`,
//         p.anulado === 0 ? 'Aprobado' : 'Anulado'
//       ]),
//     });
    
//     doc.save(`reporte_cobros_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}.pdf`);
//   };

//   const handleGenerateInvoice = (payment) => {
//     invoiceGenerator.generateInvoicePDF(payment);
//   };

//   const handleGenerateThermalInvoice = (payment) => {
//     invoiceGenerator.generateThermalPrint(payment);
//   };

//   const toggleExpand = (id) => {
//     setExpandedPayment(expandedPayment === id ? null : id);
//   };

//   if (isLoading) {
//     return (
//       <div className="flex items-center justify-center min-h-screen bg-[#FBFEF7]">
//         <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#803f98]"></div>
//       </div>
//     );
//   }

//   return (
//     <div className="flex flex-col min-h-screen font-poppins bg-[#FBFEF7]">
//       <Header />
//       <div className="h-1 bg-[#f5831f]"></div>
//       <div className="flex-grow flex flex-col p-6 md:p-12">
//         <div className="flex flex-col md:flex-row justify-between items-center mb-8">
//           <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-4 md:mb-0">
//             Informe de cobros
//           </h1>
//           <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
//             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
//               <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-[#803f98] text-2xl mr-3" />
//               <div>
//                 <p className="text-sm text-gray-600">Total de cobros</p>
//                 <p className="text-xl font-bold text-[#803f98]">{totalCobros}</p>
//               </div>
//             </div>
//             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
//               <FontAwesomeIcon icon={faMoneyBillWave} className="text-[#f5831f] text-2xl mr-3" />
//               <div>
//                 <p className="text-sm text-gray-600">Total recaudado</p>
//                 <p className="text-xl font-bold text-[#f5831f]">${totalRecaudado.toFixed(2)}</p>
//               </div>
//             </div>
//             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
//               <FontAwesomeIcon icon={faCalendar} className="text-[#803f98] text-2xl mr-3" />
//               <div>
//                 <p className="text-sm text-gray-600">Cantidad a comision</p>
//                 <p className="text-xl font-bold text-[#803f98]">{totalMesesFacturados.toFixed(2)}</p>
//               </div>
//             </div>
//             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
//               <FontAwesomeIcon icon={faBan} className="text-red-500 text-2xl mr-3" />
//               <div>
//                 <p className="text-sm text-gray-600">Anulados</p>
//                 <p className="text-xl font-bold text-red-500">{totalAnulados}</p>
//               </div>
//             </div>
//             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
//               <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-2xl mr-3" />
//               <div>
//                 <p className="text-sm text-gray-600">Aprobados</p>
//                 <p className="text-xl font-bold text-green-500">{totalNoAnulados}</p>
//               </div>
//             </div>
//             <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
//               <FontAwesomeIcon icon={faCoins} className="text-[#f5831f] text-2xl mr-3" />
//               <div>
//                 <p className="text-sm text-gray-600">Total a Comisionar</p>
//                 <p className="text-xl font-bold text-[#f5831f]">${(totalNoAnulados * 0.30).toFixed(2)}</p>
//               </div>
//             </div>
//           </div>
//         </div>
        
//         <div className="mb-6">
//           <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
//             <div className="flex items-center space-x-4">
//               <label htmlFor="service-filter" className="text-lg font-semibold text-[#803f98]">Filtrar por servicio:</label>
//               <div className="relative">
//                 <select
//                   id="service-filter"
//                   value={filter}
//                   onChange={(e) => setFilter(e.target.value)}
//                   className="appearance-none bg-white border border-[#803f98] text-[#803f98] py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-[#f5831f] transition duration-300 ease-in-out"
//                 >
//                   <option value="all">Todos los servicios</option>
//                   <option value="internet">Internet</option>
//                   <option value="tv">TV</option>
//                 </select>
//                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-[#803f98]">
//                   <FontAwesomeIcon icon={faChevronDown} />
//                 </div>
//               </div>
//             </div>
//             <div className="flex space-x-2">
//               <DatePicker
//                 selectsRange={true}
//                 startDate={startDate}
//                 endDate={endDate}
//                 onChange={handleDateChange}
//                 className="border border-[#803f98] rounded px-2 py-1"
//                 dateFormat="yyyy-MM-dd"
//               />
//               <button
//                 onClick={handleApplyDateFilter}
//                 className="bg-[#803f98] text-white font-bold py-1 px-2 rounded-md hover:bg-[#6a2f7a] focus:outline-none focus:ring-2 focus:ring-[#803f98] inline-flex items-center"
//               >
//                 <FontAwesomeIcon icon={faCalendar} className="mr-1" />
//                 Aplicar Fechas
//               </button>
//               <button
//                 onClick={generateReport}
//                 className="bg-[#f5831f] text-white font-bold py-1 px-2 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f] inline-flex items-center"
//               >
//                 <FontAwesomeIcon icon={faFileExport} className="mr-1" />
//                 Generar Reporte
//               </button>
//             </div>
//           </div>
//         </div>
        
//                {noDataMessage ? (
//             <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
//               <p className="font-bold">{noDataMessage}</p>
//             </div>
//           ) : (
//             <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg mb-8 overflow-x-auto">
//               <table className="w-full">
//                 <thead>
//                   <tr className="bg-[#803f98] text-white">
//                     <th className="p-2">#</th>
//                     <th className="p-2">Fecha</th>
//                     <th className="p-2">RUC/Cédula</th>
//                     <th className="p-2">Cliente</th>
//                     <th className="p-2">Servicio</th>
//                     <th className="p-2">Meses</th>
//                     <th className="p-2">Total</th>
//                     <th className="p-2">Estado</th>
//                     <th className="p-2">Acciones</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {filteredPayments.map((payment) => (
//                     <React.Fragment key={payment.cod_fac}>
//                       <tr className={`border-b ${payment.type === 'internet' ? 'bg-purple-100/30' : 'bg-orange-100/30'}`}>
//                         <td className="p-2">{payment.cod_fac}</td>
//                         <td className="p-2">{new Date(payment.fecha).toLocaleDateString()}</td>
//                         <td className="p-2">{payment.ruc || 'N/A'}</td>
//                         <td className="p-2">{payment.nombre_cliente || 'N/A'}</td>
//                         <td className="p-2">{payment.type === 'internet' ? 'Internet' : 'TV'}</td>
//                         <td className="p-2">{payment.meses.toFixed(2)}</td>
//                         <td className="p-2">${payment.total.toFixed(2)}</td>
//                         <td className="p-2">{payment.anulado === 0 ? 'Aprobado' : 'Anulado'}</td>
//                         <td className="p-2 space-x-2">
//                           <button
//                             onClick={() => handleGenerateThermalInvoice(payment)}
//                             className="bg-[#f5831f] text-white font-bold py-1 px-2 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f] inline-flex items-center"
//                           >
//                             <FontAwesomeIcon icon={faPrint} className="mr-1" />
//                             Térmica
//                           </button>
//                           <button
//                             onClick={() => handleGenerateInvoice(payment)}
//                             className="bg-[#803f98] text-white font-bold py-1 px-2 rounded-md hover:bg-[#6a2f7a] focus:outline-none focus:ring-2 focus:ring-[#803f98] inline-flex items-center"
//                           >
//                             <FontAwesomeIcon icon={faReceipt} className="mr-1" />
//                             Factura
//                           </button>
//                           <button
//                             onClick={() => toggleExpand(payment.cod_fac)}
//                             className="bg-gray-200 text-gray-700 font-bold py-1 px-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 inline-flex items-center"
//                           >
//                             <FontAwesomeIcon icon={expandedPayment === payment.cod_fac ? faChevronUp : faChevronDown} />
//                           </button>
//                         </td>
//                       </tr>
//                       {expandedPayment === payment.cod_fac && (
//                         <tr>
//                           <td colSpan="9">
//                             <div className="p-2 bg-gray-200 rounded-md">
//                               <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
//                                 <div>
//                                   <p><strong>Dirección:</strong> {payment.direccion || 'N/A'}</p>
//                                   <p><strong>Teléfono:</strong> {payment.telefono || 'N/A'}</p>
//                                   <p><strong>Celular:</strong> {payment.celular || 'N/A'}</p>
//                                 </div>
//                                 <div>
//                                   <p><strong>Contrato:</strong> {payment.contrato || 'N/A'}</p>
//                                   <p><strong>Código de Cliente:</strong> {payment.cod_cliente || 'N/A'}</p>
//                                   <p><strong>Código de Documento:</strong> {payment.cod_doc || 'N/A'}</p>
//                                 </div>
//                               </div>
//                               <h5 className="font-bold mt-2 mb-1">Detalles del servicio:</h5>
//                               <table className="w-full text-sm">
//                                 <thead>
//                                   <tr className="bg-[#803f98] text-white">
//                                     <th className="p-1">Descripción</th>
//                                     <th className="p-1">Cantidad</th>
//                                     <th className="p-1">Valor</th>
                                    
//                                   </tr>
//                                 </thead>
//                                 <tbody>
//                                   {payment.informacion_facturas_d && payment.informacion_facturas_d.map((detalle, index) => (
//                                     <tr key={index} className="border-b">
//                                       <td className="p-1">{detalle.descripcion}</td>
//                                       <td className="p-1">{detalle.cantidad}</td>
//                                       <td className="p-1">${detalle.valor_t.toFixed(2)}</td>
//                                     </tr>
//                                   ))}
//                                   {(!payment.informacion_facturas_d || payment.informacion_facturas_d.length === 0) && (
//                                     <tr>
//                                       <td colSpan="3" className="p-1 text-center">No hay detalles disponibles</td>
//                                     </tr>
//                                   )}
//                                 </tbody>
//                               </table>
//                             </div>
//                           </td>
//                         </tr>
//                       )}
//                     </React.Fragment>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           )}
//         </div>
//         <Footer />
//       </div>
//     );
//   };
  
//   export default PaymentHistory;








import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faReceipt, faChevronDown, faChevronUp, faGlobe, faTv, faList, faMoneyBillWave, faFileInvoiceDollar, faCalendar, faFileExport, faBan, faCheckCircle, faCoins } from '@fortawesome/free-solid-svg-icons';




import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import apiService from '../services/apiService';
import logo from '../assets/logo.png';
import InvoiceGenerator from './InvoiceGenerator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PaymentHistory = () => {
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [expandedPayment, setExpandedPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [totalCobros, setTotalCobros] = useState(0);
  const [totalRecaudado, setTotalRecaudado] = useState(0);
  const [cantidadComision, setCantidadComision] = useState(0);
  const [totalPendientes, setTotalPendientes] = useState(0);
  const [totalAprobados, setTotalAprobados] = useState(0);

  const [totalAnulados, setTotalAnulados] = useState(0);

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [noDataMessage, setNoDataMessage] = useState('');
  const navigate = useNavigate();

  const invoiceGenerator = InvoiceGenerator();

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    try {
      const storedUser = localStorage.getItem("user");
      if (!storedUser) {
        navigate('/login');
        return;
      }

      const user = JSON.parse(storedUser);
      if (!user || !user.sucursal) {
        throw new Error('Sucursal no encontrada');
      }

      const branch = user.sucursal;
      const response = await apiService.getCobrosRealizados(
        branch, 
        startDate.toISOString().split('T')[0], 
        endDate.toISOString().split('T')[0]
      );

      if (response.status === 204 || !response.data) {
        setNoDataMessage('No se encontraron cobros para el rango de fechas seleccionado');
        setPayments([]);
        setFilteredPayments([]);
        resetTotals();
      } else {
        const allPayments = [
          ...(response.data.int || []).map(p => ({ ...p, type: 'internet' })),
          ...(response.data.tv || []).map(p => ({ ...p, type: 'tv' }))
        ];

        setPayments(allPayments);
        applyFilters(allPayments);
        setNoDataMessage('');
      }
    } catch (error) {
      console.error('Error fetching payments:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetTotals = () => {
    setTotalCobros(0);
    setTotalRecaudado(0);
    setCantidadComision(0);
    setTotalPendientes(0);
    setTotalAprobados(0);
  };

  const applyFilters = (paymentsToFilter) => {
    let filtered = paymentsToFilter;
    if (filter !== 'all') {
      filtered = filtered.filter(p => p.type === filter);
    }
    setFilteredPayments(filtered);
    calculateTotals(filtered);
  };

  const calculateTotals = (filtered) => {
    setTotalCobros(filtered.length);
    let recaudado = 0;
    let comision = 0;
    let pendientes = 0;
    let aprobados = 0;
    let anulados = 0;

    filtered.forEach(p => {
      if (p.anulado === 1) {
        anulados++;
      } else {
        recaudado += p.total;
        comision += p.meses;
        if (p.numero_doc === 0) {
          pendientes++;
        } else {
          aprobados++;
        }
      }
    });

    setTotalRecaudado(recaudado);
    setCantidadComision(comision);
    setTotalPendientes(pendientes);
    setTotalAprobados(aprobados);
    setTotalAnulados(anulados);
  };



  useEffect(() => {
    applyFilters(payments);
  }, [filter, payments]);

  const handleDateChange = async (update) => {
    setDateRange(update);
  };

  const handleApplyDateFilter = async () => {
    setIsLoading(true);
    await fetchPayments();
  };


  
const generateReport = () => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;

  // Funciones auxiliares
  const addPageNumber = (doc) => {
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(`Página 1 de 1`, pageWidth - 20, pageHeight - 10, { align: 'right' });
  };

  // Agregar logo y título
  const logoWidth = 60;
  const logoHeight = 12.24;
  doc.addImage(logo, 'PNG', (pageWidth - logoWidth) / 2, 10, logoWidth, logoHeight);
  
  doc.setTextColor(128, 63, 152); // Color morado
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  doc.text('Informe de Cobros', pageWidth / 2, 35, { align: 'center' });

  // Información general
  doc.setTextColor(0);
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  const userInitial = localStorage.getItem('inicial') || '';
  const userName = JSON.parse(localStorage.getItem('user') || '{}').nombre_usuario || '';
  
  doc.text(`Usuario: ${userInitial} - ${userName}`, 10, 45);
  doc.text(`Fecha del informe: ${new Date().toLocaleDateString()}`, 10, 53);
  doc.text(`Rango de fechas: ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`, 10, 61);

  // Resumen de cobros en formato de columnas
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.text('Resumen de Cobros', 10, 75);

  const columnWidth = pageWidth / 2;

  // Función para añadir un elemento del resumen
  const addSummaryItem = (label, value, x, y, highlight = false) => {
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(11);
    doc.setTextColor(0);
    doc.text(label, x, y);
    doc.setFont('helvetica', 'bold');
    if (highlight) {
      doc.setTextColor(0, 128, 0); // Verde para resaltar
      doc.setFontSize(14);
    }
    doc.text(value, x, y + 7);
    doc.setTextColor(0); // Restablecer color
    doc.setFontSize(11);
  };

  // Primera columna
  addSummaryItem('Total cobros', Math.round(totalCobros).toString(), 10, 85);
  addSummaryItem('Total recaudado', `$${(totalRecaudado)}`, 10, 100);
  addSummaryItem('Pendientes', Math.round(totalPendientes).toString(), 10, 115);

  // Segunda columna
  addSummaryItem('Aprobados', Math.round(totalAprobados).toString(), columnWidth + 10, 85);
  addSummaryItem('Anulados', Math.round(totalAnulados).toString(), columnWidth + 10, 100);
  addSummaryItem('Cantidad a comisión', cantidadComision.toFixed(2).toString(), columnWidth + 10, 115, true);

  // Tabla de cobros
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.text('Detalle de Cobros', 10, 130);

  const tableColumns = ['# COMP.', 'Servicio', 'Fecha', 'RUC/CED', 'Cliente', 'Meses', 'Total', 'Estado'];
  const tableData = filteredPayments.map(p => [
    p.cod_doc,
    p.type === 'internet' ? 'Internet' : 'TV',
    new Date(p.fecha).toLocaleDateString(),
    p.ruc || 'N/A',
    p.nombre_cliente || 'N/A',
    p.meses.toFixed(2), // Mostrar meses con dos decimales
    `$${p.total.toFixed(2)}`,
    p.anulado === 1 ? 'Anulado' : (p.numero_doc === 0 ? 'Pendiente' : 'Aprobado')
  ]);

  doc.autoTable({
    startY: 135,
    head: [tableColumns],
    body: tableData,
    theme: 'grid',
    styles: { fontSize: 8, cellPadding: 1 },
    headerStyles: { fillColor: [128, 63, 152], textColor: 255 },
    alternateRowStyles: { fillColor: [245, 245, 245] },
    columnStyles: {
      0: { cellWidth: 20 },
      1: { cellWidth: 20 },
      2: { cellWidth: 20 },
      3: { cellWidth: 25 },
      4: { cellWidth: 40 },
      5: { cellWidth: 15 },
      6: { cellWidth: 20 },
      7: { cellWidth: 20 }
      
    },
    didParseCell: function(data) {
      if (data.column.index === 7 && data.cell.raw === 'Anulado') {
        data.cell.styles.textColor = [255, 0, 0]; // Rojo para anulados
      }
    },
    didDrawPage: function(data) {
      // Agregar logo y título en cada página
      doc.addImage(logo, 'PNG', (pageWidth - logoWidth) / 2, 10, logoWidth, logoHeight);
      doc.setTextColor(128, 63, 152);
      doc.setFontSize(18);
      doc.setFont('helvetica', 'bold');
      doc.text('Informe de Cobros', pageWidth / 2, 35, { align: 'center' });
      addPageNumber(doc);
    }
  });

  // Guardar el PDF
  doc.save(`informe_cobros_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}.pdf`);
};




const handleGenerateInvoice = (payment) => {
    invoiceGenerator.generateInvoicePDF(payment);
  };

  const handleGenerateThermalInvoice = (payment) => {
    invoiceGenerator.generateThermalPrint(payment);
  };

  const toggleExpand = (id) => {
    setExpandedPayment(expandedPayment === id ? null : id);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#FBFEF7]">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#803f98]"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen font-poppins bg-[#FBFEF7]">
      <Header />
      <div className="h-1 bg-[#f5831f]"></div>
      <div className="flex-grow flex flex-col p-6 md:p-12">
        <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-6">
          Informe de Cobros
        </h1>

        <div className="mb-6 flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex items-center space-x-2">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              className="border border-[#803f98] rounded px-2 py-1"
              dateFormat="yyyy-MM-dd"
            />
            <button
              onClick={handleApplyDateFilter}
              className="bg-[#803f98] text-white font-bold py-1 px-2 rounded-md hover:bg-[#6a2f7a] focus:outline-none focus:ring-2 focus:ring-[#803f98] inline-flex items-center"
            >
              <FontAwesomeIcon icon={faCalendar} className="mr-1" />
              Aplicar Fechas
            </button>
          </div>
          <div className="flex items-center space-x-4">
            <label htmlFor="service-filter" className="text-lg font-semibold text-[#803f98]">Filtrar por servicio:</label>
            <div className="relative">
              <select
                id="service-filter"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="appearance-none bg-white border border-[#803f98] text-[#803f98] py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-[#f5831f] transition duration-300 ease-in-out"
              >
                <option value="all">Todos los servicios</option>
                <option value="internet">Internet</option>
                <option value="tv">TV</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-[#803f98]">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
            <button
              onClick={generateReport}
              className="bg-[#f5831f] text-white font-bold py-1 px-2 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f] inline-flex items-center"
            >
              <FontAwesomeIcon icon={faFileExport} className="mr-1" />
              Generar Reporte
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-8">
          <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
            <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-[#803f98] text-2xl mr-3" />
            <div>
              <p className="text-sm text-gray-600">Total de cobros</p>
              <p className="text-xl font-bold text-[#803f98]">{totalCobros}</p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
            <FontAwesomeIcon icon={faMoneyBillWave} className="text-[#f5831f] text-2xl mr-3" />
            <div>
              <p className="text-sm text-gray-600">Total recaudado</p>
              <p className="text-xl font-bold text-[#f5831f]">${totalRecaudado.toFixed(2)}</p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
            <FontAwesomeIcon icon={faCoins} className="text-[#803f98] text-2xl mr-3" />
            <div>
              <p className="text-sm text-gray-600">Cantidad a comisión</p>
              <p className="text-xl font-bold text-[#803f98]">{cantidadComision.toFixed(2)}</p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
            <FontAwesomeIcon icon={faBan} className="text-red-500 text-2xl mr-3" />
            <div>
              <p className="text-sm text-gray-600">Pendientes</p>
              <p className="text-xl font-bold text-red-500">{totalPendientes}</p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
            <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-2xl mr-3" />
            <div>
              <p className="text-sm text-gray-600">Aprobados</p>
              <p className="text-xl font-bold text-green-500">{totalAprobados}</p>
            </div>
          </div>
        </div>
        
        {noDataMessage ? (
          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
            <p className="font-bold">{noDataMessage}</p>
          </div>
        ) : (
          <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg mb-8 overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-[#803f98] text-white">
                  <th className="p-2"># COMP.</th>
                  <th className="p-2">Fecha</th>
                  <th className="p-2">RUC/Cédula</th>
                  <th className="p-2">Cliente</th>


                  <th className="p-2">Servicio</th>
                  <th className="p-2">Meses</th>
                  <th className="p-2">Total</th>
                  <th className="p-2">Estado</th>
                  <th className="p-2">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredPayments.map((payment) => (
                  <React.Fragment key={payment.cod_fac}>
                    <tr className={`border-b ${payment.type === 'internet' ? 'bg-purple-100/30' : 'bg-orange-100/30'}`}>
                      <td className="p-2">{payment.cod_doc}</td>
                      <td className="p-2">{new Date(payment.fecha).toLocaleDateString()}</td>
                      <td className="p-2">{payment.ruc || 'N/A'}</td>
                      <td className="p-2">{payment.nombre_cliente || 'N/A'}</td>
                      <td className="p-2">{payment.type === 'internet' ? 'Internet' : 'TV'}</td>
                      <td className="p-2">{payment.meses.toFixed(2)}</td>
                      <td className="p-2">${payment.total.toFixed(2)}</td>
                      <td className={`p-2 ${payment.anulado === 1 ? 'font-bold text-red-600' : ''}`}>
                        {payment.anulado === 1 ? 'Anulado' : 
                         (payment.numero_doc === 0 ? 'Pendiente' : 'Aprobado')}
                      </td>
                      <td className="p-2 space-x-2">
                        <button
                          onClick={() => handleGenerateThermalInvoice(payment)}
                          className="bg-[#f5831f] text-white font-bold py-1 px-2 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f] inline-flex items-center"
                        >
                          <FontAwesomeIcon icon={faPrint} className="mr-1" />
                          Térmica
                        </button>
                        <button
                          onClick={() => handleGenerateInvoice(payment)}
                          className="bg-[#803f98] text-white font-bold py-1 px-2 rounded-md hover:bg-[#6a2f7a] focus:outline-none focus:ring-2 focus:ring-[#803f98] inline-flex items-center"
                        >
                          <FontAwesomeIcon icon={faReceipt} className="mr-1" />
                          Factura
                        </button>
                        <button
                          onClick={() => toggleExpand(payment.cod_fac)}
                          className="bg-gray-200 text-gray-700 font-bold py-1 px-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 inline-flex items-center"
                        >
                          <FontAwesomeIcon icon={expandedPayment === payment.cod_fac ? faChevronUp : faChevronDown} />
                        </button>
                      </td>
                    </tr>
                    {expandedPayment === payment.cod_fac && (
                      <tr>
                        <td colSpan="9">
                          <div className="p-2 bg-gray-200 rounded-md">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                              <div>
                                <p><strong>Dirección:</strong> {payment.direccion || 'N/A'}</p>
                                <p><strong>Teléfono:</strong> {payment.telefono || 'N/A'}</p>
                                <p><strong>Celular:</strong> {payment.celular || 'N/A'}</p>
                              </div>
                              <div>
                                <p><strong>Contrato:</strong> {payment.contrato || 'N/A'}</p>
                                <p><strong>Código de Cliente:</strong> {payment.cod_cliente || 'N/A'}</p>
                                <p><strong>Código de Documento:</strong> {payment.cod_doc || 'N/A'}</p>
                              </div>
                            </div>
                            <h5 className="font-bold mt-2 mb-1">Detalles del servicio:</h5>
                            <table className="w-full text-sm">
                              <thead>
                                <tr className="bg-[#803f98] text-white">
                                  <th className="p-1">Descripción</th>
                                  <th className="p-1">Cantidad</th>
                                  <th className="p-1">Valor</th>
                                </tr>
                              </thead>
                              <tbody>
                                {payment.informacion_facturas_d && payment.informacion_facturas_d.map((detalle, index) => (
                                  <tr key={index} className="border-b">
                                    <td className="p-1">{detalle.descripcion}</td>
                                    <td className="p-1">{detalle.cantidad}</td>
                                    <td className="p-1">${detalle.valor_t.toFixed(2)}</td>
                                  </tr>
                                ))}
                                {(!payment.informacion_facturas_d || payment.informacion_facturas_d.length === 0) && (
                                  <tr>
                                    <td colSpan="3" className="p-1 text-center">No hay detalles disponibles</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PaymentHistory;