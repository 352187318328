


import React from 'react';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import logo from '../assets/logo.png';

const InvoiceGenerator = () => {

const generateInvoicePDF = (payment) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Obtener datos de localStorage
    const inicial = localStorage.getItem('inicial') || '';
    const userName = localStorage.getItem('userName') || 'Usuario no especificado';

    // Definir márgenes consistentes
    const margin = 20;
    const contentWidth = pageWidth - 2 * margin;

    // Helper function for centered text
    const centerText = (text, y, fontSize = 12, fontStyle = 'normal') => {
        doc.setFontSize(fontSize);
        doc.setFont('helvetica', fontStyle);
        const textWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;
        const x = (pageWidth - textWidth) / 2;
        doc.text(text, x, y);
    };

    // Add logo
    const logoWidth = 50;
    const logoHeight = 10.2;
    doc.addImage(logo, 'PNG', margin, margin, logoWidth, logoHeight);

    // Title and invoice number
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(20);
    doc.text('Comprobante', pageWidth - margin, margin + 10, { align: 'right' });
    doc.setFontSize(12);
    const invoiceNumber = `${inicial}${payment.cod_fac || payment.invoiceNumber || 'N/A'}`;
    doc.text(`N°: ${invoiceNumber}`, pageWidth - margin, margin + 18, { align: 'right' });

    // Client information
    let y = margin + 30;
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12);
    doc.text('Información del Cliente', margin, y);
    y += 10;

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    const clientInfo = [
        `Nombre: ${payment.nombre_cliente || payment.nombre || 'N/A'}`,
        `RUC/CI: ${payment.ruc || 'N/A'}`,
        `Dirección: ${payment.direccion || 'N/A'}`,
        `Teléfono: ${payment.telefono || 'N/A'}`,
        // `Contrato: ${payment.contrato || payment.suscripcion || 'N/A'}`
    ];
    clientInfo.forEach(info => {
        doc.text(info, margin, y);
        y += 8;
    });

    // Agregar información de quién atendió
    // y += 5;
    // doc.setFont('helvetica', 'italic');
    // // doc.text(`Usted fue atendido por: ${userName}`, margin, y);
    // y += 10;

    // Invoice details
    const invoiceDetails = payment.pagos_individuales || payment.informacion_facturas_d || payment.facturas_d || [];
    doc.autoTable({
        startY: y,
        head: [['Descripción', 'Cantidad', 'Valor Unitario', 'Valor Total']],
        body: invoiceDetails.map(item => [
            item.descripcion,
            item.cantidad || item.meses || 1,
            `$${((item.valor_total || item.valor_t) / (item.cantidad || item.meses || 1)).toFixed(2)}`,
            `$${(item.valor_total || item.valor_t).toFixed(2)}`
        ]),
        theme: 'striped',
        headStyles: { fillColor: [128, 63, 152], textColor: 255, fontStyle: 'bold' },
        bodyStyles: { fontSize: 9 },
        columnStyles: {
            0: { cellWidth: 'auto' },
            1: { cellWidth: 30, halign: 'center' },
            2: { cellWidth: 40, halign: 'right' },
            3: { cellWidth: 40, halign: 'right' }
        },
        styles: { cellPadding: 5 },
        margin: { left: margin, right: margin },
    });

    // Total
    const finalY = doc.lastAutoTable.finalY + 10;
    doc.setFillColor(240, 240, 240);
    doc.rect(pageWidth - margin - 60, finalY, 60, 10, 'F');
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12);
    doc.text('TOTAL:', pageWidth - margin - 55, finalY + 7);
    doc.text(`$${payment.total.toFixed(2)}`, pageWidth - margin - 5, finalY + 7, { align: 'right' });

    // Date and footer
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(9);
    doc.text(`Fecha de emisión: ${new Date().toLocaleDateString()}`, margin, pageHeight - 30);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    centerText('DOCUMENTO SIN VALOR TRIBUTARIO', pageHeight - 20);
    centerText('SOLICITE SU FACTURA EN OFICINA CON ESTE COMPROBANTE DE PAGO', pageHeight - 10);


    doc.save(`factura_${invoiceNumber}.pdf`);
};


  const generateThermalPrint = (payment) => {
    const doc = new jsPDF({
      unit: 'mm',
      format: [80, 200]
    });

    // Obtener datos de localStorage
    const inicial = localStorage.getItem('inicial') || '';
    const userName = localStorage.getItem('userName') || 'Usuario no especificado';

    // Añadir logo centrado
    const logoWidth = 30;
    const logoHeight = 6.12;
    doc.addImage(logo, 'PNG', (80 - logoWidth) / 2, 5, logoWidth, logoHeight);

    let yPos = 15;

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text('COMPROBANTE DE PAGO', 40, yPos, { align: 'center' });
    yPos += 8;

    doc.setFontSize(8);
    doc.setFont('helvetica', 'normal');

    const invoiceNumber = `${inicial}${payment.cod_fac || payment.invoiceNumber || 'N/A'}`;
    doc.text(`N°: ${invoiceNumber}`, 5, yPos);
    yPos += 5;

    doc.text(`Fecha: ${new Date().toLocaleDateString()}`, 5, yPos);
    yPos += 5;

    // Función para dividir texto en dos líneas si es necesario
    const splitTextInTwo = (text, maxWidth) => {
        const lines = doc.splitTextToSize(text, maxWidth);
        return lines.length > 2 ? [lines[0] + '...', lines[1]] : lines;
    };

    const clientInfo = [
        `Nombre: ${payment.nombre_cliente || payment.nombre || 'N/A'}`,
        `RUC/CI: ${payment.ruc || 'N/A'}`,
        `Dirección: ${payment.direccion || 'N/A'}`,
        `Teléfono: ${payment.telefono || 'N/A'}`,
        // `Contrato: ${payment.contrato || payment.suscripcion || 'N/A'}`
    ];

    clientInfo.forEach((info, index) => {
        if (index === 0 || index === 2) { // Nombre y Dirección
            const lines = splitTextInTwo(info, 70);
            doc.text(lines[0], 5, yPos);
            yPos += 4;
            if (lines[1]) {
                doc.text(lines[1], 5, yPos);
                yPos += 4;
            }
        } else {
            doc.text(info, 5, yPos);
            yPos += 4;
        }
    });

    // Agregar información de quién atendió
    // doc.setFont('helvetica', 'italic');
    // doc.text(`Atendido por: ${userName}`, 5, yPos);
    // yPos += 5;

    doc.line(5, yPos, 75, yPos);
    yPos += 5;

    doc.setFont('helvetica', 'bold');
    doc.text('Descripción', 5, yPos);
    doc.text('Cant.', 60, yPos, { align: 'right' });
    doc.text('Total', 75, yPos, { align: 'right' });
    yPos += 5;

    doc.setFont('helvetica', 'normal');
    const invoiceDetails = payment.pagos_individuales || payment.informacion_facturas_d || payment.facturas_d || [];
    invoiceDetails.forEach(item => {
        const description = doc.splitTextToSize(item.descripcion, 50);
        doc.text(description, 5, yPos);
        doc.text(`${item.cantidad || item.meses || 1}`, 60, yPos, { align: 'right' });
        doc.text(`$${(item.valor_total || item.valor_t).toFixed(2)}`, 75, yPos, { align: 'right' });
        yPos += 4 * description.length + 2;
    });

    doc.line(5, yPos, 75, yPos);
    yPos += 5;

    doc.setFont('helvetica', 'bold');
    doc.text('TOTAL:', 45, yPos);
    doc.text(`$${payment.total.toFixed(2)}`, 75, yPos, { align: 'right' });
    yPos += 10;

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(6);
    doc.text('DOCUMENTO SIN VALOR TRIBUTARIO', 40, yPos, { align: 'center' });
    yPos += 5;
    doc.text('SOLICITE SU FACTURA EN OFICINA CON', 40, yPos, { align: 'center' });
    yPos += 3;
    doc.text('ESTE COMPROBANTE DE PAGO', 40, yPos, { align: 'center' });
    
    doc.save(`comprobante_termico_${invoiceNumber}.pdf`);
};

  return {
    generateInvoicePDF,
    generateThermalPrint
  };
};

export default InvoiceGenerator;