

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown, faBook, faSignInAlt, faSearch, faDollarSign, faTachometerAlt, faHistory, faFileAlt, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import backImage from '../assets/BACKGROUND.png';
import logoCBBlanco from '../assets/LOGOCB.png';
import luisito from '../assets/LUISITO.png';
import logoPlay from '../assets/LOGOCBPLAY.png';
import logoInternet from '../assets/LOGOINTERNET.png';
import logoSlogan from '../assets/LOGOSLOGAN.png';
import logoTV from '../assets/LOGOTV.png';
import logoWifi from '../assets/LOGOWIFI.png';

const ManualUsuario = () => {
  const [activeSection, setActiveSection] = useState('introduccion');

  const sections = [
    { id: 'introduccion', title: 'Introducción', icon: faBook },
    { id: 'inicio-sesion', title: 'Inicio de Sesión', icon: faSignInAlt },
    { id: 'busqueda-deudas', title: 'Búsqueda de Deudas del Cliente', icon: faSearch },
    { id: 'realizacion-pagos', title: 'Realización de Pagos', icon: faDollarSign },
    { id: 'dashboard', title: 'Dashboard', icon: faTachometerAlt },
    { id: 'historial-pagos', title: 'Historial de Pagos', icon: faHistory },
    { id: 'generacion-reportes', title: 'Generación de Reportes', icon: faFileAlt },
  ];

  const navigateSection = (direction) => {
    const currentIndex = sections.findIndex(section => section.id === activeSection);
    if (direction === 'next' && currentIndex < sections.length - 1) {
      setActiveSection(sections[currentIndex + 1].id);
    } else if (direction === 'prev' && currentIndex > 0) {
      setActiveSection(sections[currentIndex - 1].id);
    }
  };

  const renderNavButtons = () => {
    const currentIndex = sections.findIndex(section => section.id === activeSection);
    return (
      <div className="flex justify-between mt-8">
        {currentIndex > 0 && (
          <button 
            onClick={() => navigateSection('prev')} 
            className="bg-[#803f98] text-white px-4 py-2 rounded flex items-center"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            {sections[currentIndex - 1].title}
          </button>
        )}
        {currentIndex < sections.length - 1 && (
          <button 
            onClick={() => navigateSection('next')} 
            className="bg-[#803f98] text-white px-4 py-2 rounded flex items-center ml-auto"
          >
            {sections[currentIndex + 1].title}
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat overflow-y-auto flex flex-col font-poppins"
         style={{ backgroundImage: `url(${backImage})` }}>
      <header className="bg-[#803f98] text-white p-4 flex justify-between items-center">
        <img src={logoCBBlanco} alt="CB Logo" className="h-12" />
        <h1 className="text-2xl font-bold">Manual de Usuario - CBCOBROS</h1>
        <Link to="/login" className="bg-[#f5831f] hover:bg-[#e67100] text-white font-bold py-2 px-4 rounded">
          Volver al Login
        </Link>
      </header>

      <main className="flex-grow flex">
        <nav className="w-1/4 bg-white bg-opacity-75 p-4 overflow-y-auto">
          <ul>
            {sections.map((section) => (
              <li key={section.id} className="mb-2">
                <button
                  onClick={() => setActiveSection(section.id)}
                  className={`w-full text-left p-2 rounded transition-colors duration-200 ${
                    activeSection === section.id ? 'bg-[#803f98] text-white' : 'hover:bg-[#803f98] hover:text-white'
                  }`}
                >
                  <FontAwesomeIcon icon={section.icon} className="mr-2" />
                  {section.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        <section className="w-3/4 bg-white bg-opacity-90 p-8 overflow-y-auto">
          {activeSection === 'introduccion' && (
            <div>
                            <img src={luisito} alt="Luisito" className="mx-auto mb-4" style={{ maxHeight: '250px' }} />
              <h2 className="text-2xl font-bold mb-4">Introducción</h2>
              <p className="mb-4">CBCOBROS es un sistema web de puntos de cobro diseñado para gestionar pagos de servicios de Internet y TV. Este manual le guiará a través de las principales funcionalidades del sistema, enfocándose en el proceso de cobro y la gestión de pagos.</p>
              <div className="grid grid-cols-2 gap-4 mt-8">
                {sections.slice(1).map((section) => (
                  <button
                    key={section.id}
                    onClick={() => setActiveSection(section.id)}
                    className="bg-[#f5831f] hover:bg-[#e67100] text-white font-bold py-3 px-4 rounded flex items-center justify-center transition-colors duration-200"
                  >
                    <FontAwesomeIcon icon={section.icon} className="mr-2" />
                    {section.title}
                  </button>
                ))}
              </div>
            </div>
          )}

          {activeSection === 'inicio-sesion' && (
            <div>
              <h2 className="text-2xl font-bold mb-4">Inicio de Sesión</h2>
              <ol className="list-decimal list-inside space-y-2">
                <li>Acceda a la página de inicio de sesión del sistema.</li>
                <li>Seleccione su sucursal de la lista desplegable.</li>
                <li>Ingrese su nombre de usuario en el campo "Usuario".</li>
                <li>Ingrese su contraseña en el campo "Contraseña".</li>
                <li>Haga clic en el botón "Iniciar sesión".</li>
              </ol>
              <p className="mt-4">Nota: Tras iniciar sesión exitosamente, será redirigido automáticamente a la página de búsqueda de deudas del cliente.</p>
            </div>
          )}

          {activeSection === 'busqueda-deudas' && (
            <div>
              <h2 className="text-2xl font-bold mb-4">Búsqueda de Deudas del Cliente</h2>
              <ol className="list-decimal list-inside space-y-2">
                <li>En la página de búsqueda, ingrese la cédula o RUC del cliente en el campo correspondiente.</li>
                <li>Haga clic en "Buscar" o presione Enter.</li>
                <li>El sistema mostrará las deudas pendientes del cliente, separadas por servicios de Internet y TV.</li>
              </ol>
              <p className="mt-4">Nota: Si no se encuentran deudas para el cliente, se mostrará un mensaje indicando que no hay deudas pendientes.</p>
            </div>
          )}

          {activeSection === 'realizacion-pagos' && (
            <div>
              <h2 className="text-2xl font-bold mb-4">Realización de Pagos</h2>
              <ol className="list-decimal list-inside space-y-2">
                <li>En la lista de deudas del cliente, localice el servicio que desea pagar.</li>
                <li>Haga clic en el botón "Pagar" junto al servicio correspondiente.</li>
                <li>En la ventana emergente:
                  <ul className="list-disc list-inside ml-6 mt-2">
                    <li>Para contratos normales: Seleccione el número de meses a pagar (hasta un máximo de 6).</li>
                    <li>Para contratos suspendidos: El sistema mostrará el número de meses requeridos para reactivar el servicio.</li>
                  </ul>
                </li>
                <li>Revise cuidadosamente el detalle del pago y el monto total.</li>
                <li>Haga clic en "Confirmar Pago".</li>
                <li>El sistema procesará el pago y mostrará un mensaje de confirmación.</li>
              </ol>
              <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 mt-4">
                <p className="font-bold">Importante:</p>
                <ul className="list-disc list-inside mt-2">
                  <li>Después de confirmar el pago, el contrato pasará a estado "Pendiente" para revisión y confirmación.</li>
                  <li>Asegúrese de que todos los detalles sean correctos antes de confirmar, ya que los pagos en estado pendiente pueden afectar la disponibilidad del servicio.</li>
                </ul>
              </div>
            </div>
          )}

          {activeSection === 'dashboard' && (
            <div>
              <h2 className="text-2xl font-bold mb-4">Dashboard</h2>
              <p>Puede acceder al Dashboard en cualquier momento haciendo clic en el logo de la empresa o en un botón de "Inicio" en la barra de navegación.</p>
              <p className="mt-4">En el Dashboard encontrará:</p>
              <ul className="list-disc list-inside space-y-2 mt-2">
                <li>Un saludo personalizado con su nombre.</li>
                <li>La fecha actual.</li>
                <li>Botones de acceso rápido para "Realizar Cobro" e "Historial de Pagos".</li>
                <li>Resumen de cobros del día:
                  <ul className="list-disc list-inside ml-6 mt-2">
                    <li>Total de cobros realizados</li>
                    <li>Cantidad de cobros de Internet</li>
                    <li>Cantidad de cobros de TV</li>
                  </ul>
                </li>
              </ul>
            </div>
          )}

          {activeSection === 'historial-pagos' && (
            <div>
              <h2 className="text-2xl font-bold mb-4">Historial de Pagos</h2>
              <ol className="list-decimal list-inside space-y-2">
                <li>Desde el Dashboard o la barra de navegación, haga clic en "Historial de Pagos".</li>
                <li>En esta página, podrá ver un resumen de los cobros realizados en el día actual.</li>
                <li>Utilice el selector de fecha para ver el historial de otros días.</li>
                <li>La lista mostrará detalles como el número de factura, nombre del cliente, servicio, monto y fecha de cada pago.</li>
                <li>Los pagos en estado pendiente se marcarán claramente para su fácil identificación.</li>
              </ol>
            </div>
          )}

          {activeSection === 'generacion-reportes' && (
            <div>
              <h2 className="text-2xl font-bold mb-4">Generación de Reportes</h2>
              <ol className="list-decimal list-inside space-y-2">
                <li>En la página de Historial de Pagos, utilice la función de filtrado por fecha para seleccionar el período deseado.</li>
                <li>Una vez que tenga los datos filtrados, busque la opción "Generar Reporte" o "Imprimir".</li>
                <li>Seleccione el formato de reporte deseado (por ejemplo, PDF).</li>
                <li>El sistema generará un reporte con los cobros realizados en el período seleccionado, incluyendo tanto los pagos confirmados como los pendientes.</li>
                <li>Podrá descargar o imprimir este reporte según sus necesidades.</li>
              </ol>
              <p className="mt-4">Nota: Asegúrese de tener los permisos necesarios para acceder a la función de generación de reportes.</p>
            </div>
          )}

          {renderNavButtons()}
        </section>
      </main>

      <footer className="bg-[#803f98] text-white p-4 flex justify-between items-center">
        <div>© 2024 CBCOBROS. Todos los derechos reservados.</div>
        <div className="flex space-x-4">
          <img src={logoPlay} alt="CB Play" className="h-8" />
          <img src={logoInternet} alt="Internet" className="h-8" />
          <img src={logoTV} alt="TV" className="h-8" />
          <img src={logoWifi} alt="WiFi" className="h-8" />
        </div>
      </footer>
    </div>
  );
};

export default ManualUsuario;
