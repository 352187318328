import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import cbcobrosLogo from '../assets/CBCOBROSCOLOR.png';
import { Menu } from 'lucide-react';

const Header = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user')) || {};
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userInitial');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userBranch');
    localStorage.removeItem('username');
    localStorage.removeItem('selectedBranch');
    localStorage.removeItem('inicial');
    localStorage.removeItem('sucursal');
    navigate('/login');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white shadow-md font-poppins">
      <div className="p-2 flex justify-between items-center">
        <div className="flex items-center space-x-4 md:space-x-8 md:justify-center md:flex-grow">
          {/* <img src={logo} alt="Logo" className="h-8 w-auto" /> */}
          {/* <div className="hidden md:block w-px h-8 bg-gray-300"></div> */}
          <img src={cbcobrosLogo} alt="CBC Cobros Logo" className="h-8 w-auto" />
        </div>
        {user.nombre_usuario && (
          <button
            onClick={toggleMenu}
            className="md:hidden text-[#803f98] focus:outline-none transition-transform duration-300 ease-in-out transform hover:scale-110"
            aria-label="Toggle menu"
          >
            <Menu size={24} />
          </button>
        )}
      </div>
      <div className="h-1 bg-[#f5831f]"></div>
      {user.nombre_usuario && (
        <nav className={`bg-[#803f98] text-white overflow-hidden transition-all duration-300 ease-in-out ${isMenuOpen ? 'max-h-96' : 'max-h-0 md:max-h-full'}`}>
          <div className="container mx-auto px-2 py-2 md:py-1 md:flex md:justify-between md:items-center">
            <div className="space-y-2 md:space-y-0 md:flex md:space-x-4 md:items-center">
              <Link to="/dashboard" className="block md:inline-block hover:text-[#f5831f] transition-colors">Dashboard</Link>
              <Link to="/client-debt" className="block md:inline-block hover:text-[#f5831f] transition-colors">Realizar Cobro</Link>
              <Link to="/payment-history" className="block md:inline-block hover:text-[#f5831f] transition-colors">Historial de Pagos</Link>
            </div>
            <div className="mt-2 md:mt-0 md:flex md:items-center md:space-x-4">
              <span className="block md:inline-block text-sm">
                <span className="font-bold">{user.nombre_usuario}</span> | Sucursal: {user.sucursal}
              </span>
              <button  
                onClick={handleLogout}
                className="mt-2 md:mt-0 w-full md:w-auto bg-[#f5831f] text-white font-bold py-1 px-3 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f] text-sm transition-colors duration-300"
              >
                Cerrar Sesión
              </button>
            </div>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;