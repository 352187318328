
import React from 'react';

const Footer = () => (
  <footer className="text-gray-300 bg-white p-4 text-center">

    {/* <h4 className="text-xl md:text-2xl font-bold mb-4">&copy; 2024 CBVision. Aplicacion de Cobros. Todos los derechos reservados. V.0.0.1</h4> */}
    <p>&copy; 2024 CBVision. Aplicación de Cobros. Todos los derechos reservados. V.0.0.1</p>
  </footer>
);

export default Footer;


//#803f98
//#f59e0b