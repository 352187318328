// // // src/components/Dashboard.js
// // import React, { useEffect, useState } from 'react';
// // import { useNavigate } from 'react-router-dom';
// // import Header from '../components/header';
// // import Footer from '../components/footer';
// // import { Link } from 'react-router-dom';

// // const Dashboard = () => {
// //   const [user, setUser] = useState(null);
// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     const storedUser = localStorage.getItem('user');
// //     if (storedUser) {
// //       setUser(JSON.parse(storedUser));
// //     } else {
// //       navigate('/login');
// //     }
// //   }, [navigate]);

// //   const handleLogout = () => {
// //     localStorage.removeItem('user');
// //     navigate('/login');
// //   };

// //   if (!user) {
// //     return <div>Loading...</div>;
// //   }

// //   return (
// //     <div className="flex flex-col min-h-screen bg-[#FBFEF7]">
// //       <Header />
// //       <div className="h-1 bg-[#f5831f]"></div>
// //       <div className="flex-grow flex flex-col p-6 md:p-12">
// //         <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-8">
// //           Bienvenido, {user.nombre_usuario}
// //         </h1>
// //         <Link to="/client-debt" className="bg-[#803f98] text-white font-bold py-2 px-4 rounded-md hover:bg-[#6a2f7e] focus:outline-none focus:ring-2 focus:ring-[#803f98] mr-4">
// //   Realizar Cobro
// // </Link>
// // <Link to="/payment-history" className="bg-[#4caf50] text-white font-bold py-2 px-4 rounded-md hover:bg-[#45a049] focus:outline-none focus:ring-2 focus:ring-[#4caf50]">
// //   Historial de Pagos
// // </Link>
// //         <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg mb-8">
// //           <h2 className="text-xl md:text-2xl font-bold text-[#803f98] mb-4">
// //             Panel de Control
// //           </h2>
// //           <p className="text-gray-700 mb-4">
// //             Aquí puedes gestionar los cobros y ver estadísticas importantes.
// //           </p>
// //           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
// //             <div className="bg-[#f5831f] text-white p-4 rounded-lg">
// //               <h3 className="font-bold mb-2">Total de Cobros</h3>
// //               <p className="text-2xl">$10,000</p>
// //             </div>
// //             <div className="bg-[#803f98] text-white p-4 rounded-lg">
// //               <h3 className="font-bold mb-2">Cobros Pendientes</h3>
// //               <p className="text-2xl">5</p>
// //             </div>
// //             <div className="bg-[#4caf50] text-white p-4 rounded-lg">
// //               <h3 className="font-bold mb-2">Cobros Completados</h3>
// //               <p className="text-2xl">20</p>
// //             </div>
// //           </div>
// //         </div>
// //         <button
// //           onClick={handleLogout}
// //           className="bg-[#f5831f] text-white font-bold py-2 px-4 rounded-md hover:bg-[#e67100] focus:outline-none focus:ring-2 focus:ring-[#f5831f]"
// //         >
// //           Cerrar Sesión
// //         </button>
// //       </div>
// //       <Footer />
// //     </div>
// //   );
// // };

// // export default Dashboard;


// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import Header from '../components/header';
// import Footer from '../components/footer';
// import { ArrowRight, DollarSign, Wifi, Tv, LogOut } from 'lucide-react';
// import apiService from '../services/apiService';

// const Dashboard = () => {
//   const [user, setUser] = useState(null);
//   const [dashboardInfo, setDashboardInfo] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       const storedUser = localStorage.getItem('user');
//       if (storedUser) {
//         const parsedUser = JSON.parse(storedUser);
//         setUser(parsedUser);
//         try {
//           const info = await apiService.getDashboardInfo(parsedUser.sucursal);
//           setDashboardInfo(info.data);
//         } catch (error) {
//           console.error('Error fetching dashboard info:', error);
       
//         }
//       } else {
//         navigate('/login');
//       }
//       setIsLoading(false);
//     };

//     fetchData();
//   }, [navigate]);

//   const handleLogout = () => {
//     localStorage.removeItem('user');
//     localStorage.removeItem('token');
//     navigate('/login');
//   };

//   if (isLoading) {
//     return (
//       <div className="flex items-center justify-center min-h-screen bg-[#FBFEF7]">
//         <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#803f98]"></div>
//       </div>
//     );
//   }

//   return (
//     <div className="flex flex-col min-h-screen font-poppins bg-[#FBFEF7]">
//       <Header />
//       <div className="h-1 bg-[#f5831f]"></div>
//       <main className="flex-grow flex flex-col p-6 md:p-12 max-w-7xl mx-auto w-full">
//         <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-8">
//           Hola de nuevo, {user?.nombre_usuario}
//         </h1>
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
//           <Link 
//             to="/client-debt" 
//             className="flex items-center justify-between bg-[#803f98] text-white font-bold py-4 px-6 rounded-lg hover:bg-[#6a2f7e] transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
//           >
//             Realizar Cobro
//             <ArrowRight className="ml-2" />
//           </Link>
//           <Link 
//             to="/payment-history" 
//             className="flex items-center justify-between bg-[#4caf50] text-white font-bold py-4 px-6 rounded-lg hover:bg-[#45a049] transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
//           >
//             Historial de Pagos
//             <ArrowRight className="ml-2" />
//           </Link>
//         </div>
//         <section className="bg-white p-6 md:p-8 rounded-lg shadow-lg mb-8">
//           <h2 className="text-2xl md:text-3xl font-bold text-[#803f98] mb-6">
//             Panel de Control
//           </h2>
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//             <div className="bg-gradient-to-br from-[#f5831f] to-[#f7b065] text-white p-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl">
//               <div className="flex items-center justify-between mb-4">
//                 <h3 className="font-bold text-lg">Total de Cobros</h3>
//                 <DollarSign size={24} />
//               </div>
//               <p className="text-3xl font-bold">${dashboardInfo?.total_recaudado.toFixed(2) || '0.00'}</p>
//             </div>
//             <div className="bg-gradient-to-br from-[#803f98] to-[#a76bc4] text-white p-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl">
//               <div className="flex items-center justify-between mb-4">
//                 <h3 className="font-bold text-lg">Cobros de Internet</h3>
//                 <Wifi size={24} />
//               </div>
//               <p className="text-3xl font-bold">{dashboardInfo?.cant_cobros?.int || 0}</p>
//             </div>
//             <div className="bg-gradient-to-br from-[#4caf50] to-[#7fd683] text-white p-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl">
//               <div className="flex items-center justify-between mb-4">
//                 <h3 className="font-bold text-lg">Cobros de TV</h3>
//                 <Tv size={24} />
//               </div>
//               <p className="text-3xl font-bold">{dashboardInfo?.cant_cobros?.tv || 0}</p>
//             </div>
//           </div>
//         </section>
//         <button
//           onClick={handleLogout}
//           className="flex items-center justify-center bg-[#f5831f] text-white font-bold py-3 px-6 rounded-lg hover:bg-[#e67100] transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-[#f5831f] focus:ring-opacity-50"
//         >
//           <LogOut className="mr-2" size={20} />
//           Cerrar Sesión
//         </button>
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default Dashboard;


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import { ArrowRight, DollarSign, Wifi, Tv, Calendar } from 'lucide-react';
import { Card, Title, Text, TabList, Tab, TabGroup, TabPanels, TabPanel, Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell, Badge } from "@tremor/react";
import apiService from '../services/apiService';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [dashboardInfo, setDashboardInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        try {
          const info = await apiService.getDashboardInfo(parsedUser.sucursal);
          setDashboardInfo(info.data);
        } catch (error) {
          console.error('Error fetching dashboard info:', error);
        }
      } else {
        navigate('/login');
      }
      setIsLoading(false);
    };

    fetchData();
  }, [navigate]);

  const getCurrentDate = () => {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const now = new Date();
    return `${days[now.getDay()]}, ${now.getDate()} de ${months[now.getMonth()]} de ${now.getFullYear()}`;
  };

 
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#FBFEF7]">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#803f98]"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen font-poppins bg-[#FBFEF7]">
      <Header />
      <div className="h-1 bg-[#f5831f]"></div>
      <main className="flex-grow flex flex-col p-6 md:p-12 max-w-7xl mx-auto w-full">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
          <h1 className="text-3xl md:text-4xl font-bold text-[#803f98] mb-4 md:mb-0">
            Hola de nuevo, {user?.nombre_usuario}
          </h1>
          <div className="flex items-center text-[#803f98] font-semibold">
            <Calendar className="mr-2" size={20} />
            {getCurrentDate()}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <Link 
            to="/client-debt" 
            className="flex items-center justify-between bg-[#803f98] text-white font-bold py-4 px-6 rounded-lg hover:bg-[#6a2f7e] transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
          >
            Realizar Cobro
            <ArrowRight className="ml-2" />
          </Link>
          <Link 
            to="/payment-history" 
            className="flex items-center justify-between bg-[#4caf50] text-white font-bold py-4 px-6 rounded-lg hover:bg-[#45a049] transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
          >
            Historial de Pagos
            <ArrowRight className="ml-2" />
          </Link>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <Card decoration="top" decorationColor="indigo">
            <Title>Total de Cobros Hoy</Title>
            <Text className="mt-2">Recaudación Diaria</Text>
            <Text className="text-3xl font-bold mt-2">${dashboardInfo?.total_recaudado.toFixed(2) || '0.00'}</Text>
          </Card>
          <Card decoration="top" decorationColor="fuchsia">
            <Title>Cobros de Internet Hoy</Title>
            <Text className="mt-2">Cantidad de Servicios</Text>
            <div className="flex items-center mt-2">
              <Wifi size={24} className="mr-2 text-fuchsia-500" />
              <Text className="text-3xl font-bold">{dashboardInfo?.cant_cobros?.int || 0}</Text>
            </div>
          </Card>
          <Card decoration="top" decorationColor="emerald">
            <Title>Cobros de TV Hoy</Title>
            <Text className="mt-2">Cantidad de Servicios</Text>
            <div className="flex items-center mt-2">
              <Tv size={24} className="mr-2 text-emerald-500" />
              <Text className="text-3xl font-bold">{dashboardInfo?.cant_cobros?.tv || 0}</Text>
            </div>
          </Card>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Dashboard;